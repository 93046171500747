<template>
    <div>
        <div v-show="isBusy">
            <md-progress-bar md-mode="indeterminate"></md-progress-bar>
        </div>
        <div v-show="!isBusy">   
            <md-toolbar class="md-primary mb-2 fixed-toolbar" md-elevation="1" :class="{'editing': editingResStatus}">
                <div style="flex: 1" class="pr-2">

                    <span class="md-title ml-0"  v-if="reservation && reservation.readableId && reservation.passenger">Reservation #{{reservation.readableId}}
                    </span>
                    <transition name="fade">
                        <md-chip 
                            v-show="!editingResStatus" 
                            :class="[ statusClass(statuses[reservation.reservationStatus])  ]" 
                            class="mini"
                            @click="toggleStatusSelect()"
                            >
                            Status: {{ statuses[reservation.reservationStatus] }}
                            <md-icon class="ml-2 mini-icon">edit</md-icon>
                        </md-chip>
                    </transition>
                    <transition name="fade">
                        <md-field v-show="editingResStatus">
                            <label>Reservation status</label>
                            <md-select v-model="reservation.reservationStatus" name="reservation_status" id="reservation_status" placeholder="Reservation status" @md-selected="resStatusSelected">
                                <md-option v-for="status in reservationStatuses" :value="status.id" v-bind:key="status.id">{{status.name}}</md-option>
                            </md-select>
                        </md-field>
                    </transition>
                    
                </div>
                <carousel 
                    :perPage="1" 
                    :navigationEnabled="true" 
                    :paginationEnabled="false" 
                    :autoplay="true" 
                    :loop="true" 
                    :adjustableHeight="false"
                    :autoplayTimeout="10000"
                    class="passenger-top-carousel mt-2"
                    v-if="reservation && dialogWidth >= 992"
                    >
                    <slide v-for="(passenger,ind) in reservation.passengers"
                        v-bind:key="'pass-' + ind"
                        :data-index="ind"
                        :data-name="passenger.type + 'note'"
                        >
                        <div class="no-wrap  text-magic-ellipsis mb-2"><md-icon class="mr-2">person</md-icon>{{passenger.firstName}} {{passenger.lastName}}</div>
                        <p class="no-wrap  text-magic-ellipsis ml-0"><md-icon class="mr-2">phone_iphone</md-icon>{{passenger.phone}}</p>
                        
                    </slide>
                
                </carousel>
            
                
                <!--md-button class="md-primary">Save vehicle type</md-button-->
                <md-button class="md-primary md-raised" @click="fireUpdate" :disabled="isBusy" v-if="user.data.activeRole != 'driver'">Save</md-button>
                <md-menu md-size="medium" :md-offset-x="-100" :md-offset-y="30" >
                    <md-button md-menu-trigger class="md-icon-button ml-2 md-raised" v-if="user.data.activeRole == 'company-admin'">
                        <md-icon>more_vert</md-icon>
                    </md-button>
                    <md-menu-content class="width-auto">
                        <md-menu-item v-if="reservation && reservation.duplication" @click="editOrView(reservation.duplication.originReadableId,reservation.duplication.originId)">
                            
                            <md-icon class="mr-3">file_copy</md-icon>
                            <span> Go to copy source {{ reservation.duplication.originReadableId }}</span>
                            <md-chip v-if="reservation.duplication.roundTrip" class="ml-2 uplimo-yellow mini mb-0">Round trip</md-chip>
                        </md-menu-item>
                        <md-menu-item v-if="reservation && reservation.duplicated" @click="editOrView(reservation.duplicated.destinationReadableId,reservation.duplicated.destinationId)">
                            
                            <md-icon class="mr-3">file_copy</md-icon>
                            <span> Go to duplicated reservation {{ reservation.duplicated.destinationReadableId }}</span>
                            <md-chip v-if="reservation.duplicated.roundTrip" class="ml-2 uplimo-yellow mini mb-0">Round trip</md-chip>
                        </md-menu-item>
                        <md-menu-item @click="startResDuplication(false)">
                            

                                <md-icon class="mr-3">copy_all</md-icon>
                                <span>Copy reservation</span>
                           
                        </md-menu-item>
                        <md-menu-item @click="startResDuplication(true)">
                            
                                <md-icon class="mr-3">autorenew</md-icon>
                                <span>Round trip</span>
                            
                        </md-menu-item>
                        
                    </md-menu-content>
                </md-menu>
            </md-toolbar>
            
            <md-tabs 
                class="fixed-tab-selector"
                md-alignment="left" 
                @md-changed="mainTabChanged" 
                ref="mainTabs" 
                :md-active-tab="mainActiveTab"
                :md-dynamic-height="false" 
                :class="{ 'hide-desktop-tabs': dialogWidth < 992}"
                

                v-if="reservation"
                
                > 
                <template slot="md-tab" slot-scope="{ tab }"> 
                    {{ tab.label }} 
                    <i class="badge" v-if="tab.data.badge > 0">{{ tab.data.badge }}</i>
                    <i class="badge md-primary" v-else-if="tab.data.badge == 0"><md-icon>done</md-icon></i> 
                </template>
                <md-tab id="date-time-location" md-label="Date, time &amp; location" :md-template-data="{ badge: tabs.dateTimeLocation.errors }">
                    <md-card class="uplimo-card">
                        <md-card-header>
                            <div class="md-title">Date, time &amp; location</div>
                        </md-card-header>
                        <md-card-content>
                            <CRow>
                                <CCol sm="12" md="12" lg="12" xl="6">
                                    <CRow>
                                        <CCol sm="12">
                                            <md-field :class="getValidationClass('day')">
                                                <VueCtkDateTimePicker 
                                                    v-model="selectedData.datetime" 
                                                    :dark="true" 
                                                    :inline="false" 
                                                    :formatted="'llll (HH:mm [HS])'"
                                                    :format="'YYYY-MM-DD hh:mm a'"
                                                    :output-format="'X'"
                                                    :color="'#448aff'"
                                                    :button-color="'#448aff'"	
                                                    :minute-interval="5"
                                                    id="ul-timepicker"
                                                    :disabled="user.data.activeRole === 'driver'"
                                                />
                                                <span class="md-error" v-if="!$v.reservation.day.required">Date &amp; time are required</span>
                                            
                                                

                                                <span class="md-helper-text" v-if="reservation.companyProfile">
                                                    <span v-if="user.data.activeRole === 'company-admin'">Selected date and time below will be applied to  <b>{{reservation.companyProfile.name}}</b> timezone <b>{{reservation.companyProfile.timeZone}}</b> <br></span>
                                                    <b>Actual date time {{companyActualDateTime}}</b>
                                                </span>

                                            </md-field>
                                        </CCol>
                                        
                                        
                                    </CRow>
                                    <CRow class="mt-4" v-if="reservation.vehicleType && reservation.vehicleType.rates.hourlyEnabled && reservation.jobType && reservation.jobType.type == 'Hourly'">
                                        <CCol sm="12">
                                            <md-field>
                                                <label for="hours">hours</label>
                                                <md-select v-model="reservation.time" name="hours" id="hours">
                                                    <md-option value="1">1</md-option>
                                                    <md-option value="2">2</md-option>
                                                    <md-option value="3">3</md-option>
                                                    <md-option value="4">4</md-option>
                                                    <md-option value="5">5</md-option>
                                                    <md-option value="6">6</md-option>
                                                    <md-option value="7">7</md-option>
                                                    <md-option value="8">8</md-option>
                                                    <md-option value="9">9</md-option>
                                                    <md-option value="10">10</md-option>
                                                    <md-option value="11">11</md-option>
                                                    <md-option value="12">12</md-option>
                                                </md-select>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    <CRow class="mt-5"> 
                                        <CCol sm="8">
                                            <md-field :class="getValidationClass('pu')" class="md-focused">
                                                <label>Pick up</label>
                                                <gmap-autocomplete
                                                    :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
                                                    placeholder="Pick up"
                                                    @place_changed="setPU" class="pu-autocomplete"
                                                    @change="getSelectedAddress('pu',$event.target)"
                                                    ref="pu"
                                                    :disabled="user.data.activeRole === 'driver' ? true : false"
                                                >
                                                </gmap-autocomplete>
                                                <!--md-button class="md-icon-button" @click="usePlace" >
                                                    <md-icon>add</md-icon>
                                                </md-button-->
                                                <span class="md-error" v-if="!$v.reservation.pu.required">Pick up is required</span>
                                            </md-field>

                                        </CCol>

                                            <CCol sm="4">
                                                <md-field>
                                                <label>Zip code</label>
                                                <md-input v-model="reservation.pu.postalCode" readonly></md-input>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12">
                                            <md-button class="md-raised stop-button" @click="addStop" v-if="user.data.activeRole != 'driver'"><md-icon>add_location_alt</md-icon>Add stop</md-button>
                                            <!--div v-for="(waypoint, index) in reservation.waypoints" v-bind:key="`waypoint-${index}`"-->
                                            <div v-for="(waypoint, index) in reservation.waypoints" v-bind:key="`waypoint-${index}`">
                                                <div class="row">
                                                    <div :class="{ 'col-sm-8': reservation.jobType && reservation.jobType.type == 'Hourly', 'col-sm-6': reservation.jobType && reservation.jobType.type == 'Distance'}">
                                                        <!--md-field :class="focusedItem == index ? 'md-focused' : ''"-->
                                                        <md-field class="md-focused">
                                                            <label>{{'Stop #' + (index + 1)}}</label>
                                                            <gmap-autocomplete
                                                                :options="{fields: ['geometry', 'formatted_address', 'address_components']}"
                                                                :placeholder="''"
                                                                @place_changed="setWP" class="pu-autocomplete"
                                                                @change="autocompleteUser(index,$event.target)"
                                                                @focus="focusedLocation(index)"
                                                                @blur="unfocusLocation(index)"
                                                                v-on:input="inputWithValue(index,$event.target.value)"
                                                                ref="stopwaypoint"
                                                                :disabled="user.data.activeRole === 'driver' ? true : false"
                                                                
                                                            >
                                                            </gmap-autocomplete>
                                                            
                                                        </md-field>
                                                    </div>
                                                    <div class="stop-zip-delete-container"
                                                        v-if="reservation.jobType && reservation.jobType.type == 'Distance'" 
                                                       :class="{ 'col-sm-0': reservation.jobType && reservation.jobType.type == 'Hourly', 'col-sm-3': reservation.jobType && reservation.jobType.type == 'Distance'}">
                                                        <md-field>
                                                            <label>Waiting Time (min)</label>
                                                            <md-input v-model="waypoint.waitingTime" type="number" step="15"></md-input>
                                                            
                                                        </md-field>
                                                    </div>
                                                    <div class="col-sm-2 stop-zip-delete-container"
                                                    :class="{ 'col-sm-3': reservation.jobType && reservation.jobType.type == 'Hourly', 'col-sm-2': reservation.jobType && reservation.jobType.type == 'Distance'}">
                                                        <md-field>
                                                            <label>Zip code</label>
                                                            <md-input v-model="waypoint.postalCode" readonly></md-input>
                                                            
                                                        </md-field>
                                                    </div>
                                                    
                                                    <div class="col-sm-1 stop-zip-delete-container">
                                                        <md-button class="md-icon-button" @click="removeWaypoint(index)">
                                                            <md-icon>delete</md-icon>
                                                        </md-button>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </CCol>
                                        

                                    
                                    </CRow>
                                        <CRow>
                                        <CCol sm="8">
                                            <md-field :class="getValidationClass('do')" class="md-focused">
                                                <label>Drop off</label>
                                                <gmap-autocomplete
                                                    placeholder="Drop off"
                                                    @place_changed="setDO" class="pu-autocomplete"
                                                    ref="do"
                                                    @change="getSelectedAddress('do',$event.target)"
                                                    :disabled="user.data.activeRole === 'driver' ? true : false"
                                                >
                                                </gmap-autocomplete>
                                                <span class="md-error" v-if="!$v.reservation.do.required">Drop off is required</span>
                                                
                                            </md-field>
                                            </CCol>

                                            <CCol sm="4">
                                                <md-field>
                                                <label>Zip code</label>
                                                <md-input v-model="reservation.do.postalCode" readonly></md-input>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    <CRow>
                                        
                                        <CCol sm="12" >
                                            <md-field :class="getValidationClass('jobType')">
                                                <md-select v-model="selectedData.jobType" name="vehicle_type" id="job-type" placeholder="Job Type" :disabled="user.data.activeRole == 'driver'">
                                                    <md-option v-for="serviceType in serviceTypes"  :value="serviceType.id" v-bind:key="serviceType.id">{{serviceType.name}}</md-option>
                                                </md-select>
                                                <span class="md-error" v-if="!$v.reservation.jobType.required">Job Type is required</span>
                                            </md-field>
                                            <md-autocomplete 
                                                    :md-opened="operatorSelectorOpened()"
                                                    :md-options="airlines" 
                                                    :md-open-on-focus="true" 
                                                    :md-dense="true"
                                                    @focusin.native="focusedAirlines($event)"
                                                    @md-changed="getAirlines"
                                                    @md-selected="setAirline"
                                                    :value="reservation.flightAirline"
                                                    aria-autocomplete="off"
                                                    :class="getValidationClass('flightAirline')"
                                                    ref="airlineAutocomplete"
                                                    :disabled="user.data.activeRole === 'driver' ? true : false"
                                                    >
                                                <label>Airline</label>
                                                <template slot="md-autocomplete-item" slot-scope="{ item }">
                                                     <span class="airline-code">
                                                        <b v-if="item.icao != '' && item.iata != ''">{{ item.icao }} - {{item.iata}}</b>
                                                        <b v-if="item.icao == '' && item.iata != ''">{{item.iata}}</b>
                                                        <b v-if="item.icao != '' && item.iata == ''">{{item.icao}}</b>
                                                    </span> 
                                                    <span> {{ item.name }}</span>
                                                    
                                                </template>
                                                <template slot="md-autocomplete-empty" slot-scope="{ term }"> No matches found for <b>{{ term }}</b> </template>
                                                <span class="md-error" v-if="!$v.reservation.flightAirline.required">Flight Airline is required</span>
                                            </md-autocomplete>
                                            <div v-if="reservation && reservation.flightAirline">
                                                Selected airline <b>
                                                    <span v-if="reservation.flightIcao">{{reservation.flightIcao}}</span>
                                                    {{ reservation.flightIcao && reservation.flightIata ? ' / ' : '' }}
                                                    <span v-if="reservation.flightIata">{{reservation.flightIata}}</span>
                                                </b>
                                            </div> 
                                            <div v-else>No airline selected</div>
                                        </CCol>
                                        <CCol sm="12">
                                            <md-field :class="getValidationClass('flightNumber')">
                                                <label>Flight #</label>
                                                <md-input v-model="reservation.flightNumber" v-number-only @keyup="initFlightCheck" :disabled="user.data.activeRole === 'driver' ? true : false"></md-input>
                                                <span class="md-error" v-if="!$v.reservation.flightNumber.required">Flight is required</span>
                                                <span class="md-error" v-else-if="!$v.reservation.flightNumber.serverError">Flight is invalid</span>
                                                <md-progress-spinner v-show="loadingFlight" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    <div v-if="reservation.flightPack.noMatch" class="md-elevation-4 p-3">
                                        <div class="md-layout md-alignment-center-center">
                                            <div class="md-layout-item md-size-15">
                                                <md-icon class="md-size-2x  md-accent">airplanemode_inactive</md-icon>
                                            </div>
                                            <div class="md-layout-item">
                                                <div class="md-title"><b>{{reservation.flightIcao != '' && reservation.flightIcao != null ? reservation.flightIcao : reservation.flightIata != null ? reservation.flightIata : ''}}  {{ reservation.flightNumber}}</b></div>
                                                <div class="md-subheading">No matches found</div>
                                                <p class="md-body-2 mb-0">Searched flight code  has not been founded. Please doublecheck date, time, location, airline and flight number</p>
                                            </div>
                                        </div>
                                        
                                    </div>
                                    <div v-if="reservation.flightPack.schedule" class="md-elevation-4 p-3 mb-4">
                                        <CRow  class="mb-2">
                                            <CCol sm="12">

                                                <div>Matched flight</div>
                                                <div class="md-title">
                                                    <span v-if="reservation.flightPack.icao != null && reservation.flightPack.icao != ''">
                                                        {{ reservation.flightPack.icao }}
                                                    </span>
                                                    <span v-if="reservation.flightPack.icao != null && reservation.flightPack.icao != '' && reservation.flightPack.iata != null && reservation.flightPack.iata != ''">/</span>
                                                    <span v-if="reservation.flightPack.iata != null && reservation.flightPack.iata != ''">
                                                        {{ reservation.flightPack.iata }}
                                                    </span>

                                                    {{reservation.flightPack.number}}
                                                </div>
                                                
                                            </CCol>
                                        </CRow>
                                        <CRow v-if="reservation.flightPack.schedule">
                                            
                                            <CCol :sm="reservation.flightPack.diverted ? 4 : 6">
                                                <md-icon class="mr-2">flight_takeoff</md-icon>
                                                <div class="md-subheading mb-1">Departure</div>
                                                <div class="md-title up-text-primary">{{reservation.flightPack.schedule.origin.code_icao}} <span v-if="reservation.flightPack.schedule.origin.code_iata">/ {{reservation.flightPack.schedule.origin.code_iata }}</span></div>
                                                <div class="md-body-1 text-magic-ellipsis">{{reservation.flightPack.originAirport.name }}</div>
                                                <div class="md-caption">{{reservation.flightPack.originAirport.city }} / {{reservation.flightPack.originAirport.country }}</div>
                                                <div v-if="reservation.flightPack.live">
                                                    <md-divider class="mt-1 mb-2"></md-divider>
                                                    <span class="md-caption mr-2">Actual status</span>
                                                    <md-chip v-if="reservation.flightPack.live.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.live.actual_off == null" class="mini uplimo-orange">On land</md-chip> 
                                                    <md-chip v-else class="mini uplimo-green">Departed</md-chip> 
                                                
                                                    
                                                    <div v-if="reservation.flightPack.live.actual_off == null" class="md-subheading up-text-caution text-magic-ellipsis">Gate departure {{ reservation.flightPack.live.actual_out | moment('timezone', reservation.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div v-else-if="reservation.flightPack.live.actual_off" class="md-subheading  no-wrap up-text-confirmed text-magic-ellipsis">{{ reservation.flightPack.live.actual_off | moment('timezone', reservation.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ reservation.flightPack.lastLiveUpdate | moment('timezone', reservation.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                                                </div>



                                                <div v-if="reservation.flightPack.divertedLive">
                                                    <md-divider class="mt-1 mb-2"></md-divider>
                                                    <span class="md-caption mr-2">Actual status</span>
                                                    <md-chip v-if="reservation.flightPack.divertedLive.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.divertedLive.actual_off == null" class="mini uplimo-orange">On land</md-chip> 
                                                    <md-chip v-else class="mini uplimo-green">Departed</md-chip> 
                                                
                                                    
                                                    <div v-if="reservation.flightPack.divertedLive.actual_off == null" class="md-subheading up-text-caution text-magic-ellipsis">Gate departure {{ reservation.flightPack.divertedLive.actual_out | moment('timezone', reservation.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div v-else-if="reservation.flightPack.divertedLive.actual_off" class="md-subheading  no-wrap up-text-confirmed text-magic-ellipsis">{{ reservation.flightPack.divertedLive.actual_off | moment('timezone', reservation.flightPack.originAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ reservation.flightPack.lastLiveUpdate | moment('timezone', reservation.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                                                </div>


                                                <md-divider class="mb-2 mt-2"></md-divider>
                                                <span class="md-caption">Schedule</span>
                                                <div class="md-subheading">{{ reservation.flightPack.schedule.scheduled_out | moment('timezone', reservation.flightPack.originAirport.tz , 'YYYY-MM-DD') }}</div>
                                                <div class="md-subheading">{{ reservation.flightPack.schedule.scheduled_out | moment('timezone', reservation.flightPack.originAirport.tz , 'hh:mm A (HH:mm [HS])')  }}</div>
                                                <div class="md-caption">Timezone: {{reservation.flightPack.originAirport.tz }}</div>
                                                <md-divider class="mb-2 mt-2"></md-divider>
                                                <CRow v-if="reservation.flightPack.info" >
                                                    <CCol sm="12">
                                                        <div>Terminal: {{reservation.flightPack.schedule.terminal_origin != '' ? reservation.flightPack.schedule.terminal_origin : '-' }}</div>
                                                        <div>Gate:  {{reservation.flightPack.schedule.gate_origin != '' ? reservation.flightPack.schedule.gate_origin : '-' }}</div>
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                            <CCol :sm="reservation.flightPack.diverted ? 4 : 6">
                                                <md-icon class="mr-2">flight_land</md-icon>
                                                <div class="md-subheading mb-1">Arrival</div>
                                                

                                                <div class="md-title up-text-primary" :class="{'up-line-through': reservation.flightPack.diverted  }">{{reservation.flightPack.schedule.destination.code_icao}} <span v-if="reservation.flightPack.schedule.destination.code_iata">/ {{reservation.flightPack.schedule.destination.code_iata }}</span></div>
                                                <div class="md-title up-text-primary" v-if="reservation.flightPack.divertedDestinationAirport">{{reservation.flightPack.divertedLive.destination.code_icao}} <span v-if="reservation.flightPack.divertedDestinationAirport.iata">/ {{reservation.flightPack.divertedDestinationAirport.iata }}</span></div>

                                                <div class="md-body-1 text-magic-ellipsis" :class="{'up-line-through': reservation.flightPack.diverted  }">{{reservation.flightPack.destinationAirport.name }}</div>
                                                <div class="md-body-1 text-magic-ellipsis" v-if="reservation.flightPack.diverted">{{reservation.flightPack.divertedDestinationAirport.name }}</div>

                                                <div class="md-caption" :class="{'up-line-through': reservation.flightPack.diverted }" >{{reservation.flightPack.destinationAirport.city }} / {{reservation.flightPack.destinationAirport.country }}</div>
                                                <div class="md-caption"  v-if="reservation.flightPack.diverted">{{reservation.flightPack.divertedDestinationAirport.city }} / {{reservation.flightPack.divertedDestinationAirport.country }}</div>


                                                <div v-if="reservation.flightPack.live">
                                                <md-divider class="mt-1 mb-2"></md-divider>
                                                
                                                    <span class="md-caption mr-2">
                                                        Actual status
                                                    </span>
                                                    
                                            
                                                    <md-chip v-if="reservation.flightPack.live.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.live.actual_on == null && reservation.flightPack.live.actual_off != null " class="mini uplimo-yellow">On air</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.live.actual_on" class="mini uplimo-green">Landed</md-chip> 
                                            
                                                
                                                    <div v-if="!reservation.flightPack.live.actual_on" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ reservation.flightPack.live.estimated_on | moment('timezone', reservation.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div v-else-if="reservation.flightPack.live.actual_on" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ reservation.flightPack.live.actual_on | moment('timezone', reservation.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ reservation.flightPack.lastLiveUpdate | moment('timezone', reservation.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                                                </div>


                                                <div v-if="reservation.flightPack.divertedLive">
                                                    <md-divider class="mt-1 mb-2"></md-divider>
                                                    
                                                    <span class="md-caption mr-2">
                                                        Actual status
                                                    </span>
                                                    
                                                
                                                    <md-chip v-if="reservation.flightPack.divertedLive.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.divertedLive.actual_on == null && reservation.flightPack.divertedLive.actual_off != null " class="mini uplimo-yellow">On air</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.divertedLive.actual_on > 0" class="mini uplimo-green">Landed</md-chip> 
                                                
                                                    
                                                    <div v-if="!reservation.flightPack.divertedLive.actual_on" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ reservation.flightPack.divertedLive.estimated_on | moment('timezone', reservation.flightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div v-else-if="reservation.flightPack.divertedLive.actual_on" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ reservation.flightPack.divertedLive.actual_on | moment('timezone', reservation.flightPack.divertedDestinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ reservation.flightPack.lastLiveUpdate | moment('timezone', reservation.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                                                </div>

                                                <md-divider class="mb-2 mt-2"></md-divider>
                                                 <span class="md-caption">Schedule</span>
                                                <div class="md-subheading">{{ reservation.flightPack.schedule.scheduled_in | moment('timezone', reservation.flightPack.destinationAirport.tz , 'YYYY-MM-DD') }}</div>
                                                <div class="md-subheading">{{ reservation.flightPack.schedule.scheduled_in | moment('timezone', reservation.flightPack.destinationAirport.tz , 'hh:mm A (HH:mm [HS])') }}</div>
                                                <div class="md-caption">Timezone: {{reservation.flightPack.destinationAirport.tz }}</div>
                                                <md-divider class="mb-2 mt-2"></md-divider>
                                                <CRow v-if="reservation.flightPack.info" >
                                                    <CCol sm="12">
                                                        <div>Terminal: {{reservation.flightPack.schedule.terminal_destination != '' ? reservation.flightPack.schedule.terminal_destination : '-' }}</div>
                                                        <div>Gate:  {{reservation.flightPack.schedule.gate_destination != '' ? reservation.flightPack.schedule.gate_destination : '-' }}</div>
                                                        <div>Bag claim:  {{reservation.flightPack.schedule.baggage_claim != '' ? reservation.flightPack.schedule.baggage_claim : '-' }}</div>
                                                    </CCol>
                                                </CRow>
                                            </CCol>
                                            


                                            <CCol v-if="reservation.flightPack.diverted" sm="4">
                                                <md-icon class="mr-2">flight_land</md-icon>
                                                <div class="md-subheading mb-1">{{ reservation.flightPack.diverted ? 'Original arrival' : 'Arrival'}}</div>
                                                <div class="md-title up-text-primary" >{{reservation.flightPack.schedule.destination.code_icao}} <span v-if="eservation.flightPack.schedule.destination.code_iata">/ {{eservation.flightPack.schedule.destination.code_iata }}</span></div>
                                                <div class="md-body-1 text-magic-ellipsis">{{reservation.flightPack.destinationAirport.name }}</div>
                                                <div class="md-caption">{{reservation.flightPack.destinationAirport.city }} / {{reservation.flightPack.destinationAirport.country }}</div>
                                                
                                                
                                                <div v-if="reservation.flightPack.divertedToScheduledDestinationLive">
                                                    <md-divider class="mt-1 mb-2"></md-divider>
                                                    
                                                    <span class="md-caption mr-2">
                                                        Actual status
                                                    </span>
                                                    
                                                
                                                    <md-chip v-if="reservation.flightPack.divertedToScheduledDestinationLive.cancelled" class="mini uplimo-red">Cancelled</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.divertedToScheduledDestinationLive.actual_on == null && reservation.flightPack.divertedToScheduledDestinationLive.actual_off != null " class="mini uplimo-yellow">On air</md-chip> 
                                                    <md-chip v-else-if="reservation.flightPack.divertedToScheduledDestinationLive.actual_on > 0" class="mini uplimo-green">Landed</md-chip> 
                                                
                                                    
                                                    <div v-if="!reservation.flightPack.divertedToScheduledDestinationLive.actual_on" class="md-subheading up-text-caution text-magic-ellipsis">ETA {{ reservation.flightPack.divertedToScheduledDestinationLive.estimated_on | moment('timezone', reservation.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div v-else-if="reservation.flightPack.divertedToScheduledDestinationLive.actual_on > 0" class="no-wrap md-subheading up-text-confirmed text-magic-ellipsis">{{ reservation.flightPack.divertedToScheduledDestinationLive.actual_on | moment('timezone', reservation.flightPack.destinationAirport.tz , 'hh:mm A YYYY-MM-DD') }}</div>
                                                    <div class="md-caption no-wrap up-text-soft-black">Last updated at {{ reservation.flightPack.lastLiveUpdate | moment('timezone', reservation.companyProfile.timeZone , 'YYYY-MM-DD hh:mm A (HH:mm [HS])') }}</div>
                                                </div>
                                                

                                                
                                                
                                            </CCol>






                                        </CRow>
                                    </div>                                    
                                    <CRow v-if="reservation.flightInfo">
                                        <CCol sm="12">
                                            <md-table class="flight-table">
                                                <md-table-row>
                                                    <md-table-head>Airline</md-table-head>
                                                    <md-table-head>Flight</md-table-head>
                                                    <md-table-head>Origin</md-table-head>
                                                    <md-table-head>Destination</md-table-head>
                                                
                                                    <md-table-head>Day</md-table-head>
                                                    
                                                    <md-table-head v-if="reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2'">ETD</md-table-head>
                                                    <md-table-head v-if="reservation.jobType == 'WKvPZgD64U1JISyKjTi6'">ETA</md-table-head>
                                                </md-table-row>

                                                <md-table-row>
                                                    <md-table-cell>{{reservation.flightAirline}}</md-table-cell>
                                                    <md-table-cell>{{reservation.flightInfo.ident}}</md-table-cell>
                                                    <md-table-cell>{{reservation.flightInfo.originAirport}} <br><b>{{ reservation.flightInfo.origin }}</b></md-table-cell>
                                                    <md-table-cell>{{reservation.flightInfo.destinationAirport}} <br> <b>{{ reservation.flightInfo.destination }}</b></md-table-cell>
                                                    <md-table-cell v-if="reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2'">{{ reservation.flightInfo.departuretime | moment("YYYY-MM-DD") }}</md-table-cell>
                                                    <md-table-cell v-if="reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2'">{{ reservation.flightInfo.departuretime | moment("hh:mm A (HH:mm [HS])") }}</md-table-cell>

                                                    <md-table-cell v-if="reservation.jobType == 'WKvPZgD64U1JISyKjTi6'">{{ reservation.flightInfo.arrivaltime | moment("YYYY-MM-DD") }}</md-table-cell>
                                                    <md-table-cell v-if="reservation.jobType == 'WKvPZgD64U1JISyKjTi6'">{{ reservation.flightInfo.arrivaltime | moment("hh:mm A (HH:mm [HS])") }}</md-table-cell>
                                                </md-table-row>
                                            </md-table>
                                        </CCol>    
                                    </CRow>
                                    <CRow>
                                        <CCol sm="6" v-if="user.data.activeRole == 'company-admin'">
                                            <FeeCalculator 
                                                :addons="feeCalculatorData.addOns"
                                                :promoCodes="reservation.promoCodes"
                                                :pu="reservation.pu" 
                                                :do="reservation.do" 
                                                :datetime="reservation.day"
                                                :jobType="feeCalculatorData.jobType" 
                                                :companyProfile="reservation.companyProfile"
                                                :vehicleType="feeCalculatorData.vehicleType" 
                                                :overviewPath="feeCalculatorData.overviewPath"
                                                :extras="selectedData.extras"
                                                :calculatedDistance="reservation.miles"
                                                :waypoints="reservation.waypoints"
                                                @priceTable="printPriceTable"
                                                :rideDetailsOverridedData="overridedRateData"
                                                ref="feeCalculator" 
                                                /> 
                                            <!--md-field>
                                                <label>Price</label>
                                                <md-input v-model="reservation.price"></md-input>
                                            </md-field-->
                                        </CCol>
                                        <CCol sm="6">
                                            
                                            <md-field>
                                                <label>Miles</label>
                                                <md-input v-model="reservation.miles" readonly></md-input>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    <CRow v-if="user.data.activeRole == 'company-admin'">
                                        <CCol sm="12">
                                            <md-field>
                                                <label>Promo code</label>
                                                <md-input v-model="feeCalculatorData.promoCode"></md-input>
                                                
                                                <md-progress-spinner v-show="loadingPromoCode" :md-diameter="30" :md-stroke="3" md-mode="indeterminate"></md-progress-spinner>
                                            </md-field>
                                            <md-button class="md-raised stop-button" @click="addPromoCode">Add code</md-button>
                                            
                                            <md-list v-if="reservation.promoCodes && reservation.promoCodes.length > 0">
                                                <md-subheader>Codes to check</md-subheader>
                                                <md-list-item v-for="promoCode in reservation.promoCodes" v-bind:key="'codes-to-check-' + promoCode">{{promoCode}}</md-list-item>
                                            </md-list>  
                                        </CCol>
                                    </CRow>
                                    <CRow v-if="reservation.vehicleType 
                                                && 
                                                (
                                                    ( reservation.vehicleType.rates.extras.childSeat.enabled 
                                                && reservation.vehicleType.rates.extras.childSeat.price )
                                                || ( reservation.vehicleType.rates.extras.accessible.enabled 
                                                && reservation.vehicleType.rates.extras.accessible.price )
                                                || ( reservation.vehicleType.rates.extras.meetAndGreet.enabled 
                                                && reservation.vehicleType.rates.extras.meetAndGreet.price)
                                                )">
                                        <CCol sm="12">
                                            <div class="extras-box mt-2">
                                                <div class="ex-title">Extras</div>
                                                <CRow>
                                                    <CCol sm="4" v-if="reservation.vehicleType && reservation.vehicleType.rates.extras.childSeat.enabled && reservation.vehicleType.rates.extras.childSeat.price">
                                                        <md-icon  class="special-icon super" v-bind:class="{ 'active-icon': reservation.childSeat }">child_friendly</md-icon> 
                                                        <md-switch v-model="reservation.childSeat" :disabled="user.data.activeRole === 'driver' ? true : false">Childseat</md-switch>
                                                    </CCol>
                                                    <CCol sm="4" v-if="reservation.vehicleType && reservation.vehicleType.rates.extras.accessible.enabled && reservation.vehicleType.rates.extras.accessible.price">
                                                        <md-icon class="special-icon super" v-bind:class="{ 'active-icon': reservation.accessible }">accessible</md-icon> 
                                                        <md-switch v-model="reservation.accessible" :disabled="user.data.activeRole === 'driver' ? true : false">Accessible</md-switch>
                                                    </CCol>
                                                    <CCol sm="4" v-if="reservation.vehicleType && reservation.vehicleType.rates.extras.meetAndGreet.enabled && reservation.vehicleType.rates.extras.meetAndGreet.price">
                                                        <md-icon class="special-icon super" v-bind:class="{ 'active-icon': reservation.meetAndGreet }">face</md-icon> 
                                                        <md-switch v-model="reservation.meetAndGreet" :disabled="user.data.activeRole === 'driver' ? true : false">Meet and greet</md-switch>
                                                    </CCol>
                                                </CRow>
                                            </div>
                                        </CCol>
                                    </CRow>
                                    
                                </CCol>
                                
                                <CCol sm="12" md="12" lg="12" xl="6">
                                    <GmapMap :center="{lat:coordinates.lat,lng:coordinates.lng}" :zoom="4" style="width:100%;height:600px">
                                        <Gmap-Marker v-for="(marker, index) in markers"
                                            :key="index"
                                            :position="marker.position"
                                            ></Gmap-Marker>
                                        

                                        <DirectionsRenderer
                                            travelMode="DRIVING"
                                            :origin="origin"
                                            :destination="destination"
                                            :waypoints="computedWaypoints"
                                            @updated-miles="updatedMiles"
                                            @overview-path="overviewPath"
                                            ref="road" />
                                    </GmapMap>
                                </CCol>
                            
                            </CRow>
                        </md-card-content>
                    </md-card>
                </md-tab>
                <md-tab id="general" md-label="General" :md-template-data="{ badge: tabs.general.errors }">
                    <CRow>
                        <CCol sm="12" lg="6">
                            <md-card class="uplimo-card">
                                <md-card-header>
                                    <div class="md-title">General</div>
                                </md-card-header>
                                <md-card-content>
                                    <md-field :class="getValidationClass('companyProfile')">
                                        <md-select v-model="selectedData.companyProfile" name="company_profile" id="company-profile" placeholder="Company" :disabled="user.data.activeRole == 'driver'">
                                            <md-option v-for="companyProfile in companyProfiles"  :value="companyProfile.id" v-bind:key="companyProfile.id">{{companyProfile.name}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.reservation.companyProfile.required">Company is required</span>
                                    </md-field>
                                   
                                    <md-field :class="getValidationClass('vehicleType')">
                                        <md-select  v-model="selectedData.vehicleType" name="vehicle_type" id="vehicle_type" placeholder="Vehicle Type" :disabled="!$v.reservation.jobType.required || user.data.activeRole == 'driver' ">
                                            <md-option v-for="vehicleType in filteredVehicleTypes" :value="vehicleType.id" v-bind:key="vehicleType.id">{{vehicleType.type}}</md-option>
                                        </md-select>
                                        <span class="md-error" v-if="!$v.reservation.vehicleType.required">Vehicle Type is required</span>
                                    </md-field>
                                    
                                    
                                    
                                    <CRow>
                                        <CCol sm="6">
                                            <md-field :class="getValidationClass('qty')">
                                                <label># Passengers</label>
                                                <md-input v-model="reservation.passenger.qty" type="number" :max="selectedData.passengers" :disabled="user.data.activeRole == 'driver'"></md-input>
                                                <span class="md-error" v-if="!$v.reservation.passenger.qty.required">Passenger quantity is required</span>
                                                <span class="md-error" v-if="!$v.reservation.passenger.qty.between">Number must be between 1 and {{selectedData.passengers}}</span>
                                            </md-field>
                                            
                                        </CCol>
                                        <CCol sm="6">
                                            <md-field :class="getValidationClass('luggage')">
                                                <label># Luggage</label>
                                                <md-input v-model="reservation.passenger.luggage" type="number" :max="selectedData.luggage" :disabled="user.data.activeRole == 'driver'"></md-input>
                                                <span class="md-error" v-if="!$v.reservation.passenger.luggage.required">luggage quantity is required</span>
                                                <span class="md-error" v-if="!$v.reservation.passenger.luggage.between">Number must be between 0 and {{selectedData.luggage}}</span>

                                            </md-field>
                                        </CCol>
                                    </CRow>
                                    
                                    <CRow>
                                        <CCol sm="6">
                                            <md-field>
                                                <label>Notes for passenger</label>
                                                <md-textarea v-model="reservation.notes[0].text" md-autogrow md-counter="300" :disabled="user.data.activeRole == 'driver'"></md-textarea>
                                            </md-field>
                                            
                                            
                                        </CCol>
                                        <CCol sm="6">
                                            <md-field>
                                                <label>Notes for driver</label>
                                                <md-textarea v-model="reservation.notes[1].text" md-autogrow md-counter="300" :disabled="user.data.activeRole == 'driver'"></md-textarea>
                                            </md-field>
                                            
                                        </CCol>
                                        
                                    </CRow>
                                    <CRow>
                                        <CCol sm="12">
                                            <md-field>
                                                <label>Notes for all</label>
                                                <md-textarea v-model="reservation.notes[2].text" md-autogrow md-counter="300" :disabled="user.data.activeRole == 'driver'"></md-textarea>
                                            </md-field>
                                        </CCol>
                                    </CRow>
                                            
                                        
                                </md-card-content>
                            </md-card>
                        </CCol>
                                
                    </CRow>
                </md-tab>
                
                <md-tab id="dispatchment-info" class="pt-0" md-label="Dispatchment" :md-template-data="{ badge: tabs.dispatchmentInfo.errors }" v-if="user.data.activeRole != 'driver'">
                    <CRow class="dispatchment-container mb-4 pt-1 pb-1">
                        <CCol sm="12" class="">
                            
                            <div v-if="reservation.offersBusy">
                                <md-progress-spinner :md-diameter="30" :md-stroke="3" md-mode="indeterminate" style="position:absolute"></md-progress-spinner>
                            </div>
                            <div v-else-if="!reservation.offersBusy">
                                <div
                                    class="md-layout md-alignment-center-left" 
                                    v-if="user.data.activeRole === 'company-admin' || user.data.activeRole === 'provider' "
                                    >
                                    <div class="md-layout-item">
                                        <md-chip  :class="[ statusClass(statuses[reservation.driverStatus])  ]">Status: {{ statuses[reservation.driverStatus] }}</md-chip>
                                        <md-chip  class="accepted" v-if="reservation.acceptedOffers > 0"> Accepted: {{ reservation.acceptedOffers }}</md-chip>
                                        <md-chip  class="flat" v-if="reservation.assignedDriver"> Driver: {{ reservation.assignedDriver.name }} {{ reservation.assignedDriver.lastname }} / {{ reservation.assignedDriver.phone }} </md-chip>
                                        <md-chip  class="taken" v-if="reservation.assignedDriver && reservation.assignedDriver.requestedVehicle" @click="openVehicleDialog"> 
                                            Requested vehicle: {{ reservation.assignedDriver.requestedVehicle.mainInfo.brand }} {{ reservation.assignedDriver.requestedVehicle.mainInfo.model }} 
                                            / Plate: {{ reservation.assignedDriver.requestedVehicle.mainInfo.plate }} 
                                            <md-icon class="ml-2">image</md-icon>
                                            <md-icon>info</md-icon>
                                        </md-chip>
                                        <md-chip  class="taken" v-if="reservation.directAssignment && statuses[reservation.driverStatus] == 'Assigned'">Assignment mode: Direct</md-chip>
                                        <md-chip  class="taken" v-if="statuses[reservation.driverStatus] == 'Offered'">Assignment mode: Offering</md-chip>              
                                        
                                        
                                    </div>
                                        
                                    
                
                                </div>
                            </div>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol sm="12" md="12">
                            <vue-tabs ref="modeTab" @tab-change="modeTabChanged" :centered="false"	:type="'pills'" :direction="'horizontal'">
                                <v-tab title="Offering mode">
                                    <CRow v-if="reservation.directAssignment">
                                        <CCol sm="12">
                                            <md-empty-state
                                                md-icon="devices_other"
                                                md-label="Direct assigment active"
                                                md-description="Please check direct assigment mode tab.">
                                                <md-button class="md-primary md-raised" @click="goToTabInner('assignment-mode')">Go to direct assigment tab </md-button>
                                                </md-empty-state>
                                        </CCol>
                                    </CRow>
                                    <CRow class="p-2" v-else>
                                        <CCol sm="12" md="12">
                                            
                                            <div class="md-subheading text-uppercase mt-4 mb-2 up-text-primary">Active offers</div>
                                            <md-table md-card class="mb-4" @md-selected="onOfferSelected" v-model="filteredPopOffers" v-if="offers && offers.length > 0">
                                                <md-table-toolbar class="md-primary" >
                                                    <div class="md-toolbar-section-end" v-if="statuses[reservation.driverStatus] != 'Assigned'">
                                                    <md-checkbox v-model="offerFilters" value="Accepted">Accepted</md-checkbox>
                                                    <md-checkbox v-model="offerFilters" value="Offered">Offered</md-checkbox>
                                                    <md-checkbox v-model="offerFilters" value="Pre assigned">Pre assigned</md-checkbox>
                                                </div>
                                            </md-table-toolbar>
                                            <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }" >
                                                <div class="md-toolbar-section-start">{{ count }} {{ count == 1 ? 'offer' : 'offers' }} selected</div>
                                                
                                                <div class="md-toolbar-section-end">
                                                    
                                                    <!--md-button class="md-raised md-primary" v-if="count === 1">Assign job</md-button-->
                                                    <md-button 
                                                    class="md-raised md-accent mr-0" 
                                                        @click="deleteOffers()"
                                                        :disabled="driverAssignment.busy" 
                                                        v-if="statuses[driverAssignment.selectedOffers[0].status] != 'Assigned' && statuses[driverAssignment.selectedOffers[0].status] != 'On hold'"
                                                        >
                                                        Delete {{ count == 1 ? 'offer' : 'offers' }}
                                                        <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                                                    </md-button>
                                                    <md-button 
                                                    class="md-raised md-primary mr-0 ml-2" 
                                                    v-if="statuses[driverAssignment.selectedOffers[0].status] === 'Accepted' && driverAssignment.selectedOffers.length === 1 "
                                                    :disabled="driverAssignment.busy" 
                                                        @click="assignDriver()"
                                                    >
                                                    Assign user
                                                    <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                                                        </md-button>
                                                        <md-button
                                                        class="md-raised md-primary mr-0 ml-2"
                                                        v-if="(statuses[driverAssignment.selectedOffers[0].status] === 'Assigned' || statuses[driverAssignment.selectedOffers[0].status] === 'On hold') && driverAssignment.selectedOffers.length === 1 "
                                                        :disabled="driverAssignment.busy"
                                                        @click="revokeAssignation()">
                                                        Revoke assignation
                                                        <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                                                    </md-button>
                                                    
                                                </div>
                                            </md-table-toolbar>
                                                
                                                <md-table-row 
                                    slot="md-table-row" slot-scope="{ item }"
                                    class="text-left"
                                    md-selectable="multiple" >
                                    
                                    <md-table-cell md-label="Name">{{ item.name }} {{ item.lastname }}
                                        <div class="mt-2">

                                            <md-chip   v-if="item.tlc && item.tlc.check">
                                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                TLC Live: <b>{{item.tlc.check.type}}</b>
                                            </md-chip>
                                            <md-chip  v-if="item.tlc && item.tlc.check">
                                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                TLC Expiration: <b>{{item.tlc.check.expiration_date | moment('YYYY-MM-DD')}}</b>
                                            </md-chip>
                                        </div>
                                        
                                    </md-table-cell>
                                    <md-table-cell md-label="Email">{{ item.email }}</md-table-cell>
                                    <md-table-cell md-label="Code">{{ item.code }}</md-table-cell>
                                    <md-table-cell md-label="Role" md-sort-by="role" class="text-left">
                                        {{ item.subrole }} {{ item.role }}
                                    </md-table-cell>
                                    <md-table-cell md-label="Requested vehicle">
                                        
                                        <div v-if="item.requestedVehicle">
                                            <div class="md-layout passenger-layout md-alignment-center-left flex-wrap-no">
                                                <div class="md-layout-item">
                                                    <p class="white-space-no-wrap pr-4 mb-0">
                                                        {{  item.requestedVehicle.name }} ({{ item.requestedVehicle.vehicleType.name}})
                                                    </p>
                                                    
                                                
                                                </div>
                                                <div class="md-layout-item">
                                                    <div class="qty-container white-space-no-wrap">
                                                        <md-icon class="mr-1">emoji_people</md-icon>
                                                        <span class="big-qty">{{item.requestedVehicle.passengers}}</span>
                                                    </div>
                                                    <div class="qty-container white-space-no-wrap">
                                                        <md-icon class="mr-1">luggage</md-icon>
                                                        <span class="big-qty">{{item.requestedVehicle.luggage}}</span>
                                                    </div>
                                                
                                                </div>
                                                
                                            </div>
                                            
                                           
                                        </div>
                                        <div v-else>
                                            -
                                        </div>
                                        
                                    </md-table-cell>
                                    <md-table-cell md-label="Phone"  class="text-left">{{ item.phones.length > 0 ? item.phones[0] : '' }}</md-table-cell>
                                    <md-table-cell md-label="Status">
                                        <md-chip :class="[ statusClass(statuses[item.status])  ]">
                                            {{ statuses[item.status]}}
                                        </md-chip>
                                    </md-table-cell>
                                    <md-table-cell md-label="Community">{{ item.community ? item.community.name : '-'}}</md-table-cell>
                                    <md-table-cell md-label="Assignation timeline" v-if="(statuses[reservation.driverStatus] == 'Assigned' || statuses[reservation.driverStatus] == 'On hold') && item.timeline">
                                        
                                        <md-table>
                                            <md-table-row>
                                                <md-table-head>Status</md-table-head>
                                                <md-table-head>Step number</md-table-head>
                                                <md-table-head>User in charge</md-table-head>
                                                <md-table-head>Email</md-table-head>
                                                <md-table-head>Phone</md-table-head>
                                            </md-table-row>
                                            <md-table-row v-for="(dot, index) in item.timeline" v-bind:key="index + '-table-mode-' + dot.userInCharge.uid">
                                                <md-table-cell>
                                                    <md-chip :class="[ statusClass(statuses[dot.status])  ]">
                                                        Status: {{ statuses[dot.status] }}
                                                    </md-chip> 
                                                </md-table-cell>
                                                <md-table-cell>{{ index + 1 }}</md-table-cell>
                                                <md-table-cell>{{ dot.userInCharge.name }} {{ dot.userInCharge.lastname }}</md-table-cell>
                                                <md-table-cell>{{ dot.userInCharge.email }} </md-table-cell>
                                                <md-table-cell>{{ dot.userInCharge.phone }} </md-table-cell>
                                            </md-table-row>
                                        </md-table>
                                    </md-table-cell>
                                    
                                    
                                                </md-table-row>
                                            </md-table>  
                                            <md-empty-state
                                                v-else
                                                class="md-accent"
                                                md-icon="list"
                                                md-label="No offers made yet"
                                                md-description="Add offers below to start driver assignment.">
                                            </md-empty-state>
                                                            
                                        </CCol>
                                        <CCol sm="12" md="12" v-if="reservation && notAssignedStatus()" >
                                            <div class="md-subheading text-uppercase mt-4 mb-2 up-text-primary">Add offers</div>
                                            <div>
                                                        
                                                <CRow>
                                                    <CCol sm="12">
                                                        <vue-tabs ref="offerModeTab" @tab-change="addOffersTabChanged"	:type="'pills'">
                                                            <v-tab title="Drivers, companies and providers list"> 
                                                                <md-toolbar class="md-dense mt-3 pl-4">
                                                                    
                                                                   
                                                                    <md-switch v-model="driverAssignment.tlcOn" class="md-primary mr-4"  @change="tlcChanged" :disabled="driverAssignment.tlcBusy">
                                                                        {{driverAssignment.tlcBusy ? 'Checking TLC' : 'Check TLC' }} 
                                                                        <md-progress-bar md-mode="indeterminate" v-if="driverAssignment.tlcBusy"></md-progress-bar>
                                                                    </md-switch>
                                                                    <md-switch v-model="driverAssignment.dmvOn" class="md-primary"  @change="dmvChanged" :disabled="driverAssignment.dmvBusy">
                                                                        {{driverAssignment.dmvBusy ? 'Checking Vehicles DMV' : 'Check DMV' }} 
                                                                        <md-progress-bar md-mode="indeterminate" v-if="driverAssignment.dmvBusy"></md-progress-bar>
                                                                    </md-switch>
                                                                    
                                                                    
                                                                </md-toolbar>
                                                                <md-table 
                                                                    v-if="driverAssignment.filteredLinkedDrivers.length > 0" 
                                                                    v-model="driverAssignment.filteredLinkedDrivers" 
                                                                    md-card 
                                                                    @md-selected="onSelectAllDriverList" 
                                                                    class="alternate-header-position-relative selectable-table">
                                                                    
                                                                    <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }" class="mobile-alternate bk-grey-1 p-3">
                                                                        
                                                                        <div class="md-toolbar-row">
                                                                            <div class="md-toolbar-section-start">{{ count }} {{ count === 1 ? 'driver' : 'drivers' }} selected</div>
                                                                            
                                                                            
                                                                            <div class="md-toolbar-section-end pr-0">
                                                                                
                                                                                <md-button 
                                                                                class="md-primary md-raised" 
                                                                                @click="sendOffer()"
                                                                                :disabled="driverAssignment.busy" 
                                                                    
                                                                                    >
                                                                                    {{ driverAssignment.busy ? 'Sending offer' : 'Send offer' }}
                                                                                    <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                                                                                </md-button>
                                                                            </div>
                                                            
                                                                        </div>
                                                                        <div class="md-toolbar-row">
                                                                            <md-field  :class="getValidationClass('driverAmount')">
                                                                                <label>Driver amount ($)</label>
                                                                                <md-input v-model="reservation.driverAmount" type="number"></md-input>
                                                                                <span class="md-error" v-if="!$v.reservation.driverAmount.required">Amount is required</span>
                                                                            </md-field>
                                                                            <md-field >
                                                                                <label>Offer comment</label>
                                                                                <md-input v-model="reservation.offerComment" type="text"></md-input>
                                                                            </md-field>
                                                                        </div>
                                                                        
                                                                    </md-table-toolbar>
                                                                    
                                                                  
                                                                    <md-table-row slot="md-table-row" slot-scope="{ item }"  md-selectable="multiple"  :md-disabled="(item.role == 'driver' && item.fleet.length == 0)">
                                                                        <md-table-cell md-label="Name"  class="text-left white-space-no-wrap">
                                                                            {{ item.name }} {{ item.lastname }}
                                                                            <div class="mt-2" v-if="driverAssignment.tlcOn">

                                                                                <md-chip   v-if="item.tlc && item.tlc.check">
                                                                                    <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                                                    TLC Live: <b>{{item.tlc.check.type}}</b>
                                                                                </md-chip>
                                                                                <md-chip  v-if="item.tlc && item.tlc.check">
                                                                                    <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                                                    TLC Expiration: <b>{{item.tlc.check.expiration_date | moment('YYYY-MM-DD')}}</b>
                                                                                </md-chip>
                                                                            </div>
                                                                         </md-table-cell>
                                                                        <md-table-cell md-label="Code" md-sort-by="code" class="text-left white-space-no-wrap">{{ item.code }} </md-table-cell>
                                                                        <md-table-cell md-label="Role" md-sort-by="role" class="text-left white-space-no-wrap">
                                                                            {{ item.subrole }} {{ item.role }}
                                                                        </md-table-cell>
                                                                        <md-table-cell md-label="Phone"  class="text-left white-space-no-wrap">{{ item.phones.length > 0 ? item.phones[0] : '' }}</md-table-cell>
                                                                        <md-table-cell md-label="Email" md-sort-by="email" class="text-left white-space-no-wrap">{{ item.email }}</md-table-cell>
                                                                        <md-table-cell md-label="Areas" class="text-left">
                                                                            <ul class="pl-0 mb-0">
                                                                                <li v-for="(area,index) in item.areas" v-bind:key="area.formatted_address + index" class="white-space-no-wrap">
                                                                                    {{ area.formatted_address }}
                                                                                </li>
                                                                            </ul>
                                                                        </md-table-cell>
                                                                        <md-table-cell md-label="Fleet" class="text-left">

                                                                            <md-field  :class="{'md-invalid': checkVehicleRequired(item) }" :id="'vehicle' + item.uid" v-if="item.selected && item.fleet.length > 0">
                                                                                <label :for="'vehicle' + item.uid">Select driver vehicle</label>
                                                                                <md-select @md-selected="onSelectedVehicle" v-model="item.requestedVehicle">
                                                                                    <md-option v-for="(vehicle,index) in item.fleet" v-bind:key="vehicle.name + '-selector-' + index" :value="index + '-' + item.id + '-' + item.index">
                                                                                        {{ vehicle.name }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
                                                                                    </md-option>
                                                                                </md-select>
                                                                                <span class="md-error">Vehicle is required</span>
                                                                            </md-field>
                                                                            <ul class="pl-0 mb-0"  v-if="!item.selected && item.fleet.length > 0">
                                                                                <li v-for="(vehicle,index) in item.fleet" v-bind:key="vehicle.name + index"  class="white-space-no-wrap">
                                                                                    {{ vehicle.name }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
                                                                                        <span class="mt-2 ml-2"  v-if="vehicle.dmvCheck && driverAssignment.dmvOn">

                                                                                            <md-chip  >
                                                                                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                                                                DMV Active: <b>{{vehicle.dmvCheck.active}}</b>
                                                                                            </md-chip>
                                                                                            <md-chip  >
                                                                                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                                                               Base name: <b>{{vehicle.dmvCheck.base_name }}</b>
                                                                                            </md-chip>
                                                                                            <md-chip  >
                                                                                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                                                               Base type: <b>{{vehicle.dmvCheck.base_type }}</b>
                                                                                            </md-chip>
                                                                                            <md-chip  >
                                                                                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                                                               Vehicle: <b>{{vehicle.dmvCheck.vehicle_year }}</b>
                                                                                            </md-chip>
                                                                                        </span>
                                                                                    
                                                                                </li>
                                                                            </ul>
                                                                        </md-table-cell>
                                                                            
                                                                    </md-table-row>
                                                                </md-table>
                                                                <md-empty-state
                                                                    v-else
                                                                    class="md-accent"
                                                                    md-icon="car_crash"
                                                                    md-label="No more available drivers"
                                                                    md-description="There no more possible owner operators or company drivers to offer.">
                                                                </md-empty-state> 
                                                            </v-tab>
                                                            <v-tab title="Communities">
                                                                <md-table 
                                                                    v-if="driverAssignment.communities.length > 0" 
                                                                    v-model="driverAssignment.communities" 
                                                                    md-card 
                                                                    @md-selected="onSelectCommunity" 
                                                                    class="alternate-header-position-relative mt-4 selectable-table">
                                                                    
                                                                    <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }" class="mobile-alternate bk-grey-1 p-3">
                                                                        <div class="md-toolbar-row">
                                                                            
                                                                            <div class="md-toolbar-section-start">{{ count }} {{ count === 1 ? 'community' : 'communities'}} selected</div>
                                                                            
                                                                            <div class="md-toolbar-section-end pr-0">
                                                                                
                                                                                <md-button class="md-primary md-raised" @click="sendCommunitiesOffers" :disabled="driverAssignment.busy">
                                                                                    
                                                                                    {{ driverAssignment.busy ? 'Sending offers' : 'Send offers' }}
                                                                                    <md-progress-bar md-mode="indeterminate" v-show="driverAssignment.busy"></md-progress-bar>
                                                                                </md-button>
                                                                            </div>
                                                                        </div>
                                                                        <div class="md-toolbar-row">
                                                                                <md-field class="" :class="{'md-invalid':getValidationClass('driverAmount')}">
                                                                                    <label>Driver amount ($)</label>
                                                                                    <md-input v-model="reservation.driverAmount" type="number"></md-input>
                                                                                    <span class="md-error" v-if="!$v.reservation.driverAmount.required">Amount is required</span>
                                                                                </md-field>
                                                                                <md-field>
                                                                                    <label>Offer comment</label>
                                                                                    <md-input v-model="reservation.offerComment" type="text"></md-input>
                                                                                </md-field>
                                                                                
                                                                            </div>
                                                                    </md-table-toolbar>
                                                                        
                                                                    <md-table-row 
                                                                    slot="md-table-row" slot-scope="{ item }" 
                                                                    md-selectable="multiple" 
                                                                    md-auto-select 
                                                                    :md-disabled="(reservation.offeredCommunities && reservation.offeredCommunities.includes(item.id))" >
                                                                        <md-table-cell md-label="Name" class="text-left">
                                                                            {{ item.name }}
                                                                        </md-table-cell>
                                                                        
                                                                        <md-table-cell md-label="Status" class="text-left">
                                                                            {{ reservation.offeredCommunities && reservation.offeredCommunities.includes(item.id) ? 'Already used' : 'Available to offer'}}
                                                                        </md-table-cell>
                                                                        
                                                                        
                                                                        
                                                                    </md-table-row>
                                                                </md-table>
                                                            </v-tab>
                                                        </vue-tabs>

                                                        
                                                    </CCol>
                                                </CRow>
                                            </div> 
                                    
                                    
                                
                                        </CCol>
                                    </CRow>
                                </v-tab>

                                <v-tab title="Direct mode">
                                    <div class="modal-up-content text-center mt-4">

                                        <md-toolbar md-elevation="1" v-if="directAssignment.selectedDriver" class="mobile-alternate p-3">
                                           
                                                <div class="md-layout">
                                                    <div class="md-layout-item">
                                                        <div class="md-body-2 mb-2">{{ directAssignment.selectedDriver.email }} ({{ directAssignment.selectedDriver.code }})</div>
                                                    </div>
                                                   
                                                    
                                                </div>
                                                      
                                           
                                            <div class="md-toolbar-row">
                                                <md-field>
                                                    <label>Driver amount ($)</label>
                                                    <md-input v-model="reservation.driverAmount" type="number"></md-input>
                                                    
                                                </md-field>
                                                <md-field >
                                                    <label>Offer comment</label>
                                                    <md-input v-model="reservation.offerComment" type="text"></md-input>
                                                </md-field>
                                            </div>
                                            <div class="md-toolbar-row">
                                                <md-button 
                                                    class="md-primary md-raised" 
                                                    @click="sendDirectAssigment()"
                                                    :disabled="directAssignment.busy" 
                                                    
                                                    >
                                                    {{ directAssignment.busy ? 'Sending direct assignment' : 'Send direct assignment' }}
                                                    <md-progress-bar md-mode="indeterminate" v-show="directAssignment.busy"></md-progress-bar>
                                                </md-button>

                                            </div>
                                        </md-toolbar>

                                        <!---->
                                        <div v-if="reservation && offers && offers.length > 0">
                                        <md-table md-card class="mb-4 selectable-table" @md-selected="onDirectOfferSelected" v-model="filteredDirectOffers">
                                            
                                            <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
                                            <div class="md-toolbar-row">

                                                <div class="md-toolbar-section-start">{{ count }} {{ count == 1 ? 'offer' : 'offers' }} selected</div>

                                                <div class="md-toolbar-section-end">
                                                
                                                <!--md-button class="md-raised md-primary" v-if="count === 1">Assign job</md-button-->
                                                <md-button 
                                                    class="md-raised md-accent mr-0" 
                                                    @click="deleteAssignation()"
                                                    :disabled="directAssignment.busy" 
                                                    v-if="statuses[directAssignment.selectedOffers[0].status] != 'Assigned' && statuses[directAssignment.selectedOffers[0].status] != 'On hold'"
                                                >
                                                    Delete direct assignation
                                                    <md-progress-bar md-mode="indeterminate" v-show="directAssignment.busy"></md-progress-bar>
                                                </md-button>
                                                <md-button 
                                                    class="md-raised md-primary mr-0 ml-2" 
                                                    v-if="statuses[directAssignment.selectedOffers[0].status] === 'Accepted' && directAssignment.selectedOffers.length === 1 "
                                                    :disabled="directAssignment.busy" 
                                                    @click="assignDriver()"
                                                >
                                                    Assign user
                                                    <md-progress-bar md-mode="indeterminate" v-show="directAssignment.busy"></md-progress-bar>
                                                </md-button>
                                                <md-button
                                                    class="md-raised md-primary mr-0 ml-2"
                                                    v-if="(statuses[directAssignment.selectedOffers[0].status] === 'Assigned' || statuses[directAssignment.selectedOffers[0].status] === 'On hold') && directAssignment.selectedOffers.length === 1 "
                                                    :disabled="directAssignment.busy"
                                                    @click="revokeDirectAssignation()">
                                                    Revoke assignation
                                                    <md-progress-bar md-mode="indeterminate" v-show="directAssignment.busy"></md-progress-bar>
                                                </md-button>
                                                
                                                </div>
                                            </div>
                                            
                                            </md-table-toolbar>
                                            
                                            <md-table-row 
                                                slot="md-table-row" slot-scope="{ item }"
                                                class="text-left"
                                                md-selectable="multiple"
                                                
                                                 >
                                            
                                            <md-table-cell md-label="Name" class="white-space-no-wrap">
                                                {{ item.name }} {{ item.lastname }}
                                                <div class="mt-2">

                                                    <md-chip   v-if="item.tlc && item.tlc.check">
                                                        <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                        TLC Live: <b>{{item.tlc.check.type}}</b>
                                                    </md-chip>
                                                    <md-chip  v-if="item.tlc && item.tlc.check">
                                                        <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                        TLC Expiration: <b>{{item.tlc.check.expiration_date | moment('YYYY-MM-DD')}}</b>
                                                    </md-chip>
                                                </div>
                                            </md-table-cell>
                                            <md-table-cell md-label="Email" class="white-space-no-wrap">{{ item.email}}</md-table-cell>
                                            <md-table-cell md-label="Code" class="white-space-no-wrap">{{ item.code }}</md-table-cell>
                                            <md-table-cell md-label="Role" md-sort-by="role" class="text-left white-space-no-wrap">
                                                        {{ item.subrole }} {{ item.role }}
                                            </md-table-cell>
                                            <md-table-cell md-label="Phone"  class="text-left white-space-no-wrap">{{ item.phones.length > 0 ? item.phones[0] : '' }}</md-table-cell>
                                            <md-table-cell md-label="Requested vehicle">
                                        
                                                <div v-if="item.requestedVehicle">
                                                    <div class="md-layout passenger-layout md-alignment-center-left flex-wrap-no">
                                                        <div class="md-layout-item">
                                                            <p class="white-space-no-wrap pr-4 mb-0">
                                                                {{  item.requestedVehicle.name }} ({{ item.requestedVehicle.vehicleType.name}})
                                                            </p>
                                                            
                                                        
                                                        </div>
                                                        <div class="md-layout-item">
                                                            <div class="qty-container white-space-no-wrap">
                                                                <md-icon class="mr-1">emoji_people</md-icon>
                                                                <span class="big-qty">{{item.requestedVehicle.passengers}}</span>
                                                            </div>
                                                            <div class="qty-container white-space-no-wrap">
                                                                <md-icon class="mr-1">luggage</md-icon>
                                                                <span class="big-qty">{{item.requestedVehicle.luggage}}</span>
                                                            </div>
                                                        
                                                        </div>
                                                        
                                                    </div>
                                                    
                                                
                                                </div>
                                                <div v-else>
                                                    -
                                                </div>
                                                
                                            </md-table-cell>
                                            <md-table-cell md-label="Status">
                                                <md-chip :class="[ statusClass(statuses[item.status])  ]">
                                                {{ statuses[item.status]}}
                                                </md-chip>
                                            </md-table-cell>
                                            <md-table-cell md-label="Community">{{ item.community ? item.community.name : '-'}}</md-table-cell>
                                            <md-table-cell md-label="Assignation timeline" v-if="(statuses[reservation.driverStatus] == 'Assigned' || statuses[reservation.driverStatus] == 'On hold') && item.timeline">
                                                
                                                <md-table>
                                                <md-table-row>
                                                    <md-table-head>Status</md-table-head>
                                                    <md-table-head>Step number</md-table-head>
                                                    <md-table-head>User in charge</md-table-head>
                                                    <md-table-head>Email</md-table-head>
                                                    <md-table-head>Phone</md-table-head>
                                                </md-table-row>
                                                <md-table-row v-for="(dot, index) in item.timeline" v-bind:key="index + '-table-mode-' + dot.userInCharge.uid">
                                                    <md-table-cell>
                                                    <md-chip :class="[ statusClass(statuses[dot.status])  ]">
                                                        Status: {{ statuses[dot.status] }}
                                                    </md-chip> 
                                                    </md-table-cell>
                                                    <md-table-cell>{{ index + 1 }}</md-table-cell>
                                                    <md-table-cell>{{ dot.userInCharge.name }} {{ dot.userInCharge.lastname }}</md-table-cell>
                                                    <md-table-cell>{{ dot.userInCharge.email }} </md-table-cell>
                                                    <md-table-cell>{{ dot.userInCharge.phone }} </md-table-cell>
                                                </md-table-row>
                                                </md-table>
                                            </md-table-cell>
                                            
                                            
                                            </md-table-row>
                                        </md-table>  
                                        <md-divider class="mb-4"></md-divider>
                                        </div>
                                        <!---->
                                        <md-toolbar class="md-dense mt-3 pl-4">
                                            
                                            
                                            <md-switch v-model="directAssignment.tlcOn" class="md-primary mr-4"  @change="directTlcChanged" :disabled="directAssignment.tlcBusy">
                                                {{directAssignment.tlcBusy ? 'Checking TLC' : 'Check TLC' }} 
                                                <md-progress-bar md-mode="indeterminate" v-if="directAssignment.tlcBusy"></md-progress-bar>
                                            </md-switch>
                                            <md-switch v-model="directAssignment.dmvOn" class="md-primary"  @change="directDmvChanged" :disabled="directAssignment.dmvBusy">
                                                {{directAssignment.dmvBusy ? 'Checking Vehicles DMV' : 'Check DMV' }} 
                                                <md-progress-bar md-mode="indeterminate" v-if="directAssignment.dmvBusy"></md-progress-bar>
                                            </md-switch>
                                            
                                            
                                        </md-toolbar>
                                        <md-table 
                                            v-if="directAssignment.filteredLinkedDrivers.length > 0 && reservation && !reservation.directAssignment" 
                                            v-model="directAssignment.filteredLinkedDrivers" 
                                            md-card 
                                            @md-selected="onSelectDirectAssignationList"
                                            class="selectable-table">
                                                    

                                        <md-table-row slot="md-table-row" slot-scope="{ item }"  md-selectable="multiple" :md-disabled="(item.role == 'driver' && item.fleet.length == 0) || item.disabled">
                                            <md-table-cell md-label="Name" md-sort-by="lastname" class="text-left">
                                                {{ item.name }} {{ item.lastname }}
                                                
                                                    <span class="mt-2" v-if="directAssignment.tlcOn">
                                                        <md-chip   v-if="item.tlc && item.tlc.check">
                                                            <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                            TLC Live: <b>{{item.tlc.check.type}}</b>
                                                        </md-chip>
                                                        <md-chip  v-if="item.tlc && item.tlc.check">
                                                            <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                            TLC Expiration: <b>{{item.tlc.check.expiration_date | moment('YYYY-MM-DD')}}</b>
                                                        </md-chip>
                                                    </span>
                                                
                                            </md-table-cell>
                                            <md-table-cell md-label="Code" md-sort-by="code" class="text-left">{{ item.code }}</md-table-cell>
                                            <md-table-cell md-label="Role" md-sort-by="role" class="text-left white-space-no-wrap">
                                            {{ item.subrole }} {{ item.role }}
                                            </md-table-cell>
                                            <md-table-cell md-label="Phone"  class="text-left white-space-no-wrap">{{ item.phones.length > 0 ? item.phones[0] : '' }}</md-table-cell>
                                            <md-table-cell md-label="Email" md-sort-by="email" class="text-left">{{ item.email }}</md-table-cell>
                                            <md-table-cell md-label="Areas" class="text-left">
                                            <ul class="pl-0 mb-0">
                                                <li v-for="(area,index) in item.areas" v-bind:key="area.formatted_address + index" class="white-space-no-wrap">
                                                {{ area.formatted_address }}
                                                </li>
                                            </ul>
                                            </md-table-cell>
                                            <md-table-cell md-label="Fleet" class="text-left">
                                                <!-- Todo select direct HTML fleet -->
                                                <md-field  :class="{'md-invalid': checkVehicleRequired(item) }" :id="'direct-vehicle' + item.uid" v-if="item.selected && item.fleet.length > 0">
                                                    <label :for="'vehicle' + item.uid">Select driver vehicle</label>
                                                    <md-select @md-selected="onSelectedVehicle" v-model="item.requestedVehicle">
                                                        <md-option v-for="(vehicle,index) in item.fleet" v-bind:key="vehicle.name + '-selector-' + index" :value="index + '-' + item.id + '-' + item.index">
                                                            {{ vehicle.name }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
                                                        </md-option>
                                                    </md-select>
                                                    <span class="md-error">Vehicle is required</span>
                                                </md-field>
                                                <ul class="pl-0 mb-0"  v-if="!item.selected && item.fleet.length > 0">
                                                    <li v-for="(vehicle,index) in item.fleet" v-bind:key="vehicle.name + index"  class="white-space-no-wrap">
                                                        {{ vehicle.name }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>
                                                        <span class="mt-2 ml-2"  v-if="vehicle.dmvCheck && directAssignment.dmvOn">

                                                            <md-chip  >
                                                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                                DMV Active: <b>{{vehicle.dmvCheck.active}}</b>
                                                            </md-chip>
                                                            <md-chip  >
                                                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                            Base name: <b>{{vehicle.dmvCheck.base_name }}</b>
                                                            </md-chip>
                                                            <md-chip  >
                                                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                            Base type: <b>{{vehicle.dmvCheck.base_type }}</b>
                                                            </md-chip>
                                                            <md-chip  >
                                                                <md-icon class="mr-2 led-circle green pulse">circle</md-icon>
                                                            Vehicle: <b>{{vehicle.dmvCheck.vehicle_year }}</b>
                                                            </md-chip>
                                                        </span>
                                                    </li>
                                                </ul>
                                                <!--ul class="pl-0 mb-0">
                                                    <li v-for="(vehicle,index) in item.fleet" v-bind:key="vehicle.name + index">
                                                    {{ vehicle.name }} <b v-if="vehicle.vehicleType">({{ vehicle.vehicleType.name }})</b>

                                                    </li>
                                                </ul-->
                                            </md-table-cell>
                                            
                                        </md-table-row>
                                        </md-table>

                                    </div>
                                </v-tab>

                                
                            </vue-tabs>
                                  
                        </CCol>
                        
                    </CRow>
                </md-tab>
                <md-tab id="addons" md-label="Add ons" :md-template-data="{ badge: tabs.addons.errors }">
                    <CRow>
                        <CCol sm="12" lg="6">
                            <md-card class="uplimo-card">
                                <md-card-header>
                                    <div class="md-title">Addons</div>
                                </md-card-header>
                                <md-card-content>
                                    <md-field v-if="reservation.vehicleType && reservation.vehicleType.rates.addonsAvailable">
                                        
                                        <md-table>
                                            <md-table-row>
                                                <md-table-head>Name</md-table-head>
                                                <md-table-head>Qty</md-table-head>
                                                <md-table-head v-if="user.data.activeRole != 'driver'">Price</md-table-head>
                                                <md-table-head v-if="user.data.activeRole != 'driver'">Subtotal</md-table-head>
                                            </md-table-row>
                                            <template v-for="addon in reservation.vehicleType.rates.addons">
                                                <md-table-row v-if="addon.enabled && addon.price" v-bind:key="'reservation-' + addon.id">

                                                    <md-table-cell>{{addon.name}}
                                                    </md-table-cell>

                                                    <md-table-cell>
                                                        <md-field >
                                                            <label>Addon quantity</label>
                                                            <md-input type="number" v-model="addon.qty" min="0" :disabled="user.data.activeRole == 'driver'"></md-input>
                                                        </md-field>
                                                    </md-table-cell>

                                                    <md-table-cell v-if="user.data.activeRole != 'driver'">{{addon.price}}
                                                    </md-table-cell>
                                                    
                                                    <md-table-cell v-if="user.data.activeRole != 'driver'">
                                                        {{ addon.qty ? parseFloat(addon.qty) * parseFloat(addon.price) : 0}}
                                                    </md-table-cell>

                                                </md-table-row>
                                                
                                            </template>
                                        </md-table>
                                        
                                    </md-field>
                                    <md-empty-state
                                        v-else
                                        class="mt-4 md-primary"
                                        md-rounded
                                        md-icon="sentiment_dissatisfied"
                                        md-label="No addons available"
                                        md-description="No addons available for selected vehicle">
                                    </md-empty-state>
                                </md-card-content>
                            </md-card>
                        </CCol>
                    
                    </CRow>
                </md-tab>
                <md-tab id="rates-and-taxes" md-label="Rates and taxes" :md-template-data="{ badge: tabs.ratesAndTaxes.errors }" v-if="user.data.activeRole != 'driver'">
                    <md-empty-state
                        v-if="!rateData"
                        class="mt-4 md-primary"
                        md-rounded
                        md-icon="request_quote"
                        md-label="Calculated rates"
                        md-description="Rate detail will be displayed here when required information is completed">
                    </md-empty-state>
                    
                    <md-card class="uplimo-card" v-if="rateData">
                        <md-card-header>
                            <div class="md-title">Rate details</div>
                        </md-card-header>
                        <md-card-content>
                            <md-table>
                                <md-table-row>
                                    <md-table-head>Reference</md-table-head>
                                    <md-table-head class="text-right">Units, minutes or %</md-table-head>
                                    <md-table-head class="text-right">$ cost per unit</md-table-head>
                                    <md-table-head class="text-right">$ Subtotal No Tax</md-table-head>
                                    <md-table-head class="text-right">$ Subtotal + Tax</md-table-head>
                                </md-table-row>
                                <md-table-row>
                                    <md-table-cell colspan="5" class="bg-black">Ride related</md-table-cell>
                                    
                                </md-table-row>
                                <md-table-row v-if="rateData.activeFlatRate">
                                    <md-table-cell>{{rateData.activeFlatRate.name}} (Flat rate)</md-table-cell>
                                    <md-table-cell class="text-right">1</md-table-cell>
                                    <md-table-cell class="text-right">
                                         <md-field>
                                            <md-input v-model="rateData.activeFlatRate.price" type="number" @change="updateRideRelatedSubtotal()"></md-input>
                                        </md-field>
                                        
                                    </md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.activeFlatRate.price}}</md-table-cell>
                                </md-table-row>
                                <md-table-row v-if="reservation.vehicleType && rateData.miniumRate && !rateData.activeFlatRate">
                                    <md-table-cell>Minium rate</md-table-cell>
                                    <md-table-cell class="text-right">1</md-table-cell>
                                    <md-table-cell class="text-right">
                                         <md-field>
                                            <md-input v-model="rateData.rates.miniumRate" type="number" @change="updateRideRelatedSubtotal()"></md-input>
                                        </md-field>
                                        
                                    </md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.miniumRate}}</md-table-cell>
                                </md-table-row>
                                <template v-if="!rateData.activeFlatRate && !rateData.miniumRate">
                                    <md-table-row v-for="(rate,index) in rateData.rateSpread" v-bind:key="index">
                                        <md-table-cell v-if="rate.base"> Base rate <b>(The first {{rateData.rates.base.includedMiles}} miles)</b></md-table-cell>
                                        <md-table-cell v-else>Miles</md-table-cell>

                                        <md-table-cell class="text-right"> {{ rate.base ? '1' : roundNumber(rate.milesQty)}}</md-table-cell>
                                        <md-table-cell class="text-right">
                                            
                                            <md-field v-if="rate.base">
                                                <md-input v-model="rate.rate" type="number" @change="updateRideRelatedSubtotal()"></md-input>
                                            </md-field>
                                            <md-field v-else>
                                                 <md-input v-model="rate.ratePerMile" type="number" @change="rate.rate = rate.ratePerMile * rate.milesQty; updateRideRelatedSubtotal()"></md-input>
                                            </md-field>
                                        </md-table-cell>
                                        <md-table-cell class="text-right">0.00</md-table-cell>
                                        <md-table-cell class="text-right">{{ roundNumber(rate.rate) }}</md-table-cell>    
                                    </md-table-row>
                                </template>
                                
                                
                                
                                <md-table-row v-if="rateData.stops && rateData.stops.length > 0">
                                    <md-table-cell>Stops</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.stops.length}}</md-table-cell>
                                    <md-table-cell class="text-right">
                                        
                                        <md-field>
                                            <md-input v-model="rateData.rates.stop" type="number" @change="rateData.subtotals.stops = rateData.stops.length * rateData.rates.stop; updateRideRelatedSubtotal()"></md-input>
                                        </md-field>
                                    </md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.subtotals.stops}}</md-table-cell>
                                </md-table-row>
                                <md-table-row v-if="rateData.stops && rateData.stops.length > 0">
                                    <md-table-cell>Total waiting time on stops</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.subtotals.waitingTimeMinutesTotal}}</md-table-cell>
                                    <md-table-cell class="text-right">
                                        <md-field>
                                            <md-input :value="roundNumber(rateData.rates.waitingTime / 60)" type="number" @change="rateData.subtotals.waitingTimePriceTotal = $event.target.value * rateData.subtotals.waitingTimeMinutesTotal; updateRideRelatedSubtotal()"></md-input>
                                            <span class="md-helper-text">Per minute rate</span>
                                        </md-field>
                                        
                                    </md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{roundNumber(rateData.subtotals.waitingTimePriceTotal)}}</md-table-cell>
                                </md-table-row>

                                <md-table-row>
                                    <md-table-cell><b>Subtotal</b></md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right">-</md-table-cell>
                                    <md-table-cell class="text-right"><b>0.00</b></md-table-cell>
                                    <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterRideDetails)}}</b></md-table-cell>
                                </md-table-row>

                                <!-- Increases and decreases here -->
                                <template v-if="rateData.validOverrides.length > 0">
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Increases and decreases</md-table-cell>
                                        
                                    </md-table-row>
                                    <template  >
                                        <md-table-row v-for="override in rateData.validOverrides" v-bind:key="override.id">
                                            <md-table-cell>{{override.name}}</md-table-cell>
                                            <md-table-cell class="text-right">{{ override.type === 'percentage' ? override.value + '%' : 1}}</md-table-cell>
                                            <md-table-cell class="text-right">{{ override.type === 'percentage' ? override.unitValue : override.value}}</md-table-cell>
                                            <md-table-cell class="text-right">0.00</md-table-cell>
                                            <md-table-cell class="text-right">{{ override.type === 'percentage' ? override.unitValue : override.value}}</md-table-cell>
                                        </md-table-row>
                                    </template>
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>0.00</b></md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterOverrides)}}</b></md-table-cell>
                                    </md-table-row>
                            
                                </template>

                                <!-- Bonus and discounts here -->
                                <template v-if="rateData.validPromoCodes.length > 0">
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Bonus and discounts</md-table-cell>
                                        
                                    </md-table-row>
                                    <template  >
                                        <md-table-row v-for="promoCode in rateData.validPromoCodes" v-bind:key="promoCode.id">
                                            <md-table-cell>{{promoCode.name}}</md-table-cell>
                                            <md-table-cell class="text-right">{{ promoCode.type === 'percentage' ? promoCode.value + '%' : 1}}</md-table-cell>
                                            <md-table-cell class="text-right">{{ promoCode.type === 'percentage' ? promoCode.unitValue : promoCode.value}}</md-table-cell>
                                            <md-table-cell class="text-right">0.00</md-table-cell>
                                            <md-table-cell class="text-right">{{ promoCode.type === 'percentage' ? promoCode.unitValue : promoCode.value}}</md-table-cell>
                                        </md-table-row>
                                    </template>
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>0.00</b></md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterDiscount)}}</b></md-table-cell>
                                    </md-table-row>
                            
                                </template>

                                <!-- Applied on base tax here -->
                                <template v-if="rateData.subtotals.appliedOnBase">
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Applied on base</md-table-cell>
                                    </md-table-row>
                                    <template v-for="tax in rateData.subtotals.onBaseTaxList">
                                        <md-table-row v-if="tax.type === 'percentage' && tax.percentageType === 'base'" v-bind:key="tax.id">
                                            <md-table-cell>{{tax.name}}</md-table-cell>
                                            <md-table-cell class="text-right">{{tax.value}}</md-table-cell>
                                            <md-table-cell class="text-right">{{ roundNumber(parseFloat(tax.value) * rateData.subtotals.afterRideDetails / 100)}}</md-table-cell>
                                            <md-table-cell class="text-right">{{!tax.taxable ? roundNumber(parseFloat(tax.value) * rateData.subtotals.afterRideDetails / 100) : '0.00'}}</md-table-cell>
                                            <md-table-cell class="text-right">{{tax.taxable ? roundNumber(parseFloat(tax.value) * rateData.subtotals.afterRideDetails / 100) : '0.00'}}</md-table-cell>
                                        </md-table-row>
                                    </template>
                                    
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterAppliedOnBaseNoTaxable)}}</b></md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterAppliedOnBaseTaxable)}}</b></md-table-cell>
                                    </md-table-row>

                                </template>

                                <template v-if="(rateData.addons && rateData.addons.length > 0) || (rateData.extras.accessible || rateData.extras.childSeat || rateData.extras.meetAndGreet)">
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Addons and miscellaneous</md-table-cell>
                                    </md-table-row>
                                </template>
                                <template v-if="rateData.addons && rateData.addons.length > 0">
                                    <md-table-row v-for="addon in rateData.addons" v-bind:key="addon.id">
                                        <md-table-cell>{{addon.name}}</md-table-cell>
                                        <md-table-cell class="text-right">{{addon.qty}}</md-table-cell>
                                        <md-table-cell class="text-right">{{addon.price}}</md-table-cell>
                                        <md-table-cell class="text-right">0.00</md-table-cell>
                                        <md-table-cell class="text-right">{{addon.price * addon.qty}}</md-table-cell>
                                    </md-table-row>
                                </template>
                                <md-table-row v-if="rateData.extras.accessible">
                                    <md-table-cell>Accessible</md-table-cell>
                                    <md-table-cell class="text-right">1</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.accessible.price}}</md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.accessible.price}}</md-table-cell>
                                </md-table-row>
                                <md-table-row v-if="rateData.extras.childSeat">
                                    <md-table-cell>Child Seat</md-table-cell>
                                    <md-table-cell class="text-right">1</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.childSeat.price}}</md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.childSeat.price}}</md-table-cell>
                                </md-table-row>
                                <md-table-row v-if="rateData.extras.meetAndGreet">
                                    <md-table-cell>Meet and Greet</md-table-cell>
                                    <md-table-cell class="text-right">1</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.meetAndGreet.price}}</md-table-cell>
                                    <md-table-cell class="text-right">0.00</md-table-cell>
                                    <md-table-cell class="text-right">{{rateData.rates.extras.meetAndGreet.price}}</md-table-cell>
                                </md-table-row>
                                <template v-if="(rateData.addons && rateData.addons.length > 0) || (rateData.extras.accessible || rateData.extras.childSeat || rateData.extras.meetAndGreet)">
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterAppliedOnBaseNoTaxable ) }}</b>   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterAddonsTaxable )}}</b></md-table-cell>
                                    </md-table-row>
                                </template>

                                <template v-if="rateData.subtotals.fixedTaxes > 0">
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Tolls and fees</md-table-cell>
                                    </md-table-row>
                                </template>
                                <template v-if="rateData.subtotals.fixedTaxes > 0">
                                    <template v-for="tax in rateData.taxes">
                                    <md-table-row  v-if="tax.type === 'fixed'" v-bind:key="tax.id">
                                        <md-table-cell>{{tax.name}}</md-table-cell>
                                        <md-table-cell class="text-right">1</md-table-cell>
                                        <md-table-cell class="text-right">{{tax.value}}</md-table-cell>
                                        <md-table-cell class="text-right">{{tax.taxable ? '0.00' : tax.value}}</md-table-cell>
                                        <md-table-cell class="text-right">{{!tax.taxable ? '0.00' : tax.value}}</md-table-cell>
                                    </md-table-row>
                                    </template>
                                </template>
                                <template v-if="rateData.subtotals.fixedTaxes > 0">
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterFixedTaxesNoTaxable) }}</b>   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterFixedTaxesTaxable) }}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                            
                            
                                <template>
                                    <md-table-row>
                                        <md-table-cell colspan="5" class="bg-black">Applied on total tax</md-table-cell>
                                    </md-table-row>
                                    <md-table-row>
                                        <md-table-cell>Sale tax</md-table-cell>
                                        <md-table-cell class="text-right">{{ reservation.companyProfile.saleTax }}%</md-table-cell>
                                        <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.afterSaleTaxUnit) }}</md-table-cell>
                                        <md-table-cell class="text-right">0.00</md-table-cell>
                                        <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.afterSaleTaxUnit) }}</md-table-cell>
                                    </md-table-row>
                                </template>
                                <template >
                                    <md-table-row>
                                        <md-table-cell><b>Subtotal</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.afterSaleTaxNoTaxable) }}</b>   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.afterSaleTaxTaxable)}}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                                <md-table-row>
                                    <md-table-cell>Credit card fee</md-table-cell>
                                    <md-table-cell class="text-right">{{ reservation.companyProfile.creditCardFee }}%</md-table-cell>
                                    <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.creditCardFeeUnitNoTaxable)}} / {{ roundNumber(rateData.subtotals.creditCardFeeUnitTaxable)}}</md-table-cell>
                                    <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.creditCardFeeUnitNoTaxable)}}</md-table-cell>
                                    <md-table-cell class="text-right">{{ roundNumber(rateData.subtotals.creditCardFeeUnitTaxable)}}</md-table-cell>
                                </md-table-row>
                                <template>
                                    <md-table-row class="final-rows uplimo-blue">
                                        <md-table-cell><b>Subtotals</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.subtotals.lastNoTaxable)}}</b>   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.subtotals.lastTaxable)}}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                                <template>
                                    <md-table-row class="final-rows uplimo-blue">
                                        <md-table-cell><b>Total</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{roundNumber(rateData.price)}}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                               <template>
                                    <md-table-row class="final-rows uplimo-green">
                                        <md-table-cell><b>Paid</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ totalTransactionsPaidAmount }}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                                <template>
                                    <md-table-row class="final-rows uplimo-yellow">
                                        <md-table-cell><b>Balance</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{ roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-table-cell>
                                    </md-table-row>
                                </template>
                                <!--template>
                                    <md-table-row class="final-rows uplimo-red">
                                        <md-table-cell><b>Collect Balance</b></md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-</md-table-cell>
                                        <md-table-cell class="text-right">-   
                                        </md-table-cell>
                                        <md-table-cell class="text-right"><b>{{  roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-table-cell>
                                    </md-table-row>
                                </template-->
                            </md-table>
                            <md-button class="md-primary md-raised full-width-button" @click="openPaymentDialog()" :disabled="roundNumber(rateData.price - totalTransactionsPaidAmount) == 0">Collect balance <b>{{  roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-button>
                        </md-card-content>
                    </md-card>             
                </md-tab>
                <md-tab id="passenger" md-label="Passenger" :md-template-data="{ badge: tabs.passenger.errors }" >
                    <CRow>
                    
                        <CCol sm="12" md="8" >
                            <md-card class="uplimo-card">
                                <md-card-header>
                                    <div class="md-title">Passengers</div>
                                </md-card-header>
                                <md-card-content>
                                    <md-table v-model="reservation.passengers" v-if="reservation.passengers.length > 0">
                                        <md-table-row slot="md-table-row" slot-scope="{ item , index }">
                                            <md-table-cell md-label="Name">{{item.firstName}}
                                                <md-chip class="md-primary ml-2" v-if="!item.id">New</md-chip>
                                            </md-table-cell>
                                            <md-table-cell md-label="Lastname">{{item.lastName}}</md-table-cell>
                                            <md-table-cell md-label="Email">{{item.email}}</md-table-cell>
                                            <md-table-cell md-label="Phone">{{item.phone}}</md-table-cell>
                                            <md-table-cell md-label="Actions">
                                                <md-button class="md-fab md-mini ml-0" @click="removeAddedPassenger(item, index)">
                                                    <md-icon>delete</md-icon>
                                                </md-button>
                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                    <md-empty-state
                                            v-else
                                            md-icon="person_off"
                                            :md-label="`No passengers added yet`"
                                            >
                                            <div :class="getValidationClass('passengers')" class="md-field md-theme-default no-line min-height-initial">
                                                <div class="md-error md-subheading mt-2 text-center right-0" v-if="!$v.reservation.passengers.required">Add at least one passenger</div>
                                            </div>
                                        </md-empty-state>
                                    <md-button class="md-raised md-primary full-width-button" @click="firePassengerDialog()" v-if="availableClientPassengers > 0 || availableClientPassengers == null">Add passenger</md-button>
                                    <md-button class="md-raised md-primary full-width-button" v-if="reservation.client" @click="fireNonRegisteredPass()">Add non registered passenger</md-button>
                                    
                                </md-card-content>
                            </md-card>
                        </CCol>
                        <CCol sm="12" md="4" >
                            <md-card class="uplimo-card" v-if="reservation.client">
                                <md-card-header>
                                    <div class="md-title">Client</div>
                                </md-card-header>
                                <md-card-content>
                                    <div class="md-caption">Name</div>
                                    <div class="md-subheading mb-3">
                                        {{ reservation.client.type === 'corporate' ? reservation.client.name : '' }}
                                        {{ reservation.client.type === 'individual' ? `${reservation.client.firstName} ${reservation.client.lastName}` : '' }}
                                        {{ reservation.client.type === 'corporate' && !reservation.client.name ? 'Not provided' : '' }}
                                        {{ reservation.client.type === 'individual' && !reservation.client.firstName && !reservation.client.lastName ? 'Not provided' : '' }}
                                    </div>
                                    <div class="md-caption">Phone</div>
                                    <div class="md-subheading mb-3">
                                        {{ reservation.client.phone ? reservation.client.phone  : 'Not provided' }}
                                    </div>
                                    <div class="md-caption">Email</div>
                                    <div class="md-subheading mb-3">
                                        {{ reservation.client.email }}
                                    </div>
                                    <div class="md-caption">Type</div>
                                    <div class="md-subheading mb-3">
                                        {{ reservation.client.type }}
                                    </div>
                                    <md-button class="md-raised md-accent full-width-button" @click="removeSelectedClient()">Remove selected client</md-button>
                                    <div class="md-caption">Selected passengers will also be removed</div> 
                                </md-card-content>
                            </md-card>
                        </CCol>
                    </CRow>
                </md-tab>
                <md-tab id="payment-method" md-label="Payment" :md-template-data="{ badge: tabs.paymentMethod.errors }" v-if="user.data.activeRole != 'driver'">
                    
                   
                    <CRow>
                        <CCol sm="12" md="12" lg="8" >
                             <md-card class="uplimo-card" v-if="reservation.transactions">
                                <md-card-header>
                                    <div class="md-title">Payment history</div>
                                    <div class="mt-4">Payment Status: 
                                        
                                       <span class="bubble ml-2 paid" v-if="getCompleteStatus(reservation.paymentStatus).name === 'Paid'" >{{ getCompleteStatus(reservation.paymentStatus).name }}</span>
                                       <span class="bubble ml-2 unpaid" v-if="getCompleteStatus(reservation.paymentStatus).name === 'Unpaid'" >{{ getCompleteStatus(reservation.paymentStatus).name }}</span>
                                       <span class="bubble ml-2 partially-paid" v-if="getCompleteStatus(reservation.paymentStatus).name === 'Partially paid'" >{{ getCompleteStatus(reservation.paymentStatus).name }}</span>
                                        
                                       
                                    
                                    </div>
                                     <!--md-field v-show="payment.paymentMethod === 'cash' || payment.paymentMethod === 'check'">
                        <label>Payment status</label>
                        <md-select v-model="reservation.paymentStatus" name="payment_status" id="payment_status" placeholder="Payment status">
                            <md-option v-for="status in paymentStatuses" :value="status.id" v-bind:key="status.id">{{status.name}}</md-option>
                        </md-select>
                    </md-field-->
                                </md-card-header>

                                <md-card-content>
                                    <md-empty-state
                                        v-if="reservation.transactions.length == 0"
                                        class="md-primary"
                                        md-icon="receipt"
                                        md-label="No payments were made"
                                        md-description="Select your payment method and submit payment">
                                    </md-empty-state>
                                    <md-table
                                        v-if="reservation.transactions.length > 0"
                                    >
                                        <md-table-row>
                                            <md-table-head>Id</md-table-head>
                                            <md-table-head>Datetime</md-table-head>
                                            <md-table-head>Method</md-table-head>
                                            <md-table-head>Amount</md-table-head>
                                            <md-table-head>Status</md-table-head>
                                            <md-table-head>Creator</md-table-head>
                                            <md-table-head>Action</md-table-head>
                                        </md-table-row>
                                        <md-table-row v-for="(transaction, index) in reservation.transactions" v-bind:key="index + '-transaction'">
                                            <md-table-cell>TR-{{index + 1}}</md-table-cell>
                                            <md-table-cell>{{ transaction.datetime | moment("LLLL") }} </md-table-cell>
                                            <md-table-cell>{{transaction.type}}</md-table-cell>
                                            <md-table-cell class="text-right"><span class="md-subheading">{{transaction.amount}}</span></md-table-cell>
                                            <md-table-cell>
                                                
                                                <md-chip class="mini uplimo-green" v-if="transaction.status == 'SUCCESS' && transaction.action == 'collect'">Collected</md-chip>
                                                <md-chip class="mini uplimo-red" v-if="transaction.status == 'SUCCESS'  && !transaction.partial && transaction.action == 'refund'">Refunded</md-chip>
                                                <md-chip class="mini uplimo-red" v-if="transaction.status == 'SUCCESS' && transaction.refunded">Refunded</md-chip>
                                                <md-chip class="mini uplimo-yellow" v-if="transaction.status == 'SUCCESS' && transaction.partial && transaction.action == 'refund'">Partial refund</md-chip>
                                                <md-chip class="mini  uplimo-yellow" v-if="transaction.status == 'SUCCESS' && transaction.partiallyRefunded">Partially refunded</md-chip>
                                               
                                                
                                            </md-table-cell>
                                            <md-table-cell>{{transaction.creator.email}}</md-table-cell>
                                            <md-table-cell>
                                                <!--md-button class="md-raised full-width-button" v-if="transaction.type === 'cash'" @click="refundPayment(transaction.type, 'TR-' + (index + 1))">Delete</md-button-->
                                                <md-button class="md-raised md-primary full-width-button" v-if="transaction.action === 'collect' && !transaction.refunded" @click="openRefundDialog(transaction,'TR-' + (index + 1))">Refund</md-button>
                                                <md-button class="full-width-button md-accent" :disabled="true" v-if="transaction.action === 'refund'">No possible action</md-button>

                                            </md-table-cell>
                                        </md-table-row>
                                    </md-table>
                                </md-card-content>
                            </md-card>
                            
                        </CCol>
                        <CCol sm="12" md="12" lg="4" >
                            <md-card class="uplimo-card" v-if="rateData">
                                <md-card-header>
                                    <div class="md-title">Rate resume</div>
                                </md-card-header>

                                <md-card-content>
                                    <md-table>
                                        <template>
                                            <md-table-row class="final-rows uplimo-blue">
                                                <md-table-cell><b>Total</b></md-table-cell>
                                               
                                                <md-table-cell class="text-right"><b>{{roundNumber(rateData.price)}}</b></md-table-cell>
                                            </md-table-row>
                                        </template>
                                         <template>
                                            <md-table-row class="final-rows uplimo-green">
                                                <md-table-cell><b>Paid</b></md-table-cell>
                                               
                                                <md-table-cell class="text-right"><b>{{ roundNumber(totalTransactionsPaidAmount) }}</b></md-table-cell>
                                            </md-table-row>
                                        </template>
                                        <template>
                                            <md-table-row class="final-rows uplimo-yellow">
                                                <md-table-cell><b>Balance</b></md-table-cell>
                                                
                                                <md-table-cell class="text-right"><b>{{ roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-table-cell>
                                            </md-table-row>
                                        </template>
                                        <!--template>
                                            <md-table-row class="final-rows uplimo-red">
                                                <md-table-cell><b>Collect Balance</b></md-table-cell>
                                                <md-table-cell class="text-right">-</md-table-cell>
                                                <md-table-cell class="text-right">-</md-table-cell>
                                                <md-table-cell class="text-right">-   
                                                </md-table-cell>
                                                <md-table-cell class="text-right"><b>{{  roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-table-cell>
                                            </md-table-row>
                                        </template-->
                                    </md-table>
                                     <md-button class="md-primary md-raised full-width-button" @click="openPaymentDialog()" :disabled="roundNumber(rateData.price - totalTransactionsPaidAmount) == 0">Collect balance <b>{{  roundNumber(rateData.price - totalTransactionsPaidAmount) }}</b></md-button>

                                </md-card-content>
                            </md-card>
                        </CCol>
                        
                    </CRow>
                </md-tab>
                <md-tab id="emails" md-label="Emails" :md-template-data="{ badge: tabs.emails.errors }" v-if="user.data.activeRole != 'driver'">
                    <md-toolbar>
                        <div class="md-toolbar-row">
                            <h3 class="md-title flex-1">Email notifications actions</h3>
                            <!--md-button class="md-raised">Start notification scheduling</md-button-->
                        </div>
                    </md-toolbar >
                    <md-toolbar md-elevation="0" class="mb-3">
                        <md-button class="md-raised" @click="openMailingModal('Confirmation')">Confirmation</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Receipt')">Receipt</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Cancellation')">Cancellation</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Refund')">Refund</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Driver assignment request')">Driver assignment request</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Driver assignation')">Driver assignation</md-button>
                        <md-button class="md-raised" @click="openMailingModal('Quotation')">Quotation</md-button>
                    </md-toolbar>
                    
                    <md-empty-state
                        class="mt-4 md-primary"
                        md-rounded
                        md-icon="schedule_send"
                        md-label="Email log"
                        md-description="All sent emails related to this reservation will be displayed here."
                        v-if="reservation.mails.length == 0"
                    >
                    </md-empty-state>
                    <md-table md-card v-else>
                        <md-table-toolbar>
                            <h2 class="md-subheading">Sent notifications</h2>
                        </md-table-toolbar>
                        <md-table-row>
                            
                            <md-table-head>Mail type</md-table-head>
                            <md-table-head>Recipient type</md-table-head>
                            <md-table-head>To</md-table-head>
                            <md-table-head>Subject</md-table-head>
                            <md-table-head>Status</md-table-head>
                            <md-table-head >Date</md-table-head>
                        </md-table-row>

                        <md-table-row v-for="(mail,index) in reservation.mails" v-bind:key="'mail' + index">
                            
                            <md-table-cell class="text-capitalize">{{mail.mailType}}</md-table-cell>
                            <md-table-cell class="text-capitalize">{{mail.recipientType}}</md-table-cell>
                            <md-table-cell>{{mail.to}}</md-table-cell>
                            <md-table-cell>{{mail.message.subject}}</md-table-cell>
                            <md-table-cell>{{mail.delivery.state}}</md-table-cell>
                            <md-table-cell>{{ mail.delivery.endTime.seconds | moment("LLLL (HH:mm [HS])") }}</md-table-cell>
                            
                        </md-table-row>
                    </md-table>
                </md-tab>
                <md-tab id="sms" md-label="SMS" :md-template-data="{ badge: tabs.sms.errors }" v-if="user.data.activeRole != 'driver'">
                    <md-toolbar>
                        <div class="md-toolbar-row">
                            <h3 class="md-title flex-1">SMS notifications actions</h3>
                            <!--md-button class="md-raised">Start notification scheduling</md-button-->
                        </div>
                    </md-toolbar >
                    <md-toolbar md-elevation="0" class="mb-3">
                        <md-button class="md-raised" @click="openSMSModal('Confirmation')">Confirmation</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Receipt')">Receipt</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Cancellation')">Cancellation</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Refund')">Refund</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Driver assignment request')">Driver assignment request</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Driver assignation')">Driver assignation</md-button>
                        <md-button class="md-raised" @click="openSMSModal('Quotation')">Quotation</md-button>
                    </md-toolbar>
                    
                    <md-empty-state
                        class="mt-4 md-primary"
                        md-rounded
                        md-icon="schedule_send"
                        md-label="SMS log"
                        md-description="All sent SMS related to this reservation will be displayed here."
                        v-if="reservation.sms.length == 0"
                    >
                    </md-empty-state>
                    <md-table md-card v-else>
                        <md-table-toolbar>
                            <h2 class="md-subheading">Sent notifications</h2>
                        </md-table-toolbar>
                        <md-table-row>
                            
                            <md-table-head>Sms type</md-table-head>
                            <md-table-head>Recipient type</md-table-head>
                            <md-table-head>To</md-table-head>
                            <md-table-head>Text</md-table-head>
                            <md-table-head>Status</md-table-head>
                            <md-table-head >Date</md-table-head>
                        </md-table-row>

                        <md-table-row v-for="(sms,index) in reservation.sms" v-bind:key="'mail' + index">
                            
                            <md-table-cell class="text-capitalize">{{sms.smsType}}</md-table-cell>
                            <md-table-cell class="text-capitalize">{{sms.recipientType}}</md-table-cell>
                            <md-table-cell>{{sms.to}}</md-table-cell>
                            <md-table-cell>{{sms.text}}</md-table-cell>
                            <md-table-cell>{{sms.delivery.state}}</md-table-cell>
                            <md-table-cell>{{ sms.delivery.endTime | moment("LLLL (HH:mm [HS])") }}</md-table-cell>
                            
                        </md-table-row>
                    </md-table>
                </md-tab>
                <md-tab id="company" md-label="Company" v-if="user.data.activeRole == 'driver'">
                    <CRow v-if="reservation.companyProfile">
                        <CCol sm="12" md="12" lg="8" >
                             <md-card class="uplimo-card">
                                <md-card-header>
                                    <div class="md-title">Company details</div>
                                </md-card-header>

                                <md-card-content>
                                    <div class="md-layout-item flex-1">
                                        {{ reservation.companyProfile.name }} 
                                        <ul class="pl-0">
                                        <li v-for="phone in reservation.companyProfile.phones" v-bind:key="phone + reservation.companyProfile.id">
                                            {{ phone }}
                                        </li>
                                        </ul>
                                    </div>
                                </md-card-content>

                             </md-card>
                        </CCol>
                    </CRow>
                </md-tab>
            </md-tabs>
            <transition name="fade">
                <div class="mobile-backdrop-overlay" v-show="showSelector && dialogWidth < 992" @click="showSelector = false"></div>
            </transition>
            <transition name="fade">
                <md-toolbar class="md-dense bottom-fixed extra-case md-elevation-5" v-show="showSelector && dialogWidth < 992" :style="[dialogDimensions]">
                    <div class="md-toolbar-row">
                        <md-list>
                            <md-list-item 
                                :class="{ 'active-item-tab' : activeTabIndex == index }" 
                                v-for="(step,index) in steps" 
                                v-bind:key="'selector-mobile' + step.id" 
                                @click="changeMobileTab(step.id, index)">
                                
                                <span class="md-list-item-text">{{ step.label }}</span>
                                
                                <md-badge class="md-accent" :md-content="tabs[step.tabErrorLabel].errors" v-if="tabs[step.tabErrorLabel].errors" />
                                <md-badge class="confirmed" md-content="OK" v-if="tabs[step.tabErrorLabel].errors == 0" />
                            </md-list-item>
                        </md-list>
                    </div>
                </md-toolbar>
            </transition>
            <transition name="fade">
                <md-toolbar class="md-dense bottom-fixed"  :style="[dialogDimensions]">
                    
                    <div class="md-toolbar-row" v-show="dialogWidth < 992">
                        <h3 class="md-title flex-1">{{ activeTabLabel }} ({{ activeTabIndex + 1 }} of {{this.steps.length}})</h3>
                        <md-button class="md-icon-button bg-secondary md-raised" @click="prevTab()" :disabled="activeTabIndex == 0">
                            <md-icon>arrow_back_ios</md-icon>
                        </md-button>
                        <md-button class="md-icon-button bg-secondary md-raised" @click="nextTab()" :disabled="activeTabIndex + 1 == this.steps.length">
                            <md-icon>arrow_forward_ios</md-icon>
                        </md-button>
                        <md-button class="md-icon-button " @click="showSelector = !showSelector" >
                            <md-icon>more_vert</md-icon>
                        </md-button>
                    </div>
                    <div class="md-toolbar-row" v-show="dialogWidth >= 992">
                        <h3 class="md-title flex-1">{{ activeTabLabel }} ({{ activeTabIndex + 1 }} of {{this.steps.length}})</h3>
                        <md-button class="bg-secondary md-raised" @click="prevTab()" :disabled="activeTabIndex == 0">
                            Prev
                        </md-button>
                        <md-button class="bg-secondary md-raised" @click="nextTab()" :disabled="activeTabIndex + 1 == this.steps.length">
                            Next
                        </md-button>
                        
                    </div>
                </md-toolbar>
            </transition>
            
            <md-dialog
                :style="[dialogDimensions]"
                :md-active.sync="confirmDialog"
            >
                <md-dialog-title>What would you like to do ?</md-dialog-title>
                <md-dialog-content>
                    <p>You made changes in this reservation. Choose your action </p>
                    <md-button class="full-width-button md-raised mb-2" @click="onCancel">Continue editing</md-button>
                    <md-button class="full-width-button md-raised md-accent mb-2" @click="onConfirm">Exit without saving</md-button>
                    <md-button class="md-primary full-width-button md-raised" @click="saveAndExit">Save &amp; exit</md-button>
                </md-dialog-content>

                
                
            </md-dialog>
            <md-dialog :md-active.sync="showDialog" :style="[dialogDimensions]" v-if="reservation" :md-fullscreen="true">
                <md-dialog-title class="md-body-1">{{mailingInfo.title}} <div class="md-caption">Mail config</div></md-dialog-title>
                <!-- Content -->
                <md-divider></md-divider>
                <div class="up-modal-content">
                    <md-list >
                        <md-subheader>Recipients</md-subheader>
                        <md-list-item v-if="mailingInfo.recipients.passenger.active">
                            <md-checkbox v-model="mailingInfo.recipients.passenger.send"  />
                            <div class="md-list-item-text">
                                <span>{{reservation.passenger.email ? reservation.passenger.email : 'Passenger email'}}</span>
                                <span class="md-caption">Passenger</span>
                            </div>
                            
                        </md-list-item>
                        <md-list-item v-if="mailingInfo.recipients.owner.active">
                            <md-checkbox v-model="mailingInfo.recipients.owner.send" />
                            <div class="md-list-item-text">
                                <span>{{reservation.companyProfile ? reservation.companyProfile.mailList : 'Owner email'}}</span>
                                <span class="md-caption">Owner</span>
                            </div>
                        </md-list-item>
                        <md-list-item v-if="mailingInfo.recipients.driver.active">
                            <md-checkbox v-model="mailingInfo.recipients.driver.send" />
                            <div class="md-list-item-text">
                                <span>{{reservation.assignedDriver ? reservation.assignedDriver : 'Driver email'}}</span>
                                <span class="md-caption">Driver</span>
                            </div>
                        </md-list-item>
                        <md-list-item v-if="mailingInfo.recipients.user.active">
                            <md-checkbox v-model="mailingInfo.recipients.user.send" />
                            <div class="md-list-item-text">
                                <span>{{user.data ? user.data.email : ''}} <b> (me)</b></span>
                                <span class="md-caption">User</span>
                            </div>
                        </md-list-item>
                    </md-list>
                    
                    
                </div>   
                <md-divider></md-divider>
                <div class="up-modal-content">
                    <md-field>
                        <label>Mail Subject</label>
                        <md-input v-model="mailingInfo.subject"></md-input>
                    </md-field>
                </div>
                <md-divider class="mb-3"></md-divider>
                <div class="up-modal-content">
                    <md-field>
                        <label>Mail body</label>
                        <md-textarea v-model="mailingInfo.body"></md-textarea>
                    </md-field>
                </div>
                <md-dialog-actions>
                    <md-button class="md-primary" @click="showDialog = false; isBusy = false">Close</md-button>
                    <md-button class="md-primary" @click="fireNotifications">Send</md-button>
                </md-dialog-actions>
            </md-dialog>
            <md-dialog :md-active.sync="showSMSDialog" :style="[dialogDimensions]" v-if="reservation" :md-fullscreen="true">
                <md-dialog-title class="md-body-1">{{smsInfo.title}} <div class="md-caption">SMS config</div></md-dialog-title>
                <!-- Content -->
                <md-divider></md-divider>
                <div class="up-modal-content">
                    <md-list >
                        <md-subheader>Recipients</md-subheader>
                        <md-list-item v-if="smsInfo.recipients.passenger.active">
                            <md-checkbox v-model="smsInfo.recipients.passenger.send"  />
                            <div class="md-list-item-text">
                                <span>{{reservation.passenger.phone ? reservation.passenger.phone : 'Passenger phone'}}</span>
                                <span class="md-caption">Passenger</span>
                            </div>
                            
                        </md-list-item>
                        <md-list-item v-if="smsInfo.recipients.owner.active">
                            <md-checkbox v-model="smsInfo.recipients.owner.send" />
                            <div class="md-list-item-text">
                                <span>{{reservation.companyProfile ? reservation.companyProfile.smsPhoneTarget : 'Owner email'}}</span>
                                <span class="md-caption">Owner</span>
                            </div>
                        </md-list-item>
                        <md-list-item v-if="smsInfo.recipients.driver.active">
                            <md-checkbox v-model="smsInfo.recipients.driver.send" />
                            <div class="md-list-item-text">
                                <span>{{reservation.assignedDriver ? reservation.assignedDriver : 'Driver email'}}</span>
                                <span class="md-caption">Driver</span>
                            </div>
                        </md-list-item>
                       
                    </md-list>
                    
                    
                </div>   
                <md-divider></md-divider>
                <div class="up-modal-content">
                    <md-field>
                        <label>SMS text</label>
                        <md-input v-model="smsInfo.text"></md-input>
                    </md-field>
                </div>
                <md-divider class="mb-3"></md-divider>
               
                <md-dialog-actions>
                    <md-button class="md-primary" @click="showSMSDialog = false; isBusy = false">Close</md-button>
                    <md-button class="md-primary" @click="fireSMSNotifications">Send</md-button>
                </md-dialog-actions>
            </md-dialog>
            <md-dialog :md-active.sync="transactionDialog" :style="[dialogDimensions]" v-if="reservation" :md-fullscreen="false" class="free-dialog">
               
                <div v-if="submitingPayment">
                    <div class="up-modal-content md-alignment-center-center mt-4 mb-4 text-center">
                        <p class="md-title">Submitting Payment</p>
                        <md-progress-spinner class="md-accent mt-3" md-mode="indeterminate"></md-progress-spinner>
                    </div>
                </div>
                   
                   
                <md-dialog-title class="md-body-1" v-if="!submitingPayment">{{smsInfo.title}} <div class="md-caption">Payment method</div></md-dialog-title>
                <!-- Content -->
                <md-divider v-if="!submitingPayment"></md-divider>
                <div class="up-modal-content">
                
                    <div class="amount-to-pay" v-show="reservation.price">
                        <div class="title">Amount to pay</div>
                        <div class="amount">$ {{reservation.price}}</div>
                    </div>
                    
                    <!--md-field v-show="payment.paymentMethod === 'cash' || payment.paymentMethod === 'check'">
                        <label>Payment status</label>
                        <md-select v-model="reservation.paymentStatus" name="payment_status" id="payment_status" placeholder="Payment status">
                            <md-option v-for="status in paymentStatuses" :value="status.id" v-bind:key="status.id">{{status.name}}</md-option>
                        </md-select>
                    </md-field-->
                    
                    <div class="row">
                        <div class="col-sm-3">
                            <md-radio v-model="payment.paymentMethod" value="credit-card" @change="paymentMethodChanged">Credit card</md-radio>
                        </div>
                        <div class="col-sm-3">
                            <md-radio v-model="payment.paymentMethod" value="cash" @change="paymentMethodChanged">Cash</md-radio>
                        </div>
                        <div class="col-sm-3">
                            <md-radio v-model="payment.paymentMethod" value="check" @change="paymentMethodChanged">Check</md-radio>
                        </div>
                        <div class="col-sm-3">
                            <md-radio v-model="payment.paymentMethod" value="invoice" @change="paymentMethodChanged">Invoice</md-radio>
                        </div>
                    </div>
                    
                    
                    
                    <md-field v-if="payment.paymentMethod == 'credit-card'">
                    
                        <label>Merchant accounts</label>
                        <md-select v-model="payment.merchantAccount" name="merchant_account" id="merchant_account" placeholder="Merchant account">
                            <md-option v-for="merchant in merchantAccounts" :value="merchant.id" v-bind:key="merchant.id">{{merchant.name}}</md-option>
                        </md-select>
                    </md-field>
                    <md-tabs id="payment-method-inner" ref="tabs"  :md-active-tab="paymentMethodActiveTab" :md-dynamic-height="true" @md-changed="tabChanged">
                        <md-tab id="cash" md-label="cash">
                            <md-field :class="getValidationClass('paidAmount')">
                                <md-icon>account_balance_wallet</md-icon>
                                <label>Amount to collect</label>
                                <md-input 
                                        min="10"
                                        type="number"
                                        v-model="payment.cash.paidAmount"   
                                ></md-input>
                                <span class="md-error" v-if="!$v.payment.cash.paidAmount.required">Amount to collect is required</span>
                                <span class="md-error" v-if="!$v.payment.cash.paidAmount.minValue">Min transaction value is 1</span>
                            </md-field>
                        </md-tab>
                        <md-tab id="credit-card" md-label="Credit card">

                            <div >
                                <div v-if="payment.gateway == 'US Bank Account'">
                                    <div id="card-form">
                                        <div class="textfield--float-label">
                                            <!-- Begin hosted fields section -->
                                            <label class="hosted-field--label" for="number"><span class="icon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M0 0h24v24H0z" fill="none"/><path d="M20 4H4c-1.11 0-1.99.89-1.99 2L2 18c0 1.11.89 2 2 2h16c1.11 0 2-.89 2-2V6c0-1.11-.89-2-2-2zm0 14H4v-6h16v6zm0-10H4V6h16v2z"/></svg></span> Card Number 
                                            </label>
                                            <div id="number" class="hosted-field"></div>
                                            <!-- End hosted fields section -->
                                        </div>
                                        <div class="textfield--float-label">
                                            <!-- Begin hosted fields section -->
                                            <label class="hosted-field--label" for="cardHolder">
                                            <md-icon class="icon">person_outline</md-icon>
                                                Card holder 
                                            </label>
                                            <div id="cardholder" class="hosted-field"></div>
                                            <!-- End hosted fields section -->
                                        </div>
                                        <div class="row">
                                            <div class="col-sm-4">
                                    
                                                <div class="textfield--float-label">
                                                    <!-- Begin hosted fields section -->
                                                    <label class="hosted-field--label" for="expiration-date">
                                                    <span class="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M9 11H7v2h2v-2zm4 0h-2v2h2v-2zm4 0h-2v2h2v-2zm2-7h-1V2h-2v2H8V2H6v2H5c-1.11 0-1.99.9-1.99 2L3 20c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 16H5V9h14v11z"/></svg>
                                                    </span>
                                                    Expiration Date</label>
                                                    <div id="expiration-date" class="hosted-field"></div>
                                                    <!-- End hosted fields section -->
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="textfield--float-label">
                                                    <!-- Begin hosted fields section -->
                                                    <label class="hosted-field--label" for="cvv">
                                                    <span class="icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24"><path d="M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z"/></svg>
                                                        </span>
                                                        CVV</label>
                                                    <div id="cvv" class="hosted-field"></div>
                                                    <!-- End hosted fields section -->
                                                </div>
                                            </div>
                                            <div class="col-sm-4">
                                                <div class="textfield--float-label">
                                                    <!-- Begin hosted fields section -->
                                                    <label class="hosted-field--label" for="postal-code">
                                                    <span class="icon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24">
                                                <path d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"/></svg>
                                                    </span>
                                                    Postal Code</label>
                                                    <div id="postal" class="hosted-field"></div>
                                                    <!-- End hosted fields section -->
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                                <div v-else-if="payment.gateway == 'Paypal PayFlow Pro'">
                                    <vue-paycard 
                                        :value-fields="payment.cardFields" 
                                        :input-fields="inputFields" 
                                        :is-card-number-masked="false" 
                                        class="mb-5"/>
                                    <md-field :class="getValidationClass('cardNumber')" :md-counter="false" style="margin-top:40px">
                                        <md-icon>credit_card</md-icon>
                                        <label>Credit card number</label>
                                        <md-input  type="tel"
                                                :value="payment.cardFields.cardNumber"
                                                @keyup="changeNumber"
                                                data-card-field
                                                autocomplete="off"
                                                :id="inputFields.cardNumber"
                                                :maxlength="cardNumberMaxLength"
                                                ref="cardNumber"
                                                
                                                
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.cardFields.cardNumber.required">Card number is required</span>
                                    </md-field>

                                    <md-field :class="getValidationClass('cardName')">
                                        <md-icon>person_outline</md-icon>
                                        <label>Name on card</label>
                                        <md-input  type="text"
                                                :id="inputFields.cardName"
                                                    title="Name on card"
                                                    v-letter-only
                                                    :value="payment.cardFields.cardName"
                                                    @keyup="changeName"
                                                    data-card-field
                                                    autocomplete="off"
                                                    ref="cardName"
                                                
                                                
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.cardFields.cardName.required">Card name is required</span>
                                    </md-field>
                                    <div class="row">
                                        <div class="col-sm-4">
                                            <md-field class="expiration-month" :class="[getValidationClass('cardMonth'),getValidationClass('cardYear')]">
                                                <md-icon>event</md-icon>
                                                <label>Expiration date</label>
                                                <md-select md-dense 
                                                            :id="inputFields.cardMonth"
                                                            aria-label="Card Month"
                                                            title="Month"
                                                            v-model="payment.cardFields.cardMonth"
                                                            data-card-field
                                                            ref="cardMonth"
                                                            
                                                >
                                                    <md-option value disabled selected>Month</md-option>
                                                    <md-option
                                                        v-bind:value="n < 10 ? '0' + n : n"
                                                        v-for="n in 12"
                                                        v-bind:disabled="n < minCardMonth"
                                                        v-bind:key="n"
                                                    >{{generateMonthValue(n)}}</md-option>
                                                    
                                                </md-select>
                                                <md-select md-dense 
                                                            :id="inputFields.cardYear"
                                                            aria-label="Card year"
                                                            title="Year"
                                                            v-model="payment.cardFields.cardYear"
                                                            data-card-field
                                                            ref="cardYear"
                                                            
                                                >
                                                    <md-option value disabled selected>Year</md-option>
                                                    <md-option
                                                        v-bind:value="$index + minCardYear"
                                                        v-for="(n, $index) in 12"
                                                        v-bind:key="n"
                                                    >{{$index + minCardYear}}</md-option>
                                                    
                                                </md-select>
                                                <span class="md-error" v-if="!$v.payment.cardFields.cardMonth.required || !$v.payment.cardFields.cardYear.required">Card expiration is required</span>
                                                
                                            </md-field>
                                        </div>
                                        <div class="col-sm-4">
                                            <md-field :md-counter="false" :class="getValidationClass('cardCvv')">
                                                <md-icon>https</md-icon>
                                                <label>CVV</label>
                                                <md-input 
                                                            type="tel"
                                                            title="CVV"
                                                            v-number-only
                                                            :id="inputFields.cardCvv"
                                                            maxlength="4"
                                                            :value="payment.cardFields.cardCvv"
                                                            @keyup="changeCvv"
                                                            data-card-field
                                                            autocomplete="off"
                                                            ref="cardCvv"
                                                        
                                                        
                                                ></md-input>
                                                <span class="md-error" v-if="!$v.payment.cardFields.cardCvv.required">Card CVV is required</span>
                                            </md-field>
                                        </div>
                                        <div class="col-sm-4">
                                            <md-field :class="getValidationClass('billZip')">
                                                <md-icon>home</md-icon>
                                                <label>Bill Zip code</label>
                                                <md-input 
                                                        type="tel"
                                                        title="Zip code"
                                                        v-number-only
                                                        v-model="payment.cardFields.billZip"   
                                                ></md-input>
                                                <span class="md-error" v-if="!$v.payment.cardFields.billZip.required">Billing ZIP is required</span>
                                            </md-field>
                                        </div>
                                    </div>
                                    <md-field :class="getValidationClass('billingAddress')">
                                        <md-icon>map</md-icon>
                                        <label>Billing address</label>
                                        <md-input 
                                                title="Billing address"
                                                v-model="payment.cardFields.billingAddress"
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.cardFields.billingAddress.required">Billing address is required</span>
                                    </md-field>
                                </div>
                                <div v-else-if="payment.gateway == 'Stripe'">
                                    <form id="payment-form">
                                        <div id="payment-element">
                                            <!-- Elements will create form elements here -->
                                        </div>
                                        <!--button id="submit">Submit</button-->
                                        <div id="error-message">
                                            <!-- Display error message to your customers here -->
                                        </div>
                                    </form>
                                </div>
                            </div>





                            
                        </md-tab>
                        <!-- Check    -Company Name, -Address, -Routing #, -Account # -->
                        <md-tab id="check" md-label="check">
                            <div class="row">
                                <div class="col-sm-12">
                                    <md-field :class="getValidationClass('check.companyName')">
                                        <md-icon>business</md-icon>
                                        <label>Company name</label>
                                        <md-input 
                                                v-model="payment.check.companyName"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.check.companyName.required">Company name is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('check.address')">
                                        <md-icon>room</md-icon>
                                        <label>Address</label>
                                        <md-input 
                                                v-model="payment.check.address"   
                                        ></md-input>
                                    <span class="md-error" v-if="!$v.payment.check.address.required">Address is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('check.routing')">
                                        <md-icon>account_balance</md-icon>
                                        <label>Routing #</label>
                                        <md-input 
                                                v-model="payment.check.routing"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.check.routing.required">Routing # is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('check.account')">
                                        <md-icon >account_balance</md-icon>
                                        <label>Account #</label>
                                        <md-input 
                                                v-model="payment.check.account"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.check.account.required">Account # is required</span>
                                    </md-field>

                                </div>
                            </div>
                        
                        </md-tab>
                        <md-tab id="invoice" md-label="invoice">
                            <div class="row">
                                <div class="col-sm-12">
                                    <md-field :class="getValidationClass('invoice.companyName')">
                                        <md-icon>business</md-icon>
                                        <label>Company name</label>
                                        <md-input 
                                                v-model="payment.invoice.companyName"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.invoice.companyName.required">Company name is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('invoice.address')">
                                        <md-icon>room</md-icon>
                                        <label>Address</label>
                                        <md-input 
                                                v-model="payment.invoice.address"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.invoice.address.required">Address is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('invoice.ein')">
                                        <md-icon>account_balance</md-icon>
                                        <label>EIN #</label>
                                        <md-input 
                                                v-model="payment.invoice.ein"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.invoice.ein.required">EIN is required</span>
                                    </md-field>
                                    <md-field :class="getValidationClass('invoice.email')">
                                        <md-icon>mail</md-icon>
                                        <label>Email</label>
                                        <md-input 
                                                v-model="payment.invoice.email"   
                                        ></md-input>
                                        <span class="md-error" v-if="!$v.payment.invoice.email.required">Email is required</span>
                                    </md-field>

                                </div>
                            </div>
                        </md-tab>
                    </md-tabs>
                   
                    
                </div>    
                    
                <md-dialog-actions class="fixed-bottom-dialog" v-if="!submitingPayment">
                    <md-button class="md-primary md-raised full-width-button" @click="submitPayment">Submit payment</md-button>
                    <md-button class="md-raised full-width-button" @click="transactionDialog = false">Close</md-button>
                </md-dialog-actions> 
                
               
            </md-dialog>
            <md-dialog :md-active.sync="refundDialog" :style="[dialogDimensions]" v-if="reservation" :md-fullscreen="false" class="free-dialog">
                 <div v-if="submitingRefund">
                    <div class="up-modal-content md-alignment-center-center mt-4 mb-4 text-center">
                        <p class="md-title">Submitting refund</p>
                        <md-progress-spinner class="md-accent mt-3" md-mode="indeterminate"></md-progress-spinner>
                    </div>
                </div>
                <md-dialog-title class="md-title" v-if="!submitingRefund"><b>{{refund.actualId}}</b> Refund details</md-dialog-title>
                <div class="up-modal-content" v-if="!submitingRefund && refund.actualTransaction">
                    
                         <md-field :class="getValidationClass('refund.partial')">
                            <label for="refund-type">Refund type</label>
                            <md-select v-model="refund.partial" name="refund-type" id="refund-type" :disabled="refund.actualTransaction.partiallyRefunded">
                                <md-option :value="true">Partial</md-option>
                                <md-option :value="false">Total</md-option>
                                
                            </md-select>
                            <span class="md-error" v-if="!$v.refund.partial.required">Refund type is required</span>

                        </md-field>
                         <md-field :class="getValidationClass('refund.amount')">
                            <label>Amount to refund {{refund.partial ? '(Partial)': '(Total)'}}</label>
                            <md-input v-model="refund.amount" type="number" step="5" :disabled="!refund.partial"></md-input>
                            <span class="md-error" v-if="!$v.refund.amount.required">Amount is required</span>
                            <span class="md-error" v-if="!$v.refund.amount.minValue">Min refund value is 1</span>
                            <span class="md-error" v-if="!$v.refund.amount.maxValue">Max value is {{refund.maxPossibleValue}}</span>
                            
                        </md-field>
                        <md-button class="md-primary md-raised full-width-button" @click="refundPayment(refund.actualTransaction.type,refund.actualId,refund.actualTransaction)">Refund</md-button>
                   
                </div>
            </md-dialog>
            <md-dialog :md-active.sync="requestedVehicleDialog" :style="[dialogDimensions]" v-if="reservation && reservation.assignedDriver && reservation.assignedDriver.requestedVehicle" :md-fullscreen="false" class="free-dialog">
                <md-dialog-title class="md-title">Requested vehicle</md-dialog-title>
                <div class="up-modal-content vehicle-dialog" >
                    <CRow class="pb-3">
                        <CCol sm="8">
                            <carousel 
                                            
                                            :per-page="1" :mouse-drag="true"  
                                            paginationActiveColor="#f9ba00" 
                                            paginationColor="#c3c3c3" 
                                            paginationPosition="top" 
                                            ref="vehicleCarousel"
                                            :adjustableHeight="true"
                                        
                                            
                                    >
                                    <slide v-for="picture in reservation.assignedDriver.requestedVehicle.pictures" v-bind:key="picture.id">
                                        <img :src="picture">
                                    </slide>
                                </carousel> 
                        </CCol>
                        <CCol sm="4">
                           
                            <md-list>
                                <md-list-item>Brand <code>{{reservation.assignedDriver.requestedVehicle.mainInfo.brand}}</code></md-list-item>
                                <md-list-item >Model <code>{{reservation.assignedDriver.requestedVehicle.mainInfo.carModel}}</code></md-list-item>
                                <md-list-item >Color <code>{{reservation.assignedDriver.requestedVehicle.mainInfo.color}}</code></md-list-item>
                                <md-list-item >Plate <code>{{reservation.assignedDriver.requestedVehicle.mainInfo.plate}}</code></md-list-item>
                                <md-list-item >Year <code>{{reservation.assignedDriver.requestedVehicle.mainInfo.year}}</code></md-list-item>
                                <md-list-item >Luggage <code>{{reservation.assignedDriver.requestedVehicle.luggage}}</code></md-list-item>
                                <md-list-item >Passenger <code>{{reservation.assignedDriver.requestedVehicle.passengers}}</code></md-list-item>
                            </md-list>
                        </CCol>
                    </CRow>
                </div>
            </md-dialog>
            <md-dialog :md-active.sync="duplication.active" :style="[dialogDimensions]"  :md-fullscreen="false" class="free-dialog" v-if="reservation">
                <md-dialog-title class="md-title">{{ duplication.title }}</md-dialog-title>
                <div class="up-modal-content" >
                    <CRow>
                        <CCol sm="12 pb-2">
                            <md-field :class="getValidationClass('newPuDatetime')">
                                <VueCtkDateTimePicker 
                                    v-model="duplication.newPuDatetime" 
                                    :dark="true" 
                                    :inline="true" 
                                    :formatted="'llll (HH:mm [HS])'"
                                    :format="'YYYY-MM-DD hh:mm a'"
                                    :output-format="'X'"
                                    :color="'#448aff'"
                                    :button-color="'#448aff'"	
                                    :minute-interval="5"
                                    id="duplication-timepicker"
                                    :disabled="user.data.activeRole === 'driver'"
                                />
                                <span class="md-error" v-if="!$v.duplication.newPuDatetime.required">Date &amp; time are required</span>
                            
                                

                                

                            </md-field>
                        </CCol>
                        
                    </CRow>
                    <CRow >
                        <CCol sm="12" class="mb-4">
                            <div class="md-helper-text mt-4" v-if="reservation.companyProfile">
                                <span v-if="user.data.activeRole === 'company-admin'">Selected date and time below will be applied to  <b>{{reservation.companyProfile.name}}</b> timezone <b>{{reservation.companyProfile.timeZone}}</b> <br></span>
                                <b>Actual date time {{companyActualDateTime}}</b>
                            </div>
                        </CCol>
                    </CRow>
                </div>
                <md-dialog-actions class="fixed-bottom-dialog" >
                    <md-button class="md-primary md-raised full-width-button" @click="duplicateRes()">{{ duplication.buttonText}}</md-button>
                    <md-button class="md-raised full-width-button" @click="duplication.active = false">Close</md-button>
                </md-dialog-actions> 
            </md-dialog>
            <md-dialog :md-active.sync="passengerDialog" :style="[dialogDimensions]" class="scrollable-content-dialog maxium-width-dialog" v-if="reservation">
                <md-dialog-title>Add passengers</md-dialog-title>
                <div class="up-modal-content">
                    
                    <md-steppers md-vertical :md-active-step.sync="addPassengerStep" :md-description="clientHasAccount == 'on' ? 'YES' : 'NO'">
                        <md-step id="client-has-account" md-label="Does client has account?" v-if="!reservation.client" :md-error="clientHasAccountProp.error" :md-done.sync="clientHasAccountProp.done" :md-editable="clientHasAccountProp.editable">
                            <md-switch v-model="clientHasAccount" class="md-primary" value="on">Yes</md-switch>
                            <md-switch v-model="clientHasAccount" value="off">No</md-switch>
                            
                        </md-step>
                        <md-step id="client-autocomplete-selector" :md-label="clientHasAccount === 'on' ? 'Find client account' : 'Fill new client info'" v-if="(clientHasAccount == 'on' || clientHasAccount == 'off') && !reservation.client " :md-error="findClientAccount.error" :md-done.sync="findClientAccount.done" :md-editable="findClientAccount.editable">
                            <form autocomplete="off" v-if="clientHasAccount === 'on'"> 
                                <md-autocomplete 
                                    
                                    md-input-id="client-selector"
                                    :md-options="clients" 
                                    :md-open-on-focus="false" 
                                    :md-dense="true"
                                    
                                    @md-changed="clientChanged"
                                    @md-selected="setClient"
                                    
                                    :value="clientText"
                                    aria-autocomplete="off"
                                    :class="getValidationClass('clients')"
                                    ref="clientAutocomplete"
                                    
                                    >
                                    <label>Type phone, client name, lastname etc.</label>
                                    <template slot="md-autocomplete-item" slot-scope="{ item, term }" >
                                        
                                        <md-highlight-text :md-term="term">{{ item.type === 'corporate' ? item.name : `${item.firstName} ${item.lastName}`}} {{ item.phone ? `(${item.phone})` : ''}} | {{item.type}}</md-highlight-text>
                                    </template>
                                    <template slot="md-autocomplete-empty" slot-scope="{ term }"> No matches found for <b>{{ term }}</b> </template>
                                    
                                </md-autocomplete>
                            </form>

                            <div v-else-if="clientHasAccount === 'off'">
                                <p class="md-subheading">Client will be saved after reservation is created.</p>


                                
                                <p class="md-subheading margin-top-40">Client type</p>
                                <md-switch v-model="newClient.type" class="md-primary" value="corporate">Corporate</md-switch>
                                <md-switch v-model="newClient.type" value="individual">Individual</md-switch>
                                <md-field :class="getValidationClass('newClient.type')" class="no-line reset-height">
                                    <span class="md-error" v-if="!$v.newClient.type.required">Type is required</span>
                                </md-field>

                                <md-field :class="getValidationClass('newClient.email')">
                                    <label>Email</label>
                                    <md-input v-model="newClient.email" ></md-input>
                                    <span class="md-error" v-if="!$v.newClient.email.required">Email is required</span>
                                    <span class="md-error" v-if="!$v.newClient.email.email">Invalid email</span>

                                </md-field>
                                <md-field :class="getValidationClass('newClient.name')" v-if="newClient.type === 'corporate'">
                                    <label>Company Name</label>
                                    <md-input v-model="newClient.name" ></md-input>
                                    <span class="md-error" v-if="!$v.newClient.name.required">Name is required</span>
                                </md-field>
                                <md-field :class="getValidationClass('newClient.firstName')"  v-if="newClient.type === 'individual'">
                                    <label>Name</label>
                                    <md-input v-model="newClient.firstName" ></md-input>
                                    <span class="md-error" v-if="!$v.newClient.firstName.required" >Name is required</span>
                                </md-field>
                                <md-field :class="getValidationClass('newClient.lastName')"  v-if="newClient.type === 'individual'">
                                    <label>Lastname</label>
                                    <md-input v-model="newClient.lastName" ></md-input>
                                    <span class="md-error" v-if="!$v.newClient.lastName.required">Lastname is required</span>
                                </md-field>
                                <md-field :class="getValidationClass('newClient.phone')">
                                    <label>Phone</label>
                                    <md-input v-model="newClient.phone" ></md-input>
                                    <span class="md-error" v-if="!$v.newClient.phone.required">Name is required</span>
                                </md-field>
                                <md-field>
                                    <label>Additional Phone</label>
                                    <md-input v-model="newClient.additionalPhone" :disabled="newClient.checked"></md-input>
                                </md-field>
                                
                                <div v-if="newClient.type === 'individual'">
                                    <p class="md-subheading margin-top-40">Will <b>{{ newClient.firstName ? newClient.firstName : ''}} {{ newClient.lastName ? newClient.lastName : '' }}</b> be a passenger ?</p>
                                    <md-switch v-model="newClient.willBePassenger" class="md-primary" value="on">Yes</md-switch>
                                    <md-switch v-model="newClient.willBePassenger" value="off">No</md-switch>
                                    <md-field :class="getValidationClass('newClient.willBePassenger')" class="no-line reset-height">
                                        <span class="md-error" v-if="!$v.newClient.willBePassenger.required">Select if added person will be a passenger</span>
                                    </md-field>
                                </div>
                            </div>
                            <md-button class="md-raised md-dense ml-0 mt-4" @click="addPassengerStep = 'client-has-account'">Previous step</md-button>
                            <md-button class="md-primary md-raised md-dense mt-4" v-if="clientHasAccount === 'off'" @click="fireNewClientValidation()">Next step</md-button>
                        </md-step>
                        <md-step id="passengers-add" md-label="Add passengers to new client account" v-if="clientHasAccount === 'off'">
                            <div class="table-magic-wrapper mt-4">
                                <transition name="fixed-last-col">
                                    <table v-if="fixedLastRowActive" class="fixed-last-col-table">
                                        <tr class="md-table-row">
                                            <th class="md-table-head">
                                                <div class="md-table-head-container">
                                                    <div class="md-table-head-label">Actions</div>
                                                </div>
                                            </th>
                                        </tr>
                                        <tr class="md-table-row" v-for="(row,index) in fixedLastColRows" v-bind:key="'row-col' + index" :style="`height:${row.height}px`">

                                            <td class="md-table-cell">
                                                <div class="md-table-cell-container">
                                                    
                                                    <md-button class="ml-0 md-primary md-raised md-dense" @click="addPassengerToNewClient()" v-if="index == fixedLastColRows.length - 1">
                                                    Add passenger
                                                    </md-button>
                                                    <md-button class="ml-0 md-icon-button" v-else @click="removeNewClientPassenger(index)">
                                                        <md-icon>delete</md-icon>
                                                    </md-button>
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                </transition>
                                <md-table ref="passengerAddTable" class="take-back">
                                    <md-table-row>
                                        <md-table-head>Name</md-table-head>
                                        <md-table-head>Lastname</md-table-head>
                                        <md-table-head>Email</md-table-head>
                                        <md-table-head>Phone</md-table-head>
                                        <md-table-head>Actions</md-table-head>
                                    </md-table-row>
                                    <md-table-row v-for="(item,index) in newClient.passengers" v-bind:key="`new-client-pass-${index}`">
                                        
                                    
                                        <md-table-cell>{{ item.firstName }}</md-table-cell>
                                        <md-table-cell>{{ item.lastName }}</md-table-cell>
                                        <md-table-cell>{{ item.email ? item.email : 'Not provided'}}</md-table-cell>
                                        <md-table-cell>{{ item.phone }}</md-table-cell>
                                        <md-table-cell>
                                            <md-button class="ml-0 md-icon-button" @click="removeNewClientPassenger(index)">
                                                <md-icon>delete</md-icon>
        
                                            </md-button>
                                        </md-table-cell>
                                    </md-table-row>
                                    <md-table-row>
                                        
                                        <md-table-cell>
                                            <md-field :class="getValidationClass('newPassengerAddition.firstName')">
                                                <label>Name</label>
                                                <md-input v-model="newPassengerAddition.firstName" ></md-input>
                                                <span class="md-error" v-if="!$v.newPassengerAddition.firstName.required">Name is required</span>
                                            </md-field>
                                        </md-table-cell>
                                        <md-table-cell>
                                            <md-field :class="getValidationClass('newPassengerAddition.lastName')">
                                                <label>Lastname</label>
                                                <md-input v-model="newPassengerAddition.lastName" ></md-input>
                                                <span class="md-error" v-if="!$v.newPassengerAddition.lastName.required">Lastname is required</span>
                                            </md-field>
                                        </md-table-cell>
                                        <md-table-cell>
                                            <md-field :class="getValidationClass('newPassengerAddition.email')">
                                                <label>Email</label>
                                                <md-input v-model="newPassengerAddition.email" ></md-input>
                                                <span class="md-error" v-if="!$v.newPassengerAddition.email.email">Invalid email</span>
                                            </md-field>
                                        </md-table-cell>
                                        <md-table-cell>
                                            <md-field :class="getValidationClass('newPassengerAddition.phone')">
                                                <label>Phone</label>
                                                <md-input v-model="newPassengerAddition.phone" ></md-input>
                                                <span class="md-error" v-if="!$v.newPassengerAddition.phone.required">Phone is required</span>
                                            </md-field>
                                        </md-table-cell>
                                        <md-table-cell>
                                            <md-button class="ml-0 md-primary md-raised md-dense" @click="addPassengerToNewClient()">
                                            Add passenger
        
                                            </md-button>
                                        </md-table-cell>
                                    </md-table-row>
                                </md-table>
                            </div>


                            <md-button class="md-primary md-raised md-dense ml-0 mt-4" @click="addPassengerStep = 'client-autocomplete-selector'">Previous step</md-button>
                            
                        </md-step>
                        <md-step id="passengers-selector" md-label="Select passengers" v-if="clientHasAccount === 'on' && preselected.client">
                            <md-table class="mt-4" v-if="preselected.client && preselected.client.passengers && preselected.client.passengers.length > 0" v-model="preselected.client.passengers" md-card @md-selected="passengersSelected">                          

                                <md-table-toolbar slot="md-table-alternate-header" slot-scope="{ count }">
                                    <div class="md-toolbar-section-start">{{ getAlternateLabel(count) }}</div>
                                </md-table-toolbar>

                                <md-table-row slot="md-table-row" slot-scope="{ item }"  md-selectable="multiple" md-auto-select :md-disabled="item.alreadySelected">
                                    <md-table-cell md-label="Name" md-sort-by="firstName">
                                        {{ item.firstName }} 
                                        <md-chip v-if="item.alreadySelected" class="ml-2 uplimo-yellow mini mb-0">Already selected</md-chip>
                                    </md-table-cell>
                                    <md-table-cell md-label="Name" md-sort-by="lastName">{{ item.lastName }}</md-table-cell>
                                    <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
                                </md-table-row>
                            </md-table>
                            <md-button class="md-raised md-dense ml-0 mt-4" @click="addPassengerStep = 'client-autocomplete-selector'" v-if="!reservation.client">Previous step</md-button>

                        </md-step>
                    </md-steppers>
                </div>   
                <md-dialog-actions>
                    <md-button class="md-raised" @click="passengerDialog = false; isBusy = false">Close</md-button>
                    <md-button class="md-raised md-primary" @click="addPassenger()" v-if="clientHasAccount === 'on'">{{ (preselected.client && !preselected.passengers) || ( preselected.passengers && preselected.passengers.length == 0) ? 'Confirm client and add passengers later' : 'Confirm'  }}</md-button>
                    <md-button class="md-raised md-primary" @click="addPassenger()" v-if="clientHasAccount === 'off'">{{ newClient.passengers.length > 0 ? 'Confirm' : 'Confirm client data and add passengers later'}}</md-button>
                </md-dialog-actions>
                
            </md-dialog> 
            <md-dialog :md-active.sync="newClientPassengerDialog.active" :style="[dialogDimensions]">
                    <md-dialog-title>Add non registered passenger</md-dialog-title>
                    
                    <div class="up-modal-content">
                        <p class="md-subheading">Passenger will be saved into clients account after reservation is created.</p>
                        <md-field :class="getValidationClass('dialog.email')">
                            <label>Email</label>
                            <md-input v-model="newClientPassengerDialog.email" ></md-input>
                            <span class="md-error" v-if="!$v.newClientPassengerDialog.email.required">Email is required</span>
                            <span class="md-error" v-if="!$v.newClientPassengerDialog.email.email">Invalid email</span>

                        </md-field>
                        <md-field :class="getValidationClass('dialog.firstName')" >
                            <label>Name</label>
                            <md-input v-model="newClientPassengerDialog.firstName" :disabled="newClientPassengerDialog.checked"></md-input>
                            <span class="md-error" v-if="!$v.newClientPassengerDialog.firstName.required">Name is required</span>
                        </md-field>
                        <md-field :class="getValidationClass('dialog.lastName')">
                            <label>Lastname</label>
                            <md-input v-model="newClientPassengerDialog.lastName" :disabled="newClientPassengerDialog.checked"></md-input>
                            <span class="md-error" v-if="!$v.newClientPassengerDialog.lastName.required">Lastname is required</span>
                        </md-field>
                        <md-field :class="getValidationClass('dialog.phone')">
                            <label>Phone</label>
                            <md-input v-model="newClientPassengerDialog.phone" :disabled="newClientPassengerDialog.checked"></md-input>
                            <span class="md-error" v-if="!$v.newClientPassengerDialog.phone.required">Name is required</span>
                        </md-field>
                        <md-field>
                            <label>Additional Phone</label>
                            <md-input v-model="newClientPassengerDialog.additionalPhone" :disabled="newClientPassengerDialog.checked"></md-input>
                        </md-field>
                        <md-button  class="md-raised md-primary full-width-button" @click="addNonRegisteredPassenger()">Add passenger</md-button>
                    </div>
                    <md-dialog-actions>

                </md-dialog-actions>

            </md-dialog>
            <md-snackbar md-position="center" :md-duration="snackbar.isInfinity ? Infinity : snackbar.duration" :md-active.sync="snackbar.show" md-persistent>
                <span>{{ snackbar.message }}</span>
                <md-button class="md-primary" @click="snackbar.show = false">Ok</md-button>
            </md-snackbar>
            <!--transition name="fade">
                <div class="bottom-el" :style="[dialogDimensions]" v-if="rateData && $refs.mainTabs.activeTab == 'rates-and-taxes'"> 
                    <md-bottom-bar>
                        <md-toolbar class="md-dense md-primary" md-elevation="1" v-if="rateData">
                            <h3 class="md-body-2 mb-0" style="flex: 1">Grand total </h3>
                            <h4 class="mb-0">${{roundNumber(rateData.price)}}</h4>
                        </md-toolbar>
                    </md-bottom-bar>
                </div>
            </transition-->  
        </div>
    </div>
</template>

<script>
import VueTimepicker from 'vue2-timepicker'
import DirectionsRenderer from './../maps/DirectionsRenderer';
import { validationMixin } from 'vuelidate'
import FeeCalculator from './FeeCalculator.vue';
import firebase from '../../Firebase'
import * as VueGoogleMaps from 'vue2-google-maps'
import {
    between,
    required,
    email,
    minLength,
    //maxLength,
    minValue,
    maxValue,
    requiredIf
} from 'vuelidate/lib/validators'
import { brandSet } from '@coreui/icons';
import { VuePaycard } from 'vue-paycard'

import axios from "axios"
import axiosApi from "./../../helpers/axios";
import moment from 'moment'
import moment_timezone from "moment-timezone";
import Vue from 'vue';
import {loadStripe} from '@stripe/stripe-js';

export default {
    name: 'Reservation',
    mixins: [validationMixin],
    components: {
        DirectionsRenderer,
        VueTimepicker,
        VuePaycard,
        FeeCalculator
    },
    directives: {
        'number-only': {
            bind (el) {
                function checkValue (event) {
                event.target.value = event.target.value.replace(/[^0-9]/g, '')
                if (event.charCode >= 48 && event.charCode <= 57) {
                    return true
                }
                event.preventDefault()
                }
                el.addEventListener('keypress', checkValue)
            }
        },
        'letter-only': {
            bind (el) {
                function checkValue (event) {
                if (event.charCode >= 48 && event.charCode <= 57) {
                    event.preventDefault()
                }
                return true
                }
                el.addEventListener('keypress', checkValue)
            }
        }
    },
    data() {
        return {
            editingResStatus: false,
            activeTabIndex: 0,
            activeTabLabel: 'Date, time and location',
            steps: [    
                        { id: 'date-time-location', label: 'Datime and location' , tabErrorLabel: 'dateTimeLocation'} ,
                        { id: 'general', label: 'General' , tabErrorLabel: 'general'} ,
                        { id: 'dispatchment-info', label: 'Dispatchment info' , tabErrorLabel: 'dispatchmentInfo'} ,
                        { id: 'addons', label: 'Addons' , tabErrorLabel: 'addons'} ,
                        { id: 'rates-and-taxes', label: 'Rates and taxes' , tabErrorLabel: 'ratesAndTaxes'} ,
                        { id: 'passenger', label: 'Passenger' , tabErrorLabel: 'passenger'} ,
                        { id: 'payment-method', label: 'Payment method' , tabErrorLabel: 'paymentMethod'} ,
                        { id: 'emails', label: 'Emails' , tabErrorLabel: 'emails'} ,
                        { id: 'sms', label: 'SMS' , tabErrorLabel: 'sms'} ,
                    ] ,
            showSelector: false,
            fixedLastColRows: [],
            newPassengerAddition: {
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                additionalPhone: null,
            },
            fixedLastRowActive: true,
            newClient: {
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                additionalPhone: null,
                passengers: [],
                willBePassenger: null,
                type:null,
            },
            newClientPassengerDialog: {
                active: false,
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                additionalPhone: null

            },
            findClientAccount:{
                editable: false,
                done: false,
                error: null
            },
            clientHasAccountProp: {
                editable: true,
                done: false,
                error: null
            },
            clientHasAccount: null,
            addPassengerStep: 'client-has-account',
            passengerDialog: false,
            guest: true,
            preselected: {
                client: null,
                passengers: null
            },
            passenger: {
                guest: true,
                firstName: '',
                lastName:'',
                email:'',
                phone:'',
                additionalPhone: '',
                qty: null,
                luggage:null
            },
            passengerInput: null,
            filteredPassengers: [],
            //clients:[],
            duplication: {
                active: false,
                roundTrip: null,
                title: null,
                buttonText: null,
                newPuDatetime: null

            },
            requestedVehicleDialog: false,
            modeTab: 'Offering assigment mode',
            unsubscribeLiveChanges: null,
            dispatchmentActiveTab: 'offering-mode',
            driverAssignment: {
                selectedOffers: [],
                busy: false,
                filteredLinkedDrivers: [],
                communities: [],
                linkedAvailableUsersToOffer: [],
                selectedCommunities: [],
                selectedDrivers: [],
                tlcOn: false,
                dmvOn: false,
                dmvBusy: false,
                tlcBusy: false
            },
            directAssignment: {
                filteredLinkedDrivers: [],
                linkedAvailableUsersToOffer: [],
                busy: false,
                selectedDriver: null,
                selectedOffers: [],
                companyDrivers: [],
                tlcOn: false,
                dmvOn: false,
                dmvBusy: false,
                tlcBusy: false
            },
            assignmentMode: 'offering-mode',
            offerFilters: [],
            offers: [],
            possibleFlights: [],
            refund: {
                partial: false,
                amount: null,
                actualTransaction: null,
                actualId: null,
                maxPossibleValue: null
            },
            submitingRefund:false,
            refundDialog: false,
            transactionDialog: false,
            totalTransactionsPaidAmount: 0,
            submitingPayment: false,
            cardToPayAmount:null,
            resReadedFirstSnap: false,
            snackbar: {
                show: false,
                duration: 4000,
                isInfinity: false,
                message:null

            }, 
            notificationPack: {
                types: []
            },
            mailTypes: [
                'confirmation',
                'receipt',
                'cancellation',
                'driverAssignation',
                'driverAssignmentRequest',
                'refund',
                'quotation'
            ],
            mailingInfo: {
                title: null,
                recipients: {
                    passenger: {
                        send: false,
                        active: false
                    },
                    driver: {
                        send: false,
                        active: false
                    },
                    owner: {
                        send: false,
                        active: false
                    },
                    user: {
                        send: false,
                        active: false
                    },
                    others: []
                },
                subject: null,
                body: null,
            },
            smsInfo: {
                title: null,
                recipients: {
                    passenger: {
                        send: false,
                        active: false
                    },
                    driver: {
                        send: false,
                        active: false
                    },
                    owner: {
                        send: false,
                        active: false
                    },
                    user: {
                        send: false,
                        active: false
                    },
                    others: []
                },
                text: null,
                body: null,
            },
            isBusy: true,
            companyActualDateTime: null,
            timezoneOffsetDif: null,
            viewerTimezonePosition: null,
            overridedRateData:null,
            rateData: null,
            mainActiveTab: 'date-time-location',
            //mainActiveTab: 'payment-method',
            paymentMethodActiveTab: 'credit-card',
            tabs: {
                general: {
                    errors: null,
                },
                dateTimeLocation: {
                    errors: null,
                },
                dispatchmentInfo: {
                    errors: null,
                },
                addons: {
                    errors: null,
                },
                ratesAndTaxes: {
                    errors: null,
                },
                passenger: {
                    errors: null,
                },
                paymentMethod: {
                    errors: null,
                },  
                emails: {
                    errors: null,
                },
                sms: {
                    errors: null,
                }
            },
            filteredVehicleTypes: [],
            filteredAddons:[],
            selectedData: {
                companyProfile: null,
                datetime:null,
                jobType: null,
                vehicleType: undefined,
                passengers:null,
                luggage:null,
                dispatcher:null,
                addOns: [],
                extras: {
                    childSeat: null,
                    accessible: null,
                    meetAndGreet: null
                }
            },
            feeCalculatorData: {
                jobType: {
                    name: null,
                    type: null
                },
                vehicleType: {
                    
                },
                overviewPath:[],
                addOns:[],
                extras: null,
                promoCode:null
                
            },
            loadingFlight: false,
            loadingPromoCode: false,
            serverError: true,
            airlines: [],
            airlinesList: this.$airlines,
            airportsList: this.$airports,
            inputFields: {
                cardNumber: 'v-card-number',
                cardName: 'v-card-name',
                cardMonth: 'v-card-month',
                cardYear: 'v-card-year',
                cardCvv: 'v-card-cvv'
            },
            minCardYear: new Date().getFullYear(),
            mainCardNumber: '',
            cardNumberMaxLength: 19,
            dialogLeft: 0,
            step: "general",
            labelWrittenItems: [],
            labelFocusedItems: [],
            waypointsPack: [],
            activeStopIndex: null,
            actualStopAddress: null,
           
            date: new Date(),
            //dispatchers: [],
            //companyProfiles: [],
            //vehicleTypes: [],
            addOns: [],
            //jobTypes: [],
            //serviceTypes: [],
            //reservationStatuses: [],
            //driverStatuses: [],
            //paymentStatuses: [],
            //tripStatuses: [],
            //merchantAccounts: [],
            payment: {
                cash: {
                    paidAmount: null
                },
                invoice: {
                    companyName: null,
                    address: null,
                    ein: null,
                    email: null
                },
                check: {
                    companyName: null,
                    address: null,
                    routing: null,
                    account: null
                },
                cardFields: {
                    cardName: '',
                    cardNumber: '',
                    cardMonth: '',
                    cardYear: '',
                    cardCvv: '',
                    billZip: '',
                    billingAddress: '',
                },
                merchantAccount: null,
                paymentMethod: 'credit-card'
            },
            //reservation: {
                // passenger: {
                //     firstName: '',
                //     lastName:'',
                //     email:'',
                //     phone:'',
                //     additionalPhone: '',
                //     qty: null,
                //     luggage:null
                // },
                // do: {
                //     formatted_address: '',
                //     postalCode: null,
                //     coordinates: {
                //         lat: null,
                //         lng: null
                //     }
                // },
                // pu: {
                //     formatted_address: '',
                //     postalCode: null,
                //     coordinates: {
                //         lat: null,
                //         lng: null
                //     }
                // },
                // waypoints: [],
            //} ,
            unsub:null,
            reservation: null,
            ref: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('reservations'),
            //dispatchRef: this.$firebase.firestore().collection('dispatchers'),
            vehicleRef: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('vehicle-types'),
            jobTypesRef:  this.$firebase.firestore().collection('job-types'),
            statusesRef: this.$firebase.firestore().collection('statuses'),
            addOnsRef: this.$firebase.firestore().collection('users').doc(this.$root.uid).collection('add-ons'),
            lastIdRef: this.$firebase.firestore().collection('res-last-id'),
            lastId: null,
            coordinates: {
                lat: 37.09024,
                lng:-95.712891
            },
            markers: [],
            showDialog: false,
            showSMSDialog: false,
            isEditing: false,
            confirmDialog: false,
            goTo: null,
            confirmLeave: false
            // start: "",
            // end: "",
           
        }
    },
    beforeMount() {
        window.addEventListener("beforeunload", this.preventNav)
    },
    beforeRouteLeave(to, from, next) {
   
        if (this.isEditing && !this.confirmLeave) {
            this.confirmDialog = true;
            console.log(to);
            this.goTo = to;
            return 
            if (!window.confirm("Leave without saving?")) {
                return;
            }
        }
        next();
    },
    beforeDestroy() {
       window.removeEventListener("beforeunload", this.preventNav);
    },
    mounted(){
        this.$store.commit('centerDialog',window.innerWidth)
        this.getAirlines();
    },
    watch: {
        'clientHasAccount': {
            handler: function(val,oldVal) {
                console.log(val)

                if(val != null) {
                    this.addPassengerStep = 'client-autocomplete-selector'
                }

                this.validateClientPassengerModal()
                

            }
        },
        'newClient.willBePassenger' : {
            handler: function(val,oldVal) {
                console.log(oldVal)
                console.log(val)
                //if(oldVal != null) {
                let willBePassengerExists = false
                let indexToRemove = null
                for (let i = 0; i < this.newClient.passengers.length; i++) {
                    if(this.newClient.passengers[i].willBePassenger == true) {
                        willBePassengerExists = true
                        indexToRemove = i
                    }
                    
                }

                if(!willBePassengerExists && val == 'on') {
                    let passToAdd = {
                        firstName: this.newClient.firstName,
                        lastName: this.newClient.lastName,
                        phone: this.newClient.phone,
                        email: this.newClient.email,
                        additionalPhone: this.newClient.additionalPhone,
                        willBePassenger: true
                    }
                    this.newClient.passengers.push(passToAdd)
                }

                if(val == 'off') {
                    this.newClient.passengers.splice(indexToRemove, 1)
                }

                //this.addPassengerStep = 'passengers-add'
                this.fireNewClientValidation()


                //}
            }
        },
        cardYear () {
            if (this.payment.cardFields.cardMonth < this.minCardMonth) {
                this.payment.cardFields.cardMonth = ''
            }
        },
        'reservation': {
            handler: function(val, oldVal) {
                
                if(val && oldVal && val.transactions && val.transactions.length == oldVal.transactions.length) {
                    
                    this.isEditing = true 
                }
                
            },
            deep:true
        },
        'selectedData.datetime': {
            handler: function(val,oldVal) {

                if(oldVal != null) {
                    // let localDateTime = moment.unix(val) ;
                    // console.log('localDateTime')
                    // console.log(localDateTime.format('LLLL'))
                    // let utcRealDateTime = localDateTime.utcOffset(this.timezoneOffsetDif).format()
                    // let utcCutOffset;

                    // console.log('this.timezoneOffsetDif');
                    // console.log(this.timezoneOffsetDif);
                    // if(this.timezoneOffsetDif === 0) {
                    //     utcCutOffset = utcRealDateTime
                    // } else {
                    //     utcCutOffset = utcRealDateTime.split('+')[0] + '+00:00'
                    // }

                    // console.log('utcCutOffset');
                    // console.log(utcCutOffset);

                    // let dbaDateTime = moment.utc(utcCutOffset);
                    // console.log('dbaDateTime')
                    // console.log(dbaDateTime.format('LLLL Z'))
                    // this.reservation.day = parseInt(dbaDateTime.format('X'))
                    let localDateTime = moment.unix(val) ;
                    let datePack = {
                        date: parseInt(localDateTime.format('D')),
                        month: parseInt(localDateTime.format('M')) - 1,
                        year:parseInt(localDateTime.format('YYYY')),
                        hour:parseInt(localDateTime.format('H')),
                        minute:parseInt(localDateTime.format('m')),
                    }

                    let companyProfileTime = moment().tz(this.reservation.companyProfile.timeZone).set(datePack)
                    let dbaTime = companyProfileTime.unix()
                   
                    this.reservation.day = dbaTime



                }
               
            }

        },
        'reservation.childSeat': {
            handler: function(val,oldVal) {
                this.selectedData.extras.childSeat = val;
            }
        },
        'reservation.accessible': {
            handler: function(val,oldVal) {
                this.selectedData.extras.accessible = val;
            }
        },
        'reservation.meetAndGreet': {
            handler: function(val,oldVal) {
                this.selectedData.extras.meetAndGreet = val;

            }
        },
        'selectedData.jobType': {
            handler: function(val,oldVal) {
                
                if(oldVal != null) {
                    this.rateData = null;
                    this.filteredVehicleTypes = [];
                    this.reservation.vehicleType = null;
                    this.selectedData.vehicleType = null;
                    this.feeCalculatorData.vehicleType = null;
                    for (let i = 0; i < this.serviceTypes.length; i++) {
                        if(this.serviceTypes[i].id === val) {
                                this.feeCalculatorData.jobType = this.serviceTypes[i]
                                this.reservation.jobType = this.serviceTypes[i]
                                //this.serviceTypes[i].vehicleType
                                for (let j = 0; j < this.serviceTypes[i].vehicleType.length; j++) {
                                    for (let k = 0; k < this.vehicleTypes.length; k++) {
                                        if(this.serviceTypes[i].vehicleType[j] == this.vehicleTypes[k].id ) {
                                            this.filteredVehicleTypes.push(this.vehicleTypes[k]);
                                        }
                                        
                                    }
                                    
                                }
                        }
                    }
                     this.validateAllSteps()
                } else {
                    

                }
               
            }   
        },
        'selectedData.companyProfile': {
            handler: function(val,oldVal) {
                for (let i = 0; i < this.companyProfiles.length; i++) {
                   if(this.companyProfiles[i].id === val) {
                        this.reservation.companyProfile = this.companyProfiles[i]
                        this.updateTime()
                        this.flowTimeZone();
                   }
                }
            }   
        },
        'selectedData.vehicleType': {
            handler: function(val,oldVal) {
                
                if(oldVal !== undefined) {
                    this.reservation.addOns = []
                    
                    for (let i = 0; i < this.vehicleTypes.length; i++) {
                        if(this.vehicleTypes[i].id === val) {
                                this.feeCalculatorData.vehicleType = this.vehicleTypes[i]
                                this.reservation.vehicleType = this.vehicleTypes[i]
                                this.selectedData.passengers = this.vehicleTypes[i].passengers
                                this.selectedData.luggage = this.vehicleTypes[i].luggage


                                /////
                                let counter = 0;
                                if(this.reservation.vehicleType.rates.addons){
                                    for (let i = 0; i < this.reservation.vehicleType.rates.addons.length; i++) {
                                        if(this.reservation.vehicleType.rates.addons[i].enabled && this.reservation.vehicleType.rates.addons[i].price) {
                                            counter++;
                                        }
                                        
                                    }
                                }
                                console.log('Counter',counter);
                            
                                counter > 0 ? this.reservation.vehicleType.rates.addonsAvailable = true : this.reservation.vehicleType.rates.addonsAvailable = false
                                /////
                        }
                    }
                } else {
                    // for (let i = 0; i < this.vehicleTypes.length; i++) {
                    //     if(this.vehicleTypes[i].id === val) {
                    //             this.feeCalculatorData.vehicleType = this.vehicleTypes[i]
                    //             this.reservation.vehicleType = this.vehicleTypes[i]
                    //             this.selectedData.passengers = this.vehicleTypes[i].passengers
                    //             this.selectedData.luggage = this.vehicleTypes[i].luggage
                    //     }
                    // }
                }
            }   
        },
        'selectedData.dispatcher': {
            handler: function(val,oldVal) {
                
                for (let i = 0; i < this.dispatchers.length; i++) {
                   if(this.dispatchers[i].uid === val) {
                        this.reservation.dispatcher = this.dispatchers[i]
                       
                   }
                }
            }   
        },
        'selectedData.addOns': {
            handler: function(val,oldVal) {
                console.log(val);
                let addOns = [];
                for (let i = 0; i < val.length; i++) {
                    
                    for (let j = 0; j < this.reservation.vehicleType.rates.addons.length; j++) {
                        if(val[i] == this.reservation.vehicleType.rates.addons[j].id) {
                            addOns.push(this.reservation.vehicleType.rates.addons[j]);
                        }
                        
                    }
                }
                this.reservation.addOns = addOns ;
                this.feeCalculatorData.addOns = addOns ;
                
            }   
        },
        'reservation.vehicleType.rates.addons' : {
            handler: function(val,oldVal) {
                
                
                if(val !== undefined) {
                    let addOns = [];
                    for (let i = 0; i < val.length; i++) {
                        if(parseFloat(val[i].qty) > 0) {
                            addOns.push(val[i])
                        }
                    }
                    this.reservation.addOns = addOns ;
                    this.feeCalculatorData.addOns = addOns ;
                }

            },
            deep: true
        },
        'reservation.day': {
            handler: function(){
                //this.initFlightCheck()
            }
        },
        '$store.state.sidebarMinimize': function() {
            this.$store.commit('centerDialog',window.innerWidth)
        },
        '$store.state.sidebarShow': function(){
            this.$store.commit('centerDialog',window.innerWidth)
        },
        'refund.partial': {
            handler: function(val, oldVal){
                if(val) {
                    this.refund.amount = null;
                } else {
                    this.refund.amount = this.refund.actualTransaction.amount;
                }
            }
        },
        'refund.amount': {
            handler: function(val, oldVal){
                if(parseFloat(val) ===  parseFloat(this.refund.actualTransaction.amount)) {
                    this.refund.partial = false;
                }
            }
        },
        'payment.merchantAccount': {
            handler: async function(val,oldVal) {
                console.log('payment.merchantAccount watcher')
                let selectedMerchant ;
                for(let i = 0 ; i <  this.merchantAccounts.length ; i++) {
                    if(this.merchantAccounts[i].id === val) {
                        selectedMerchant = this.merchantAccounts[i]
                    }
                }

              
                //this.payment.merchantAccount = selectedMerchant.id
                this.payment.gateway = selectedMerchant.gateway
                this.payment.paymentMethod = 'cash'
                this.payment.paymentMethod = 'credit-card'
                this.payment.public = selectedMerchant.public

                if(val != null && this.payment.gateway == 'Stripe' && this.reservation.generatedPrice) {
                    console.log('init merch')
                    this.initMerchantObject(selectedMerchant.public, 'stripe')
                    // if(this.payment.paymentIntent) {
                    //     await this.updateOrderInformation()
                    // } else {

                    //     await this.sendOrderInformation()
                    // }
                }

                

            }
        },
        
        
    },
    methods: {
        resStatusSelected(selected) {
            this.editingResStatus = false
            this.fireUpdate()
            console.log(selected)
        },
        toggleStatusSelect() {
            this.editingResStatus = !this.editingResStatus
        },
        changeMobileTab(id,index) {
            this.$refs.mainTabs.activeTab = id;
            this.activeTabLabel = this.steps[index].label
            this.activeTabIndex = index
            this.showSelector = false

        },

        prevTab() {
            let prevTabId = null ;
            let activeTabIndex = null;

            for (let i = 0; i < this.steps.length; i++) {
                if(this.steps[i].id == this.$refs.mainTabs.activeTab) {
                    activeTabIndex = i
                }
                
            }
            console.log(activeTabIndex)
            prevTabId = this.steps[activeTabIndex - 1].id
            this.activeTabLabel = this.steps[activeTabIndex - 1].label
            this.activeTabIndex = activeTabIndex - 1
            this.$refs.mainTabs.activeTab = prevTabId;
        },
        nextTab() {
            let nextTabId = null ;
            let activeTabIndex = null;

            for (let i = 0; i < this.steps.length; i++) {
                if(this.steps[i].id == this.$refs.mainTabs.activeTab) {
                    activeTabIndex = i
                }
                
            }
            console.log(activeTabIndex)
            nextTabId = this.steps[activeTabIndex + 1].id
            this.activeTabLabel = this.steps[activeTabIndex + 1].label
            this.activeTabIndex = activeTabIndex + 1
            
            this.$refs.mainTabs.activeTab = nextTabId;

        },
        updateFixedLastCol(ref){
            let table = this.$refs[ref];
            let rows = table.$el.querySelectorAll('.content-row td:nth-last-child(1)')
            console.log(rows)

            this.$nextTick(() => {

                this.fixedLastColRows = []
                for (let i = 0; i < rows.length; i++) {
    
    
                    let dataToSave = {
                        height: rows[i].clientHeight,
                    }
                    this.fixedLastColRows.push(dataToSave)
                    
                }

                
            })
           

        },
        validateNewClientInfo(){
            let type,email,name,firstName,lastName,phone,willBePassenger

            type = this.$v.newClient.type.$invalid
            email = this.$v.newClient.email.$invalid
            name = this.$v.newClient.name.$invalid
            firstName = this.$v.newClient.firstName.$invalid
            lastName = this.$v.newClient.lastName.$invalid
            phone = this.$v.newClient.phone.$invalid
            willBePassenger = this.$v.newClient.willBePassenger.$invalid

            if(!email && this.clientExsits) {
                console.log('invalid email 1')
                email = true
            }

            if(this.userCheck) {
                if(!this.isPassenger && this.client.type === 'individual') {
                    console.log('invalid email 2')
                    email = true
                }

                if(!this.isClient && this.client.type === 'corporate') {
                    console.log('invalid email 3')
                    email = true
                }
            }

            return !type && !email && !name && !firstName && !lastName && !phone && !willBePassenger? true : false
        },
        fireNewClientValidation(){
            if(this.validateNewClientInfo()) {
                console.log('Valid New')
                this.addPassengerStep = 'passengers-add'

                this.$nextTick(() => {
                    this.updateFixedLastCol('passengerAddTable')
                })

                this.$refs.passengerAddTable.$el.addEventListener('scroll', this.tableScrolledHorizontally)
                
                this.findClientAccount.error = null
                this.findClientAccount.editable = false
                this.findClientAccount.done = true
            } else {
                this.$v.newClient.$touch()
                this.findClientAccount.error = 'Complete required info for new client'
                this.findClientAccount.editable = true
                this.findClientAccount.done = false

            }
        },
        resetPassengerDialog() {
            this.passengerDialog = false
            this.addPassengerStep = 'passengers-selector'
            this.clientHasAccount = null
            this.preselected.client = null
            this.preselected.passengers = null
            this.newClient = {
                email: null,
                firstName: null,
                lastName: null,
                phone: null,
                additionalPhone: null,
                passengers: [],
                willBePassenger: null,
                type:null,
            }

        },
        addPassenger() {
            
            if(this.validateClientPassengerModal()){
                
                
                if(this.clientHasAccount == 'on') {
    
                    this.reservation.client = this.preselected.client
                    let passengersToAdd = []
                    for (let i = 0; i < this.preselected.client.passengers.length; i++) {
                        if(this.preselected.passengers) {
        
                            for (let j = 0; j < this.preselected.passengers.length; j++) {
                                if(this.preselected.client.passengers[i].id == this.preselected.passengers[j].id){
                                    this.preselected.client.passengers[i].alreadySelected = true
                                    passengersToAdd.push(this.preselected.passengers[j])
                                }
                            }
                        }
                        
                    }
                    this.reservation.passengers = [].concat(this.reservation.passengers,passengersToAdd);
        
                   this.resetPassengerDialog()
                    
                } else if(this.clientHasAccount == 'off') {
    
                    let clientToAdd = {
                        email: this.newClient.email,
                        firstName: this.newClient.type == 'individual' ? this.newClient.firstName : null,
                        lastName: this.newClient.type == 'individual' ? this.newClient.lastName : null,
                        name: this.newClient.type == 'corporate' ?  this.newClient.name : null,
                        phone: this.newClient.phone,
                        additionalPhone: this.newClient.additionalPhone,
                        willBePassenger: this.newClient.willBePassenger,
                        type:this.newClient.type,
                        new: true
                    }

                    this.reservation.client = clientToAdd
    
                    this.reservation.passengers = this.newClient.passengers
    
                    this.resetPassengerDialog()
    
                    
    
                }
                



            } else {

            }

        },
        getAlternateLabel (count) {
            let plural = ''

            if (count > 1) {
            plural = 's'
            }

            return `${count} passenger${plural} selected`
        },
        validateClientPassengerModal(){
            if(this.clientHasAccount == null) {
                console.log('First step null')
                this.addPassengerStep = 'client-has-account'
                this.clientHasAccountProp.error = 'Select one of these options'
                
            } else if(this.clientHasAccount == 'on') {
                this.clientHasAccountProp.done = true
                this.clientHasAccountProp.editable = false
                if(!this.preselected.client) {
                    this.findClientAccount.error = 'Select client below'
                } else {
                    this.findClientAccount.error = null
                    this.findClientAccount.editable = false
                    this.findClientAccount.done = true

                    return true

                }
            } else if(this.clientHasAccount == 'off') {
                this.clientHasAccountProp.done = true
                this.clientHasAccountProp.editable = false

                if(this.$v.newClient.$invalid) {
                    this.findClientAccount.error = 'Select client below'
                   
                    this.$v.newClient.$touch()
                    if(this.$v.newClient.$anyDirty) {

                        this.fireNewClientValidation()
                    }
                    
                } else {
                    this.findClientAccount.error = null
                    this.findClientAccount.editable = false
                    this.findClientAccount.done = true

                    return true

                }



            }
        },
        setClient(item) {
            //console.log(item)
            this.preselected.client = item
            this.addPassengerStep = 'passengers-selector'
            this.validateClientPassengerModal()

            
        },
        clientChanged(term) {
            if(!term) {
                this.reservation.client = null
            }
        },
        passengersSelected(items) {
            this.preselected.passengers = items
        },
        validateNonRegisteredPassenger() {
            let firstName,lastName,passengerEmail,phone ;
            firstName = this.$v.newClientPassengerDialog.firstName.$invalid;
            lastName = this.$v.newClientPassengerDialog.lastName.$invalid;
            passengerEmail = this.$v.newClientPassengerDialog.email.$invalid;
            phone = this.$v.newClientPassengerDialog.phone.$invalid;
            let validPassenger = !firstName && !lastName && !passengerEmail && !phone ? true : false ;
            return validPassenger
        },
        addNonRegisteredPassenger() {
            let passenger = {
                firstName: this.newClientPassengerDialog.firstName,
                lastName: this.newClientPassengerDialog.lastName,
                phone: this.newClientPassengerDialog.phone,
                email: this.newClientPassengerDialog.email,
                additionalPhone: this.newClientPassengerDialog.additionalPhone,
            }
            if(this.validateNonRegisteredPassenger()) {
                console.log('valid')
                this.reservation.passengers.push(passenger)
                this.newClientPassengerDialog.active = false
                this.newClientPassengerDialog =  {
                    active: false,
                    email: null,
                    firstName: null,
                    lastName: null,
                    phone: null,
                    additionalPhone: null
    
                }
            } else {
                console.log('invalid')
                this.$v.newClientPassengerDialog.$touch()
            }


        },
        fireNonRegisteredPass() {
            this.newClientPassengerDialog.active = true
        },
        fireNonRegisteredPass() {
            this.newClientPassengerDialog.active = true
        },
        firePassengerDialog() {
            if(this.reservation.client) {
                this.clientHasAccount = 'on'
                this.preselected.client = this.reservation.client
            } else {
                
            }
            this.$nextTick(() => {
                if(this.reservation.client) {
                    this.addPassengerStep = 'passengers-selector'
                } else {

                }
                this.passengerDialog = true
                //console.log(this.$refs.clientAutocomplete.getElementById(''))

            })
        },
        removeSelectedClient() {
            this.reservation.client = null;
            this.reservation.passengers = []
            this.preselected.client = null;
            this.preselected.passengers = null;
            this.addPassengerStep = 'client-has-account'
            
        },
        notAssignedStatus(){

            return this.statuses[this.reservation.driverStatus] != `Assigned`
        },

        getFilteredPassengers(searchTerm){
            this.filteredPassengers = new Promise(resolve => {
                window.setTimeout(() => {
                    if (!searchTerm) {
                        let aux = this.passengers.slice(0, 10);
                        resolve(aux);
                    } else {
                        const term = searchTerm.toLowerCase()
                        
                        //resolve(this.airlinesList.filter(({ name }) => name.toLowerCase().includes(term)).slice(0, 10))
                        resolve(this.passengers.filter(function(v, i) {
                            
                            
                            return (
                                        v.firstName.toLowerCase().includes(term) || 
                                        v.phone.toLowerCase() == term.toLowerCase() || 
                                        v.email.toLowerCase() == term.toLowerCase() || 
                                        v.lastName.toLowerCase() == term.toLowerCase()
                                    );
                        }).slice(0, 100))
                            
                    }
                }, 0)
            })
        },
        focusedPassengers(ev) {
            ev.target.value = ""
            this.getFilteredPassengers()
        },
        addGuestPassenger() {
            if(this.validateGuestPassenger()) {
                console.log('Valid')
                this.reservation.passengers.push(this.passenger)
                this.passenger = {
                    guest: true,
                    firstName: '',
                    lastName:'',
                    email:'',
                    phone:'',
                    additionalPhone: '',
                    qty: null,
                    luggage:null
                }
                this.$v.passenger.$reset()
                
            } else {
                console.log('Invalid')
                this.$v.passenger.$touch()
            }
        },
        removeAddedPassenger(index) {
            this.reservation.passengers.splice(index,1)
        },
        validateGuestPassenger() {
            let firstName,lastName,passengerEmail,phone ;
            firstName = this.$v.passenger.firstName.$invalid;
            lastName = this.$v.passenger.lastName.$invalid;
            passengerEmail = this.$v.passenger.email.$invalid;
            phone = this.$v.passenger.phone.$invalid;
            let validPassenger = !firstName && !lastName && !passengerEmail && !phone ? true : false ;
            return validPassenger
        },
        setPassenger(item) {
            console.log(item)
            this.reservation.passengers.push(item)
            this.passengerInput = ''

           


        },
        positionMenu() {
            let list = document.querySelectorAll('.md-menu-content')
            
            
            
            if(
                this.$refs.airlineAutocomplete && 
                list.length > 0 && 
                list[0].querySelectorAll('.airline-code').length > 0){
                console.log('RRRee position menu')
                let input = this.$refs.airlineAutocomplete
                
                
    
                // Get current location's distance from the top of the page
                var position = window.pageYOffset;
                // Get an element's distance from the top of the page
                var getElemDistance = function ( elem ) {
                    var location = 0;
                    if (elem.offsetParent) {
                        do {
                            location += elem.offsetTop;
                            elem = elem.offsetParent;
                        } while (elem);
                    }
                    return location >= 0 ? location : 0;
                };
                //var elem = document.querySelector('#some-element');
                var location = getElemDistance( input.$el );
                if(list[0]) {
                    setTimeout(function(){

                        list[0].style.top = ( input.$el.offsetHeight + location ) + 'px'
                    },100)

                }
            }
        },
        operatorSelectorOpened() {
            //.addEventListener
            
            this.positionMenu()


        },
        editOrView(readableId, id) {
            this.$router.push({path: `/app/reservations/${readableId}/${id}`})
        },
        startResDuplication(roundTrip) {
            this.duplication.title = roundTrip ? 'Create round trip' : 'Duplicate reservation'
            this.duplication.buttonText = roundTrip ? 'Create trip' : 'Duplicate'
            this.duplication.roundTrip = roundTrip ? true : false
            this.duplication.active = true;

        },
        async duplicateRes() {
            // roundTrip boolean
            this.duplication.active = false
            let th = this;
            this.isBusy = true;
            this.busyMessage = this.duplication.roundTrip ? "Saving round trip reservation" : "Duplicating reservation"
            let reservationToSave = th.reservation
            let pickUp = th.reservation.pu
            let dropOff = th.reservation.do
            let localDateTime = moment.unix(this.duplication.newPuDatetime) ;
            let datePack = {
                date: parseInt(localDateTime.format('D')),
                month: parseInt(localDateTime.format('M')) - 1,
                year:parseInt(localDateTime.format('YYYY')),
                hour:parseInt(localDateTime.format('H')),
                minute:parseInt(localDateTime.format('m')),
            }

            let companyProfileTime = moment().tz(this.reservation.companyProfile.timeZone).set(datePack)
            let dbaTime = companyProfileTime.unix()
            
            reservationToSave.day = dbaTime
            reservationToSave.duplication = {
                originId: this.$route.params.id,
                originReadableId: this.reservation.readableId
            }

            if(this.duplication.roundTrip) {
                reservationToSave.pu = dropOff
                reservationToSave.do = pickUp
                reservationToSave.duplication.roundTrip = true

                
            } else {
                reservationToSave.duplication.roundTrip = false 
            }
            

            return new Promise((resolve, reject) => {
                let data = {
                    duplication: true,
                    roundTrip: th.duplication.roundTrip ? true : null,
                    reservation: reservationToSave,
                    paymentData: th.payment,
                    user: th.user.data.uid,
                    collect: th.payment.collect,
                    creator: {
                        email: th.user.data.email,
                        role: th.user.data.activeRole,
                        displayName: th.user.data.displayName,
                    },
                    transaction: {}
                } ;

                switch (th.payment.paymentMethod) {
                    case 'cash':
                        data.transaction = {
                            amount : th.payment.cash.paidAmount,
                            type: 'cash'
                        }
                        break;
                    case 'credit-card':
                        data.transaction = {
                            cardFields: th.payment.cardFields,
                            amount: th.cardToPayAmount,
                            type: 'paypal'
                        }

                        break;

                }

                console.log('data dupli', data)
               
                axiosApi.post('reservation/create',data).then(response =>{
                    console.log(response);
                    
                    this.$router.push({path: `/app/reservations/${response.data.newReadableId}/${response.data.newId}`})
                    resolve()
                    //this.$router.push({path: `/app/reservations`})

                }).catch(e => {
                
                   
                    console.log(e);
                    reject()
                }) 
            })

        },
        openVehicleDialog() {
            this.requestedVehicleDialog = true
            this.$nextTick(function() {

                console.log(this.$refs.vehicleCarousel)
              
                
            })
        },
        statusClass(className) {
            let slug
            if(className) {
                slug = className.toLowerCase().replace(" ","-")
            }
            return slug
        },
        async startDirectAssignment(res, index) {
            this.directAssignment.actualReservationId = res.id
            this.directAssignment.actualRes = res

            let linkedAvailableUsersToOffer = []
            let companyDrivers = []
            let th = this

            linkedAvailableUsersToOffer = this.directAssignment.linkedAvailableUsersToOffer.filter((driver) => {
                return driver
            })


            companyDrivers = this.directAssignment.companyDrivers.filter((driver) => {  
                
                return driver
            })

            
            this.directAssignment.filteredLinkedDrivers = [].concat(linkedAvailableUsersToOffer, companyDrivers);
            

            this.directAssignment.active = true

        },
        
        validateRefund() {
            this.tabs.paymentMethod.errors = 0;
            let amount,partial;

            amount = this.$v.refund.amount.$invalid;
            partial = this.$v.refund.partial.$invalid;


            amount ? this.tabs.paymentMethod.errors++ : false;
            partial ? this.tabs.paymentMethod.errors++ : false;
            return !amount && !partial? true : false ;

        },
        openRefundDialog(transaction, id){
            this.refund.actualTransaction = transaction
            this.refund.amount = this.refund.partial ? null : this.refund.actualTransaction.amount
            this.refund.partial = this.refund.actualTransaction.partiallyRefunded
            let actualRefundAddintion = 0

            
            if(this.refund.actualTransaction.refunds) {
                for (let i = 0; i < this.refund.actualTransaction.refunds.length; i++) {
                    actualRefundAddintion += parseFloat(this.refund.actualTransaction.refunds[i])
                }
            }
            
            this.refund.maxPossibleValue = parseFloat(this.roundNumber(parseFloat(this.refund.actualTransaction.amount) -  actualRefundAddintion))
            this.refund.actualId = id;
            this.refundDialog = true
        },
        getCompleteStatus(id) {
            for (let i = 0; i < this.paymentStatuses.length; i++) {
                if(this.paymentStatuses[i].id === id) {
                    return this.paymentStatuses[i]
                }
                
            }
        },
        async validateTransaction(){
            this.tabs.paymentMethod.errors = 0;
            switch(this.payment.paymentMethod) {
                case 'credit-card':

                let cardNumber,cardName,cardCvv,cardMonth,cardYear,billZip,billingAddress,cardToPayAmount ;
                switch(this.payment.gateway) {
                    case 'Paypal PayFlow Pro':
                        cardToPayAmount = this.$v.cardToPayAmount.$invalid;
                        cardNumber = this.$v.payment.cardFields.cardNumber.$invalid;
                        cardName = this.$v.payment.cardFields.cardName.$invalid;
                        cardCvv = this.$v.payment.cardFields.cardCvv.$invalid;
                        cardMonth = this.$v.payment.cardFields.cardMonth.$invalid;
                        cardYear = this.$v.payment.cardFields.cardYear.$invalid;
                        billZip = this.$v.payment.cardFields.billZip.$invalid;
                        billingAddress = this.$v.payment.cardFields.billingAddress.$invalid;

                        cardNumber ? this.tabs.paymentMethod.errors++ : false;
                        cardName ? this.tabs.paymentMethod.errors++ : false;
                        cardCvv ? this.tabs.paymentMethod.errors++ : false;
                        cardMonth ? this.tabs.paymentMethod.errors++ : false;
                        cardYear ? this.tabs.paymentMethod.errors++ : false;
                        billZip ? this.tabs.paymentMethod.errors++ : false;
                        billingAddress ? this.tabs.paymentMethod.errors++ : false;
                        cardToPayAmount ? this.tabs.paymentMethod.errors++ : false;

                        return !cardNumber && !cardName && !cardCvv && !cardMonth && !cardYear && !billZip && !billingAddress && !cardToPayAmount? true : false ;
                    case 'Stripe':
                       
                        
                        if(this.payment.elements) {
                            console.log('Stripe if')
                            let submitElements = await this.payment.elements.submit()
                            console.log('submitElements',submitElements)
                            if(submitElements.error) {
                                this.tabs.paymentMethod.errors++
                                return false
                            } else {
                                return true
                            }
                        } else {
                            console.log('Stripe else')
                            //// Price not generated
                            //this.tabs.paymentMethod.errors++
                            return true
                        }
                        
                        

                    default:
                        cardNumber = this.$v.payment.cardFields.cardNumber.$invalid;
                        cardName = this.$v.payment.cardFields.cardName.$invalid;
                        cardCvv = this.$v.payment.cardFields.cardCvv.$invalid;
                        cardMonth = this.$v.payment.cardFields.cardMonth.$invalid;
                        cardYear = this.$v.payment.cardFields.cardYear.$invalid;
                        billZip = this.$v.payment.cardFields.billZip.$invalid;
                        billingAddress = this.$v.payment.cardFields.billingAddress.$invalid;
                        merchantAccount = this.$v.payment.merchantAccount.$invalid;

                        cardNumber ? this.tabs.paymentMethod.errors++ : false;
                        cardName ? this.tabs.paymentMethod.errors++ : false;
                        cardCvv ? this.tabs.paymentMethod.errors++ : false;
                        cardMonth ? this.tabs.paymentMethod.errors++ : false;
                        cardYear ? this.tabs.paymentMethod.errors++ : false;
                        billZip ? this.tabs.paymentMethod.errors++ : false;
                        billingAddress ? this.tabs.paymentMethod.errors++ : false;
                        merchantAccount ? this.tabs.paymentMethod.errors++ : false;


                        return !cardNumber && !cardName && !cardCvv && !cardMonth && !cardYear && !billZip && !billingAddress && !merchantAccount ? true : false ;
                        break;

                }
                    
                    
                case 'cash':
                    let paidAmount;
                    paidAmount = this.$v.payment.cash.paidAmount.$invalid;
                    

                    paidAmount ? this.tabs.paymentMethod.errors++ : false;
                    return !paidAmount ? true : false ;
                case 'check':
                    let companyName,address,routing,account;
                    companyName = this.$v.payment.check.companyName.$invalid;
                    address = this.$v.payment.check.address.$invalid;
                    routing = this.$v.payment.check.routing.$invalid;
                    account = this.$v.payment.check.account.$invalid;

                    companyName ? this.tabs.paymentMethod.errors++ : false;
                    address ? this.tabs.paymentMethod.errors++ : false;
                    routing ? this.tabs.paymentMethod.errors++ : false;
                    account ? this.tabs.paymentMethod.errors++ : false;
                        

                    return !companyName && !address && !routing && !account ? true : false ;
                        
                case 'invoice':
                    let companyNameInv,addressInv,ein,email;
                    companyNameInv = this.$v.payment.invoice.companyName.$invalid;
                    addressInv = this.$v.payment.invoice.address.$invalid;
                    ein = this.$v.payment.invoice.ein.$invalid;
                    email = this.$v.payment.invoice.email.$invalid;

                    companyNameInv ? this.tabs.paymentMethod.errors++ : false;
                    addressInv ? this.tabs.paymentMethod.errors++ : false;
                    ein ? this.tabs.paymentMethod.errors++ : false;
                    email ? this.tabs.paymentMethod.errors++ : false;
                        

                    return !companyNameInv && !addressInv && !ein && !email  ? true : false ;
            }        
        },
        async openPaymentDialog() {
            this.payment.cash.paidAmount = this.roundNumber(this.rateData.price - this.totalTransactionsPaidAmount)
            this.cardToPayAmount = this.roundNumber(this.rateData.price - this.totalTransactionsPaidAmount)
            this.transactionDialog = true

            
            
            

            if(this.payment.gateway == 'Stripe' && this.reservation.generatedPrice) {
                
                this.initMerchantObject(this.payment.public, 'stripe')
               
            }


            // if(this.payment.gateway == 'Stripe') {
            //     if(this.payment.paymentIntent) {
            //         await this.updateOrderInformation()
            //     } else {

            //         await this.sendOrderInformation()
            //     }

            // }
        },
        switchMainTabs(tab) {
            console.log(tab)
            this.$refs.mainTabs.activeTab = tab;
            this.$window.scrollTo(0,0)
        },
        async getMerchantAccounts() {
            var params = {
                user: this.user.data.uid
            }
            let th = this;
            return new Promise((resolve, reject) => {
                axiosApi.get('active-merchant-accounts/' + th.user.data.uid).then(response =>{
                    console.log(response);
                    th.merchantAccounts = response.data.accounts ;
                    for (let i = 0; i <  th.merchantAccounts.length; i++) {
                        if(th.merchantAccounts[i].default) {
                            th.payment.merchantAccount = th.merchantAccounts[i].id
                            th.reservation.gateway = th.merchantAccounts[i].gateway
                            th.payment.gateway = th.merchantAccounts[i].gateway
                        }
                        
                    }
                    resolve()
                }).catch(e => {
                    console.log(e);
                    reject()
                })
            })
        },
        async refundPayment(type, refundedId,refundedTransaction){

            //  Parse number decimals
            if(this.validateRefund()) {
            
                this.submitingRefund = true

                // const {
                //     type,
                //     user,
                //     resId,
                //     refundedId,
                //     creator,
                //     totalTransactionsPaidAmount,
                //     targetPrice,
                //     amountToRefund,
                //     partial,
                // } = req.body;


                let data = {
                    user: this.user.data.uid,
                    creator: {
                        email: this.user.data.email,
                        role: this.user.data.activeRole,
                        displayName: this.user.data.displayName,
                    },
                    resId: this.$route.params.id,
                    totalTransactionsPaidAmount: this.totalTransactionsPaidAmount,
                    targetPrice: this.roundNumber(this.rateData.price),
                    type: type,
                    refundedId:refundedId,
                    refundedTransaction:refundedTransaction,
                    amountToRefund: parseFloat(this.roundNumber(this.refund.amount)),
                    partial: this.refund.partial
                }

                if(type === 'credit card') {
                    data.gateway = this.refund.actualTransaction.merchantId

                    if(this.refund.actualTransaction.gateway == 'stripe') {
                        data.transactionId = this.refund.actualTransaction.callback.id
                    }

                    if(this.refund.actualTransaction.gateway == 'paypal') {

                        data.transactionId = this.refund.actualTransaction.callback.transactionId
                    }


                }

                

                //     amountToRefund,
                //     partial,

                

                let th = this;

                //return new Promise((resolve, reject) => {
                axiosApi.post('refund-payment',data).then(response =>{
                    console.log(response);
                    //th.reservation.transactions.push(response.data.transaction)
                    th.refundDialog = false
                    th.snackbar.message = 'Transaction refunded correctly'
                    th.snackbar.show = true
                    th.submitingRefund = false
                    th.isEditing = false
                    
                    
                    //resolve()
                    //th.$router.push({path: '/reservations'})
                }).catch(e => {
                    th.refundDialog = false
                    th.snackbar.message = 'Transaction refund failed'
                    th.snackbar.show = true
                    console.log(e);
                    th.submitingRefund = false
                    th.isEditing = false
                    //reject()
                }) 
            } else {
                this.$v.refund.$touch()
            }


        },
        async submitStripePayment() {
            let th = this
            const elements = this.payment.elements
            console.log(this.$router)
            return new Promise(async (resolve, reject) => {

                let origin = window.location.origin
                let routeList = th.$router.matcher.getRoutes()

                let finalRoute = routeList.filter((route) => {
                    return route.name == 'Reservation'
                    
                })


                let return_url = `${origin}${finalRoute[0].path}`
                console.log('return_url',return_url)

                // const {error,paymentIntent} = await th.payment.merchantInstance.confirmPayment({
                //     //`Elements` instance that was used to create the Payment Element
                //     elements,
                //     confirmParams: {
                //         return_url: return_url,
                //     },
                //     redirect: 'if_required'
                //     //redirect: 'always'
                // });

                const { error , confirmationToken } = await th.payment.merchantInstance
                .createConfirmationToken({
                    elements: th.payment.elements,
                })


                if( error ) {
                    const messageContainer = document.querySelector('#error-message');
                    messageContainer.textContent = error.message;
                    resolve({error})
                }

                let amount = parseFloat(this.reservation.generatedPrice.replace(".",""))
                let data = {
                    amount: amount,
                    currency: 'usd',
                    merchantId: this.payment.merchantAccount,
                    user: this.user.data.uid,
                    confirmationTokenId: confirmationToken.id

                }

                axiosApi.post('stripe/create-confirm-intent',data).then(response =>{
                    
                    resolve(response)
                    //this.$router.push({path: `/app/reservations`})

                }).catch(e => {
                
                   
                    
                    resolve(e.response)
                }) 
                
                


                
                
            })
        },
        async submitPayment() {


            if(this.validateTransaction()) {
                console.log('valid transaction')
                
                //this.submitingPayment = true
                let data = {
                    user: this.user.data.uid,
                    creator: {
                        email: this.user.data.email,
                        role: this.user.data.activeRole,
                        displayName: this.user.data.displayName,
                    },
                    resId: this.$route.params.id,
                    transaction: {},
                    merchantId: this.payment.merchantAccount,
                    totalTransactionsPaidAmount: this.totalTransactionsPaidAmount,
                    targetPrice: this.roundNumber(this.rateData.price),
                    paymentData: this.payment,
                } ;


                let paymentType 
                let paymentResult 
                let sendSubmittedPayment = false
                
                switch(this.payment.gateway) {
                    case 'Paypal PayFlow Pro':
                        paymentType = 'paypal'
                        sendSubmittedPayment = true
                        break;
                    case 'Stripe':
                        paymentType = 'stripe'
                        
                        
                        paymentResult = await this.submitStripePayment()
                        console.log('paymentResult',paymentResult)
                        this.payment.paymentIntentObject = paymentResult


                        // if(paymentResult && paymentResult.error){ 
                        //     this.payment.paymentIntentObject = paymentResult
                        // } 

                        // if(paymentResult && paymentResult.paymentIntent){ 
                        //     this.payment.paymentIntentObject = paymentResult.paymentIntent
                        // }
                        

                        break;
                }


                switch (this.payment.paymentMethod) {
                    case 'cash':
                        data.transaction = {
                            amount : this.payment.cash.paidAmount,
                            type: 'cash'
                        }
                        break;
                    case 'credit-card':
                        data.transaction = {
                            cardFields: this.payment.cardFields,
                            amount: this.cardToPayAmount,
                            type: paymentType
                        }

                        break;

                }
                let th = this;


                /////
                
                if(this.payment.paymentIntentObject) {
                    console.log(this.payment.paymentIntentObject)

                    if(this.payment.paymentIntentObject.status == 500) {
                        console.log('Paymnet failed')
                        this.tabs.paymentMethod.errors++

                        resolve(this.payment.paymentIntentObject)
                    }

                    if(this.payment.paymentIntentObject.status == 200) {
                        
                        sendSubmittedPayment = true

                    }
                }

                /////
                if(sendSubmittedPayment) {

                    this.payment.paymentIntentObject = this.payment.paymentIntentObject.data
                    axiosApi.post('submit-payment',data).then(response =>{
                        console.log(response);
                        //th.reservation.transactions.push(response.data.transaction)
                        th.transactionDialog = false
                        th.snackbar.message = 'Transaction submited correctly'
                        th.snackbar.show = true
                        th.submitingPayment = false
                        th.isEditing = false
                        
                        
                        //resolve()
                        //th.$router.push({path: '/reservations'})
                    }).catch(e => {
                        th.transactionDialog = false
                        th.snackbar.message = 'Transaction failed'
                        th.snackbar.show = true
                        console.log(e);
                        th.submitingPayment = false
                        th.isEditing = false
                        //reject()
                    }) 
                }
                
                

                
                //this.reservation.transactions.push(transaction)
                
                
            } else {
                console.log('Not valid transaction')
                this.$v.payment.$touch();
                this.$v.cardToPayAmount.$touch();
            }
           
            
            //this.$v.payment.$touch()
            
        },
        resetTabErrors() {
            for(var key in this.tabs) {
                //console.log(key);
                this.tabs[key].errors = null
            }
        },
        onCancel() {
            this.confirmDialog = false
        },
        async onConfirm() {
            this.confirmLeave = true

            if(this.payment.gateway == 'Stripe' && this.payment.paymentIntent)
            await this.cancelOrderInformation()

            this.$router.push({path: this.goTo.path})
        },
        preventNav(event) {
            console.log(event)
            if (!this.isEditing) return
            event.preventDefault()
            event.returnValue = ""
        },
        openSMSModal(type) {
            this.smsInfo.title = type;
            switch(type) {
                case 'Confirmation':
                    this.smsInfo.text = 'Reservation #' + this.reservation.readableId + ' created'
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = true;
                    this.smsInfo.recipients.driver.active = true;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Receipt':
                    this.smsInfo.text = 'Reservation #' + this.reservation.readableId + ' receipt'
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = false;
                    this.smsInfo.recipients.driver.active = false;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Cancellation':
                    this.smsInfo.text = 'Reservation #' + this.reservation.readableId + ' has been cancelled'
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = true;
                    this.smsInfo.recipients.driver.active = true;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Refund':
                    this.smsInfo.text = 'Reservation #' + this.reservation.readableId + ' refund'
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = true;
                    this.smsInfo.recipients.driver.active = false;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Driver assignation':
                    this.smsInfo.text = 'New job - Reservation #' + this.reservation.readableId + ' driver confirmation'
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = true;
                    this.smsInfo.recipients.driver.active = true;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Driver assignment request':
                    this.smsInfo.text = 'Confirmation request for reservation #' + this.reservation.readableId + ' .'
                    this.smsInfo.recipients.passenger.active = false;
                    this.smsInfo.recipients.owner.active = false;
                    this.smsInfo.recipients.driver.active = true;
                    this.smsInfo.recipients.user.active = false;
                    break;
                case 'Quotation':
                    this.smsInfo.text = 'Quotation #' + this.reservation.readableId + ' '
                    this.smsInfo.recipients.passenger.active = true;
                    this.smsInfo.recipients.owner.active = false;
                    this.smsInfo.recipients.driver.active = false;
                    this.smsInfo.recipients.user.active = false;
                    break;
            }
            
            this.showSMSDialog = true
        },
        openMailingModal(type) {
            this.mailingInfo.title = type;

            switch(type) {
                case 'Confirmation':
                    this.mailingInfo.subject = 'Reservation #' + this.reservation.readableId + ' created'
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = true;
                    this.mailingInfo.recipients.driver.active = true;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Receipt':
                    this.mailingInfo.subject = 'Reservation #' + this.reservation.readableId + ' receipt'
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = false;
                    this.mailingInfo.recipients.driver.active = false;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Cancellation':
                    this.mailingInfo.subject = 'Reservation #' + this.reservation.readableId + ' has been cancelled'
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = true;
                    this.mailingInfo.recipients.driver.active = true;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Refund':
                    this.mailingInfo.subject = 'Reservation #' + this.reservation.readableId + ' refund'
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = true;
                    this.mailingInfo.recipients.driver.active = false;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Driver assignation':
                    this.mailingInfo.subject = 'New job - Reservation #' + this.reservation.readableId + ' driver confirmation'
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = true;
                    this.mailingInfo.recipients.driver.active = true;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Driver assignment request':
                    this.mailingInfo.subject = 'Confirmation request for reservation #' + this.reservation.readableId + ' .'
                    this.mailingInfo.recipients.passenger.active = false;
                    this.mailingInfo.recipients.owner.active = false;
                    this.mailingInfo.recipients.driver.active = true;
                    this.mailingInfo.recipients.user.active = false;
                    break;
                case 'Quotation':
                    this.mailingInfo.subject = 'Quotation #' + this.reservation.readableId + ' '
                    this.mailingInfo.recipients.passenger.active = true;
                    this.mailingInfo.recipients.owner.active = false;
                    this.mailingInfo.recipients.driver.active = false;
                    this.mailingInfo.recipients.user.active = false;
                    break;
            }
            
            this.showDialog = true
        },
        fireNotifications(){
            this.sendMail(this.mailingInfo.title)
        },
        fireSMSNotifications(){
            this.sendSMS(this.smsInfo.title)
        },
        sendSMS(type) {
            let resId = this.$route.params.id
            let reservation = this.reservation
            let th = this;
            let data = [];
            for (const property in this.smsInfo.recipients) {
                let to = null;
                let sms = null;
                if( property != 'others' 
                    && this.smsInfo.recipients[property].send === true 
                    && this.smsInfo.recipients[property].active === true) 
                {
                    
                    sms = {
                        smsType: type,
                        recipientType: property,
                        reservation: resId,
                        user: th.user.data.uid,
                        to: null,
                        text: th.smsInfo.text,
                            
                    }

                    switch (property) {
                        case 'passenger':
                            to = reservation.passenger.phone;
                            break;
                        case 'driver':
                            to = reservation.assignedDriver;
                            break;
                        case 'owner':
                            to = reservation.companyProfile.smsPhoneTarget;
                            break;
                        case 'user':
                            to = th.user.data.uid;
                            break;
                         
                    }
                    sms.to = to ;
                    data.push(sms)
                    
                } else {

                }
                 
                
            }

            console.log(data);
            if(data.length > 0) {
                 axiosApi.post('sms',data).then(response =>{
                    console.log(response);
                    this.snackbar.message = 'Notifications queued correctly'
                    this.showSMSDialog = false
                    this.snackbar.show = true
                    //th.$router.push({path: '/reservations'})
                }).catch(e => {
                    this.snackbar.message = 'Notifications queued failed'
                    this.showSMSDialog = false
                    this.snackbar.show = true
                    console.log(e);
                }) 
            }
        },
        sendMail(type){
            let resId = this.$route.params.id
            let reservation = this.reservation
            let th = this;
            let data = [];
            
            for (const property in this.mailingInfo.recipients) {
                let to = null;
                let mail = null;
                if( property != 'others' 
                    && this.mailingInfo.recipients[property].send === true 
                    && this.mailingInfo.recipients[property].active === true) 
                {
                    mail = {
                        mailType: type,
                        recipientType: property,
                        reservation: resId,
                        user: th.user.data.uid,
                        to: null,
                        message: {
                            subject: th.mailingInfo.subject,
                            html: th.mailingInfo.body,
                        }
                    }

                    switch (property) {
                        case 'passenger':
                            to = reservation.passenger.email;
                            break;
                        case 'driver':
                            to = reservation.assignedDriver;
                            break;
                        case 'owner':
                            to = reservation.companyProfile.mailList;
                            break;
                        case 'user':
                            to = th.user.data.uid;
                            break;
                         
                    }
                    mail.to = to ;
                    data.push(mail)
                    
                } else {

                }
                 
                
            }

            console.log(data);
            if(data.length > 0) {
                 axiosApi.post('mail',data).then(response =>{
                    console.log(response);
                    this.snackbar.message = 'Notifications queued correctly'
                    this.showDialog = false
                    this.snackbar.show = true
                    //th.$router.push({path: '/reservations'})
                }).catch(e => {
                    this.snackbar.message = 'Notifications queued failed'
                    this.showDialog = false
                    this.snackbar.show = true
                    console.log(e);
                }) 
            }

          

       
        },
        updateTime() {
            var cd = moment(new Date()).tz(this.reservation.companyProfile.timeZone);
            this.companyActualDateTime = cd.format('dddd DD MMMM YYYY hh:mm a') ;
           
        },
        flowTimeZone() {
            //console.log(this.reservation.companyProfile.timeZone);
            var tz = moment.tz.guess();
            //console.log(tz);
            var dbaTZoffset = moment.tz(this.reservation.companyProfile.timeZone).utcOffset()
            //console.log(dbaTZoffset);

            var creatorTZoffset = moment.tz(tz).utcOffset()
            //console.log(creatorTZoffset);


            if(creatorTZoffset > dbaTZoffset) {
                // Ej USEastern (dba) -240  / BS AS (creator) -180 / 
                // Moscu 180 (dba) // nuevadelih (creator) 300
                this.timezoneOffsetDif = creatorTZoffset - dbaTZoffset ;
                this.viewerTimezonePosition = 'right';
                // -180 -  -240 = 60
                // 300 - 180 = 120
            } else if(dbaTZoffset > creatorTZoffset) {

                // Ej USEastern(creator) -240  / BS AS (dba) -180 / 
                // Moscu 180 (creator) // nuevadelih (dba) 300

                this.timezoneOffsetDif = dbaTZoffset - creatorTZoffset ;
                this.viewerTimezonePosition = 'left';
                // -240 - -180 = 
                // 180 - 300 = -120
            } else {
                this.timezoneOffsetDif = 0;
                this.viewerTimezonePosition = 'equal';
            }




        },
        addPromoCode(){
            if(this.feeCalculatorData.promoCode != '' && this.feeCalculatorData.promoCode && !this.reservation.promoCodes.includes(this.feeCalculatorData.promoCode)) {
                this.reservation.promoCodes.push(this.feeCalculatorData.promoCode)
                this.feeCalculatorData.promoCode = null;
            }
            
        },
        roundNumber(number) {
            if(number) {
                number = number.toString(); //If it's not already a String
                if(number.indexOf(".") != -1) {
                    number = number.slice(0, (number.indexOf("."))+3); //With 3 exposing the hundredths place
                } 
            }
            
            return number
        },
        updateRideRelatedSubtotal(){
           // v-if="rateData.activeFlatRate"
           // v-if="reservation.vehicleType && rateData.miniumRate && !rateData.activeFlatRate"
            let addition = 0;

            if(this.rateData.activeFlatRate) {
                addition += parseFloat(this.rateData.activeFlatRate.price)
            }

            if(this.reservation.vehicleType && this.rateData.miniumRate && !this.rateData.activeFlatRate){
                addition += parseFloat(this.rateData.rates.miniumRate) ;
            }

            if(!this.rateData.activeFlatRate && !this.rateData.miniumRate) {
                this.rateData.rateSpread.forEach(rate => {
                    addition += parseFloat(rate.rate)
                });
            }

            if(this.rateData.stops && this.rateData.stops.length > 0) {
               addition +=  this.rateData.subtotals.stops ;
               addition +=  this.rateData.subtotals.waitingTimePriceTotal
               

            }

            this.rateData.subtotals.afterRideDetails = addition

            let overrided = this.rateData ;
            console.log(overrided);
            this.overridedRateData =  overrided;
            var calculator = this.$refs.feeCalculator ;  
            this.$nextTick(() => {
                calculator.validateRequiredFields()
            })

           
        },
        async printPriceTable(data){
            console.log('Price table data',data)
            
            
            this.rateData = data;
            this.reservation.generatedPrice = this.roundNumber(data.price)
            this.reservation.rateData = data;
            if(this.payment.gateway == 'Stripe') {
                this.initMerchantObject(this.payment.public, 'stripe')
                // if(this.payment.paymentIntent) {
                //     await this.updateOrderInformation()
                // } else {

                //     await this.sendOrderInformation()
                // }

            }
            this.updatePaymentStatusLocally()
            
        },
        updatePaymentStatusLocally() {
           

            let totalPaid = parseFloat(this.roundNumber(this.totalTransactionsPaidAmount))
            let targetPrice = parseFloat(this.roundNumber(this.rateData.price))

            if(totalPaid == 0) {
                //unpaid
                this.reservation.paymentStatus = 'IPKFsEucb8kjJueVEJbX'
            } else if(totalPaid < targetPrice) {
                //-Partially paid
                this.reservation.paymentStatus =  '5y2pmSkkrYBpWmQCGMKT'
            } else if(totalPaid >= targetPrice) {
                //-Totally paid
                this.reservation.paymentStatus = 'dF6isActI3wRNckjAXiw'
            } else {
                //unpaid
                this.reservation.paymentStatus = 'IPKFsEucb8kjJueVEJbX'
            }


            // if(this.roundNumber(this.totalTransactionsPaidAmount)  >= this.roundNumber(this.rateData.price) ){
            //     // Paid
            //     this.reservation.paymentStatus = 'dF6isActI3wRNckjAXiw'
                
            // } else if(this.roundNumber(this.totalTransactionsPaidAmount) > 0 && this.roundNumber(this.totalTransactionsPaidAmount) < this.roundNumber(this.rateData.price) ) {
            //     // Partially paid
            //      this.reservation.paymentStatus = '5y2pmSkkrYBpWmQCGMKT'
            // } else {
            //     // Unpaid
            //     this.reservation.paymentStatus = 'IPKFsEucb8kjJueVEJbX'
            // }

           
        },
        addOffersTabChanged(tabIndex, newTab, oldTab) {
            console.log('tabIndex',tabIndex);
            console.log('newTab',newTab);
            console.log('oldTab',oldTab);
            
        },
        modeTabChanged(tabIndex, newTab, oldTab) {
            console.log('tabIndex',tabIndex);
            console.log('newTab',newTab);
            console.log('oldTab',oldTab);

            this.assignmentMode = tabIndex == 0 ? 'offering-mode' : 'direct-assignment-mode'
            this.$nextTick(() => {
                let finalHeight = newTab.$el.clientHeight
                let mdTabsContent = this.$refs.mainTabs.$el.querySelectorAll('.md-tabs-content')[0]
                console.log(finalHeight);
                mdTabsContent.style.height = ( finalHeight + 150 ) + 'px'
            })
            
            //
        },
        mainTabChanged(tabId) {
            if(this.$refs.mainTabs && tabId == 'payment-method'){
                this.paymentMethodActiveTab = this.payment.paymentMethod
               
            } 
            if(this.$refs.mainTabs && tabId == 'dispatchment-info'){

                //this.$refs.mainTabs.
            }

            let index
            for (let i = 0; i < this.steps.length; i++) {
                
                if(tabId == this.steps[i].id) {

                    this.activeTabLabel = this.steps[i].label
                    index = i
                }
            }
            
            this.activeTabIndex = index
        },
        tlcChanged(val) {
            console.log(val)
            val ? this.runTlcCheck() : false
        },
        dmvChanged(val) {
            console.log(val)
            val ? this.runDmvCheck() : false
        },
        directTlcChanged(val) {
            console.log(val)
            val ? this.runDirectTlcCheck() : false
        },
        directDmvChanged(val) {
            console.log(val)
            val ? this.runDirectDmvCheck() : false
        },
        async runTlcCheck() {
            this.driverAssignment.tlcBusy = true
            for (let j = 0; j < this.driverAssignment.filteredLinkedDrivers.length; j++) {
               if(this.driverAssignment.filteredLinkedDrivers[j].role == 'driver' && this.driverAssignment.filteredLinkedDrivers[j].tlc) {
                    if(this.driverAssignment.filteredLinkedDrivers[j].tlc.number && this.driverAssignment.filteredLinkedDrivers[j].tlc.number != "") {
                        let response = await this.getTLCStatus(this.driverAssignment.filteredLinkedDrivers[j].tlc.number)
                        if(response.data.length > 0) {
                            this.$nextTick(()=> {

                                this.driverAssignment.filteredLinkedDrivers[j].tlc.check = response.data[0]
                            })
                        }
                    }
               } 
                
            }
            this.driverAssignment.tlcBusy = false
            this.driverAssignment.tlcOn = true
        },
        async runDmvCheck() {
            //this.isBusy = true
            //this.$nextTick(async ()=> {
                //this.driverAssignment.dmvBusy = true
            this.driverAssignment.dmvBusy = true
            for (let j = 0; j < this.driverAssignment.filteredLinkedDrivers.length; j++) {
                if(this.driverAssignment.filteredLinkedDrivers[j].role == 'driver' ) {

                    for (let n = 0; n < this.driverAssignment.filteredLinkedDrivers[j].fleet.length; n++) {
                        console.log(this.driverAssignment.filteredLinkedDrivers[j].fleet[n])
                        if(this.driverAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate && this.driverAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate != '') {
                            
                            let response = await this.getDMVStatus(this.driverAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate)
                            if(response.data.length > 0) {
                                //this.$nextTick(()=> {
        
                                    this.driverAssignment.filteredLinkedDrivers[j].fleet[n].dmvCheck = response.data[0]
                                //})
                            }
                        }
                        
                        
                    }
                } 
            }
            this.driverAssignment.dmvBusy = false
                //this.driverAssignment.dmvBusy = false
            // })
            this.isBusy = true;
            this.isBusy = false;
            
        },
        async runDirectTlcCheck() {
            this.directAssignment.tlcBusy = true
            for (let j = 0; j < this.directAssignment.filteredLinkedDrivers.length; j++) {
               if(this.directAssignment.filteredLinkedDrivers[j].role == 'driver' && this.directAssignment.filteredLinkedDrivers[j].tlc) {
                    if(this.directAssignment.filteredLinkedDrivers[j].tlc.number && this.directAssignment.filteredLinkedDrivers[j].tlc.number != "") {
                        let response = await this.getTLCStatus(this.directAssignment.filteredLinkedDrivers[j].tlc.number)
                        if(response.data.length > 0) {
                            this.$nextTick(()=> {

                                this.directAssignment.filteredLinkedDrivers[j].tlc.check = response.data[0]
                            })
                        }
                    }
               } 
                
            }
            this.directAssignment.tlcBusy = false

            this.directAssignment.tlcOn = true
        },
        async runDirectDmvCheck() {
            //this.isBusy = true
            //this.$nextTick(async ()=> {
            //this.directAssignment.dmvBusy = true
            this.directAssignment.dmvBusy = true
            for (let j = 0; j < this.directAssignment.filteredLinkedDrivers.length; j++) {
                if(this.directAssignment.filteredLinkedDrivers[j].role == 'driver' ) {

                    for (let n = 0; n < this.directAssignment.filteredLinkedDrivers[j].fleet.length; n++) {
                        console.log(this.directAssignment.filteredLinkedDrivers[j].fleet[n])
                        if(this.directAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate && this.directAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate != '') {
                            
                            let response = await this.getDMVStatus(this.directAssignment.filteredLinkedDrivers[j].fleet[n].mainInfo.plate)
                            if(response.data.length > 0) {
                                //this.$nextTick(()=> {
        
                                    this.directAssignment.filteredLinkedDrivers[j].fleet[n].dmvCheck = response.data[0]
                                //})
                            }
                        }
                        
                        
                    }
                } 
            }
            this.directAssignment.dmvBusy = false
            //this.directAssignment.dmvBusy = false
            // })
            this.isBusy = true;
            this.isBusy = false;
            
        },
        getTLCStatus(tlcNumber) {
            console.log('Get TLC Status')
            let params = {
                

            }
            //tlcNumber = '4353'
            return new Promise((resolve, reject) => {
                axios.create({
                    headers: {},                    
                //}).get('https://data.cityofnewyork.us/resource/5tub-eh45.json?$query=SELECT%0A%20%20%60license_number%60%2C%0A%20%20%60name%60%2C%0A%20%20%60status_code%60%2C%0A%20%20%60status_description%60%2C%0A%20%20%60expiration_date%60%2C%0A%20%20%60last_update_date%60%2C%0A%20%20%60last_update_time%60%0AWHERE%20%60license_number%60%20%3D%20%22' + tlcNumber + '%22',
                }).get('https://data.cityofnewyork.us/resource/xjfq-wh2d.json?license_number=' + tlcNumber ,
                {params: params})
                .then(response =>{
                    console.log(response)
                    resolve(response)
                }).catch(e => {
                    console.log(e);
                    reject()
                })
            })
        },
       
        getDMVStatus(plateNumber) {
            console.log('Get DMV Status')
            console.log(plateNumber)
            let plateToCheck = plateNumber.toUpperCase()
            let params = {
                

            }
            //tlcNumber = '4353'
            return new Promise((resolve, reject) => {
                axios.create({
                    headers: {},                    
                //}).get('https://data.cityofnewyork.us/resource/5tub-eh45.json?$query=SELECT%0A%20%20%60license_number%60%2C%0A%20%20%60name%60%2C%0A%20%20%60status_code%60%2C%0A%20%20%60status_description%60%2C%0A%20%20%60expiration_date%60%2C%0A%20%20%60last_update_date%60%2C%0A%20%20%60last_update_time%60%0AWHERE%20%60license_number%60%20%3D%20%22' + tlcNumber + '%22',
                }).get('https://data.cityofnewyork.us/resource/8wbx-tsch.json?dmv_license_plate_number=' + plateToCheck ,
                {params: params})
                .then(response =>{
                    console.log('Get DMV status' , response)
                    resolve(response)
                }).catch(e => {
                    console.log(e);
                    reject()
                })
            })
        },
        paymentMethodChanged(val){
            this.paymentMethodActiveTab = val
        },
        jobTypeChanged(event){
            console.log(event);
        },
        
        tabChanged(tab) {
            //console.log(tab);
        },
        getJobTypeName(jobTypeId) {
            for (let i = 0; i < this.jobTypes.length; i++) {
                if(this.jobTypes[i].id == jobTypeId ) {
                    return this.jobTypes[i].name ;
                }   
            }
        },
         resetFlightPack() {
            if(this.reservation) {
                this.reservation.flightPack =  {
                                icao: null,
                                iata: null,
                                schedule: null,
                                info: null,
                                number:null,
                                noMatch: false,
                                originAirport: null,
                                destinationAirport: null,

                            }
            }
            
        },
        async initFlightCheck() {

            this.resetFlightPack()
           
             if(
                ( this.reservation.day 
                 && this.reservation.flightNumber 
                 && this.reservation.flightNumber.length >= 2 
                ) 
                && 
                (
                 this.reservation.flightIcao 
                 ||
                 this.reservation.flightIata 
                ) 
             ){
                this.loadingFlight = true ;
                this.reservation.flightNumber= this.reservation.flightNumber.replace(/\s/g,'')
                console.log('start')
                console.log(this.reservation.flightNumber)
                const schedules = await this.getFlightSchedules()
                console.log(schedules)
                console.log(this.reservation.flightNumber)
                console.log('end')
                // // if(this.possibleFlights.length > 0){
                // //      await this.getAirlineFlightInfo()
                // // }

                
             }     
            
            this.loadingFlight = false ;
        },
        
        async getFlightInfo(){
            let th = this;
            return new Promise((resolve, reject) => {
                let data = {
                    flightNumber: th.reservation.flightCode + th.reservation.flightNumber,
                    departuretime: th.possibleFlights[0].departuretime
                }

                axiosApi.get('get-flight-info/' , { params: data }

                ).then(response =>{
                   
                    let callback = JSON.parse(response.data.body)
                    console.log(callback)
                    if(callback.FlightInfoExResult) {
                        console.log('actual departure time')
                        console.log(moment.unix(callback.FlightInfoExResult.flights[0].actualdeparturetime).utc().format('LLLL'))
                        console.log('estimated arrival time')
                        console.log(moment.unix(callback.FlightInfoExResult.flights[0].estimatedarrivaltime).utc().format('LLLL'))
                        console.log('actual arrival time')
                        console.log(moment.unix(callback.FlightInfoExResult.flights[0].actualarrivaltime).utc().format('LLLL'))
                        // origin
                        // originCity
                        // originName

                        // destination
                        // destinationCity
                        // destinationName          
                    }
                    resolve()
                }).catch(e => {
                    // this.reservation.flightInfo = null;
                    // this.loadingFlight = false ;
                    console.log(e);
                    reject()
                    // this.serverError = false ;
                })  

                
              
            })  
        },
       async getFlightSchedules() {
            
            let th = this;
            return new Promise((resolve, reject) => {
                if(this.reservation.flightNumber && this.reservation.day) {
                    this.possibleFlights = []
                    
                    let qty = 5;
                    //let flight = this.reservation.flightCode + this.reservation.flightNumber
                    let code = this.reservation.flightIata ?  this.reservation.flightIata  : this.reservation.flightIcao ? this.reservation.flightIcao : null;
                    let flight = code + this.reservation.flightNumber
                    
                    let flightAirline = code
                    let flighNumber = this.reservation.flightNumber
                    //let startDate = moment.unix(this.reservation.day).hour(0).minute(0).unix(); 
                    //let endDate = moment.unix(this.reservation.day).hour(23).minute(59).unix() ;



                    // Set departure time range to return
                    let format = 'YYYY-MM-DD HH:mm:ss'
                    let startDate = null
                    let endDate = null
                    var jobTypeName = this.getJobTypeName(this.reservation.jobType.jobType)
                    if(jobTypeName == 'From airport') {
                        console.log('From airport')
                        startDate = moment.unix(this.reservation.day).subtract(24,'h').unix().format(format)
                        endDate = moment.unix(this.reservation.day).unix().format(format)
                        
                    } else if(jobTypeName == 'To Airport') {
                        console.log('To airport')
                        startDate = moment.unix(this.reservation.day).subtract(2,'h').unix().format(format)
                        endDate = moment.unix(this.reservation.day).add(12,'h').unix().format(format)
                    } else {
                        console.log('Not service type selected')
                        startDate = moment.unix(this.reservation.day).subtract(24,'h').unix().format(format)
                        endDate = moment.unix(this.reservation.day).unix().format(format)
                    }    
                    

                    let now = moment()
                    let timeToCheck = moment.unix(startDate)
                    let hoursDiff = timeToCheck.diff(now, 'hours');

                    console.log('hoursDiff',hoursDiff)
                    if(hoursDiff > 36) {
                        this.reservation.flightPack =  {
                            icao: null,
                            iata: null,
                            schedule: null,
                            info: null,
                            number:null,
                            originAirport: null,
                            destinationAirport: null

                        }

                        //this.reservation.flightPack.noMatch = true
                        this.reservation.flightPack.dayLimitExceeded = true
                        resolve()
                    } else {
                    


                        let parsedToUtcDatetimeStart = moment.unix(startDate).utc().format()
                        let parsedToUtcDatetimeEnd = moment.unix(endDate).utc().format()
                        console.log(parsedToUtcDatetimeStart);
                        console.log(parsedToUtcDatetimeEnd);
                        this.cancel();
                        let axiosSource = axios.CancelToken.source();
                        this.request = { cancel: axiosSource.cancel };
                        
                        axiosApi.get('flights-schedules/' 
                                + parsedToUtcDatetimeStart + '/'
                                + parsedToUtcDatetimeEnd + '/'
                                + flightAirline + '/'
                                + this.reservation.flightIcao + '/'
                                + this.reservation.flightIata + '/'
                                + flighNumber + '/'
                                + qty + '/'
                                + this.reservation.day + '/'
                                + this.reservation.pu.formatted_address + '/'
                                + this.reservation.do.formatted_address
                                ,
                                { cancelToken: axiosSource.token }
                        ).then(response =>{

                            console.log(response)
                            
                            var payload = response;
                            
                            if(payload.data.flight) {
                                this.reservation.flightPack.noMatch = false
                                this.reservation.flightPack = payload.data.flight
                                let originAirport = this.getAirportInfo(this.reservation.flightPack.schedule.origin.code_icao)
                                let destinationAirport = this.getAirportInfo(this.reservation.flightPack.schedule.destination.code_icao)
                                            
                                this.reservation.flightPack.originAirport = originAirport;
                                this.reservation.flightPack.destinationAirport = destinationAirport;
                            } else {
                                this.reservation.flightPack =  {
                                    icao: null,
                                    iata: null,
                                    schedule: null,
                                    info: null,
                                    number:null,
                                    originAirport: null,
                                    destinationAirport: null

                                }

                                this.reservation.flightPack.noMatch = true
                                
                            }
                                                    
                            resolve()
                            
                        }).catch(e => {
                            this.reservation.flightInfo = null;
                            //this.loadingFlight = false ;
                            
                            this.serverError = false ;
                            console.log(e)
                            //reject({message: 'cancelled request'})
                        }) 
                    }
                       
                } else {
                    this.serverError = false ;
                    this.loadingFlight = false ;
                     resolve()
                }
                
            })
        },
        cancel() {
            if (this.request) this.request.cancel('New request called');

        },
        setDispatcher(item) {
            //this.reservation.dispatcher = item;
            this.reservation.dispatcher = {
                displayName: item.displayName,
                email: item.email,
                customClaims: item.customClaims
            }
        },
        setAirline(item){
            

            
            this.reservation.flightAirline = null ;
            this.reservation.flightIcao = null;
            this.reservation.flightIata = null;


            this.reservation.flightAirline = item.name ;
            this.reservation.flightIcao = item.icao;
            this.reservation.flightIata = item.iata;
            this.$nextTick(() => {
                 this.$refs.airlineAutocomplete.showMenu = false
            })
            this.initFlightCheck();
        },
        getAirportInfo(icao) {
          
           let payload; 
           for (const airport in this.airportsList) {
               if (Object.hasOwnProperty.call(this.airportsList, airport)) {
                   const element = this.airportsList[airport];
                   
                   if(element.icao == icao) {
                       payload = element;
                   }
                   
               }
           }
           
           return payload;
    
        },
        focusedAirlines(ev){
            //console.log('Focused airlines')
            //this.resetFlightPack()
            //console.log(this.$refs.airlineAutocomplete);
            console.log(ev)
            
            ev.target.value = ""
            this.reservation.flightAirline = null ;
            this.reservation.flightIcao = null;
            this.reservation.flightIata = null;
            this.getAirlines()
        },
        getAirlines(searchTerm) {
            
            this.resetFlightPack()
            this.airlines = new Promise(resolve => {
                window.setTimeout(() => {
                    if (!searchTerm) {
                        let aux = this.airlinesList.slice(0, 10);
                        resolve(aux);
                        //resolve(this.airlinesList)
                    } else {
                        const term = searchTerm.toLowerCase()
                        console.log(term);
                        resolve(this.airlinesList.filter(({ name }) => name.toLowerCase().includes(term)).slice(0, 10))
                            
                    }
                }, 200)
            })
            
        },
        changeName (e) {
            this.payment.cardFields.cardName = e.target.value
            this.$emit('input-card-name', this.payment.cardFields.cardName)
        },
        changeNumber (e) {
            this.payment.cardFields.cardNumber = e.target.value
            const value = this.payment.cardFields.cardNumber.replace(/\D/g, '')
            // american express, 15 digits
            if ((/^3[47]\d{0,13}$/).test(value)) {
                this.payment.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 17
            } else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
                this.payment.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 16
            } else if (/^62[0-9]\d*/.test(value)) {
                this.payment.cardFields.cardNumber = value.replace(/(\d{6})/, '$1 ').replace(/(\d{6}) (\d{7})/, '$1 $2 ').replace(/(\d{6}) (\d{7}) (\d{6})/, '$1 $2 $3 ').replace(/(\d{5}) (\d{5}) (\d{5}) (\d{4})/, '$1 $2 $3 $4')
                this.cardNumberMaxLength = 21
            } else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
                this.payment.cardFields.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
                this.cardNumberMaxLength = 19
            }
            // eslint-disable-next-line
            if (e.inputType == 'deleteContentBackward') {
                const lastChar = this.payment.cardFields.cardNumber.substring(this.payment.cardFields.cardNumber.length, this.payment.cardFields.cardNumber.length - 1)
                // eslint-disable-next-line
                if (lastChar == ' ') { this.payment.cardFields.cardNumber = this.payment.cardFields.cardNumber.substring(0, this.payment.cardFields.cardNumber.length - 1) }
            }
            this.$emit('input-card-number', this.payment.cardFields.cardNumber)
        },
        changeMonth () {
            this.$emit('input-card-month', this.payment.cardFields.cardMonth)
        },
        changeYear () {
            this.$emit('input-card-year', this.payment.cardFields.cardYear)
        },
        changeCvv (e) {
            this.payment.cardFields.cardCvv = e.target.value
            this.$emit('input-card-cvv', this.payment.cardFields.cardCvv)
        },
        generateMonthValue (n) {
            return n < 10 ? `0${n}` : n
        },
        toggleMask () {
            this.isCardNumberMasked = !this.isCardNumberMasked
            if (this.isCardNumberMasked) {
                this.maskCardNumber()
            } else {
                this.unMaskCardNumber()
            }
        },
        maskCardNumber () {
            this.payment.cardFields.cardNumberNotMask = this.payment.cardFields.cardNumber
            this.mainCardNumber = this.payment.cardFields.cardNumber
            const arr = this.payment.cardFields.cardNumber.split('')
            arr.forEach((element, index) => {
                if (index > 4 && index < 14 && element.trim() !== '') {
                arr[index] = '*'
                }
            })
            this.payment.cardFields.cardNumber = arr.join('')
        },
        unMaskCardNumber () {
            this.payment.cardFields.cardNumber = this.mainCardNumber
        },
        ////
        stepChanged(id)
        {
            this.step = id;
        },
        myEventHandler(e) {
            // your code for handling resize...
            this.$store.commit('centerDialog',window.innerWidth)
        },
        
        
        async saveAndExit() {
            this.isBusy = true;
            if(this.validateAllSteps()){
                await this.update()
                this.onConfirm()
            }
            this.isBusy = false;
        },
        async fireUpdate() {
            this.isBusy = true;
            if(this.validateAllSteps()){
                console.log('update()')
                await this.update()
            }
            this.isBusy = false;
        },
        validateAllSteps(){
            let validSteps = [];
            let firstCorrectableStep = undefined;
            let steps = ['date-time-location','general','dispatchment-info','addons','rates-and-taxes','passenger','payment-method','emails'] ;
            for (let i = 0; i < steps.length; i++) {
                if(this.validateStep(steps[i])) {
                    validSteps.push(true)
                } else {
                    validSteps.push(false)
                    if(firstCorrectableStep === undefined) {
                        firstCorrectableStep = steps[i] ;
                    }
                }
                      
            }
            if(validSteps.includes(false)) {
                console.log('At least one false step')
                console.log('firstCorrectableStep',firstCorrectableStep);
                this.$refs.mainTabs.activeTab = firstCorrectableStep
                //this.mainActiveTab = 'general' ;
                this.$v.reservation.$touch();
                this.isBusy = false;
                return false;
            } else {
                console.log('All steps are valid')
                return true;
                
            }

            
        },
        cancelPaymentIntentRequest() {
            if (this.paymentIntentRequest) this.paymentIntentRequest.cancel('New payment intent request called');
        },
        async sendOrderInformation() {
            console.log('this.payment.paymentIntent',this.payment.paymentIntent)
            let th = this

            let amount = parseFloat(this.reservation.generatedPrice.replace(".",""))
            let data = {
                amount: amount,
                currency: 'usd',
                merchantId: this.payment.merchantAccount,
                user: this.user.data.uid

            }

            
            this.cancelPaymentIntentRequest();
            let axiosSource = axios.CancelToken.source();

            this.paymentIntentRequest = { cancel: axiosSource.cancel };


            console.log(data)
            return new Promise((resolve, reject) => {
                axiosApi.post('stripe/create-payment-intent',data, { cancelToken: axiosSource.token }).then(response =>{
                    console.log(response);
                    if(response.data.client_secret) {
                        th.initMerchantObject(response.data.public_key,response.data.client_secret, 'stripe')
                        th.payment.clientSecret = response.data.client_secret
                    }

                    if(response.data.id) {
                        th.payment.paymentIntent = response.data.id
                    }
                    resolve()
                    //this.$router.push({path: `/app/reservations`})

                }).catch(e => {
                
                   
                    console.log(e);
                    reject()
                }) 
            })

        },
        async updateOrderInformation() {
            let th = this

            let amount = parseFloat(this.reservation.generatedPrice.replace(".",""))
            let data = {
                amount: amount,
                currency: 'usd',
                merchantId: this.payment.merchantAccount,
                user: this.user.data.uid

            }


            console.log(data)

            return new Promise((resolve, reject) => {
                axiosApi.post(`stripe/update-payment-intent/${th.payment.paymentIntent}`,data).then(response =>{
                    console.log(response);
                    if(response.data.client_secret) {
                        th.initMerchantObject(response.data.public_key,response.data.client_secret, 'stripe')
                        th.payment.clientSecret = response.data.client_secret
                    }

                    if(response.data.id) {
                        th.payment.paymentIntent = response.data.id
                    }
                    resolve()
                    //this.$router.push({path: `/app/reservations`})

                }).catch(e => {
                
                   
                    console.log(e);
                    reject()
                }) 
            })
        },
        async cancelOrderInformation() {
            let th = this

            let data = {
                merchantId: this.payment.merchantAccount,
                user: this.user.data.uid
            }

            console.log(data)

            return new Promise((resolve, reject) => {
                axiosApi.post(`stripe/cancel-payment-intent/${th.payment.paymentIntent}`,data).then(response =>{
                    console.log(response);
                    
                    resolve()
                    //this.$router.push({path: `/app/reservations`})

                }).catch(e => {
                
                   
                    console.log(e);
                    reject()
                }) 
            })

        },
        async initMerchantObject(publicKey, provider) {
            let th = this;
            switch (provider) {
                case 'stripe':
                    let amount = parseFloat(this.reservation.generatedPrice.replace(".",""))
                    // const options = {
                    //     clientSecret: clientSecret,
                    //     // Fully customizable with appearance API.
                    //     appearance: {
                    //         theme: 'night',
                    //         labels: 'floating',
                    //         variables: {
                    //             colorPrimary: '#f9ba00'
                    //         }
                    //     },
                    // };

                    const options = {
                        amount:amount,
                        currency: 'usd',
                        mode: 'payment',
                        
                        appearance: {
                            theme: 'night',
                            labels: 'floating',
                            variables: {
                                colorPrimary: '#f9ba00'
                            }
                        },
                        paymentMethodCreation: 'manual',
                        paymentMethodTypes: [
                            'card'
                        ]
                    };

                    this.payment.merchantInstance = await loadStripe(publicKey);
                   

                    // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in a previous step
                    const elements = this.payment.merchantInstance.elements(options);
                    console.log('elements', elements)
                    this.payment.elements = elements


                    // Create and mount the Payment Element
                    let paymentElementPresent = document.getElementById('payment-element')
                    console.log('paymentElementPresent',paymentElementPresent)

                    if(paymentElementPresent) {

                        const paymentElement = elements.create('payment');
                        paymentElement.mount('#payment-element');
    
                        paymentElement.on('change', function(event) {
                            console.log(event)
                            th.payment.event = event
                            
                        });
    
                        paymentElement.on('ready', function(event) {
                            console.log('payment element ready',event)
                            
                            //th.refreshTabHeight()
                            // .$el.offsetHeight , altura con tab incluido
                        });
                    }
                    
                    break;
            
                default:
                    break;
            }
        },
        async update() {


            this.isBusy = true;
            let th = this;
            let data = {
                userReservationTenantUid: this.user.data.uid,
                reservation: th.reservation
            }

            return new Promise((resolve, reject) => {
                axiosApi.patch(`/reservation/update/${this.$route.params.id}`, data).then(response =>{
                    console.log(response);
                    console.log('Updated');
                    th.snackbar.message = 'Reservation updated'
                    th.resetTabErrors()
                    //th.isBusy = false;
                    th.snackbar.show = true
                    th.isEditing = false 
                    resolve()
                }).catch(e => {
                    th.snackbar.message = 'Ooopss something went wrong'
                    //th.isBusy = false;
                    th.snackbar.show = true
                    console.log(e);
                    reject()
                })
            })               
            
        },
        validateStep(step){
            // General
            // Date, time & location
            // Dispatchment
            // add ons
            // Rates and taxes
            // passenger
            // payment
            // emails
            
            switch(step) {
                case 'general':
                    let companyProfile,vehicleType,qty,lugagge ;
                    companyProfile = this.$v.reservation.companyProfile.$invalid;
                    
                    vehicleType = this.$v.reservation.vehicleType.$invalid;
                    qty = this.$v.reservation.passenger.qty.$invalid;
                    lugagge = this.$v.reservation.passenger.luggage.$invalid;
                    this.tabs.general.errors = 0 ;
                    companyProfile ? this.tabs.general.errors++ : false;
                    
                    vehicleType ? this.tabs.general.errors++ : false;
                    qty ? this.tabs.general.errors++ : false;
                    lugagge ? this.tabs.general.errors++ : false;
                    
                    return !companyProfile && !vehicleType && !qty && !lugagge ? true : false ;
                
                case 'date-time-location':
                    let d,e,f ,jobType;
                    d = this.$v.reservation.day.$invalid;
                    e = this.$v.reservation.pu.formatted_address.$invalid;
                    f = this.$v.reservation.do.formatted_address.$invalid;
                    jobType = this.$v.reservation.jobType.$invalid;
                    this.tabs.dateTimeLocation.errors = 0;
                    d ? this.tabs.dateTimeLocation.errors++ : false;
                    e ? this.tabs.dateTimeLocation.errors++ : false;
                    f ? this.tabs.dateTimeLocation.errors++ : false;
                    jobType ? this.tabs.general.errors++ : false;
                    
                    return !d && !e && !jobType ? true : false ;

                case 'dispatchment-info':
                    let reservationStatus;
                    reservationStatus = this.$v.reservation.reservationStatus.$invalid;
                    this.tabs.dispatchmentInfo.errors = 0;
                    reservationStatus ? this.tabs.dispatchmentInfo.errors++ : false;
                    return  !reservationStatus ? true : false ;

                case 'addons':
                    this.tabs.addons.errors = 0;
                    return true
                    
                case 'rates-and-taxes':
                    this.tabs.ratesAndTaxes.errors = 0;
                    return true
                case 'passenger':
                    let passengerQty = this.$v.reservation.passengers.$invalid
                    

                    this.tabs.passenger.errors = 0;
                    
                    passengerQty ? this.tabs.passenger.errors++ : false

                    
                    let validPassenger = !passengerQty ? true : false ;
                    return validPassenger
                    break;
                case 'payment-method':
                    this.tabs.paymentMethod.errors = 0;
                    switch(this.payment.paymentMethod) {
                            case 'credit-card':
                                // let cardNumber,cardName,cardCvv,cardMonth,cardYear,billZip,billingAddress ;
                                // cardNumber = this.$v.payment.cardFields.cardNumber.$invalid;
                                // cardName = this.$v.payment.cardFields.cardName.$invalid;
                                // cardCvv = this.$v.payment.cardFields.cardCvv.$invalid;
                                // cardMonth = this.$v.payment.cardFields.cardMonth.$invalid;
                                // cardYear = this.$v.payment.cardFields.cardYear.$invalid;
                                // billZip = this.$v.payment.cardFields.billZip.$invalid;
                                // billingAddress = this.$v.payment.cardFields.billingAddress.$invalid;

                                // cardNumber ? this.tabs.paymentMethod.errors++ : false;
                                // cardName ? this.tabs.paymentMethod.errors++ : false;
                                // cardCvv ? this.tabs.paymentMethod.errors++ : false;
                                // cardMonth ? this.tabs.paymentMethod.errors++ : false;
                                // cardYear ? this.tabs.paymentMethod.errors++ : false;
                                // billZip ? this.tabs.paymentMethod.errors++ : false;
                                // billingAddress ? this.tabs.paymentMethod.errors++ : false;

                                // return !cardNumber && !cardName && !cardCvv && !cardMonth && !cardYear && !billZip && !billingAddress && validPassenger? true : false ;
                                return true;
                                
                            case 'cash':
                                // let paidAmount;
                                // paidAmount = this.$v.payment.cash.paidAmount.$invalid;

                                // paidAmount ? this.tabs.paymentMethod.errors++ : false;
                                // return !paidAmount ? true : false ;
                                return true;
                            case 'check':
                                // let companyName,address,routing,account;
                                // companyName = this.$v.payment.check.companyName.$invalid;
                                // address = this.$v.payment.check.address.$invalid;
                                // routing = this.$v.payment.check.routing.$invalid;
                                // account = this.$v.payment.check.account.$invalid;

                                // companyName ? this.tabs.paymentMethod.errors++ : false;
                                // address ? this.tabs.paymentMethod.errors++ : false;
                                // routing ? this.tabs.paymentMethod.errors++ : false;
                                // account ? this.tabs.paymentMethod.errors++ : false;
                                    

                                // return !companyName && !address && !routing && !account && validPassenger ? true : false ;
                                return true;
                                    
                            case 'invoice':
                                // let companyNameInv,addressInv,ein,email;
                                // companyNameInv = this.$v.payment.invoice.companyName.$invalid;
                                // addressInv = this.$v.payment.invoice.address.$invalid;
                                // ein = this.$v.payment.invoice.ein.$invalid;
                                // email = this.$v.payment.invoice.email.$invalid;

                                // companyNameInv ? this.tabs.paymentMethod.errors++ : false;
                                // addressInv ? this.tabs.paymentMethod.errors++ : false;
                                // ein ? this.tabs.paymentMethod.errors++ : false;
                                // email ? this.tabs.paymentMethod.errors++ : false;
                                    

                                // return !companyNameInv && !addressInv && !ein && !email && validPassenger ? true : false ;
                                return true;
                    }        
                    break;
                    case 'emails':
                        this.tabs.emails.errors = 0;
                        return true;
                    
            }                    
        },
        buttonContinueOn() {
            if(this.step == "general"){
                return true;
                //return this.firstStepOk == true ? true : false ;
                
            } else if(this.step == "locations") {
                return true;
                //return this.publicacion.fotos.length > 0 ? true : false ;
            }
        },
        getValidationClass (fieldName, index) {
   
            let field = null;
            if(
                fieldName == 'qty' 
                || fieldName == 'luggage' 
                ) {

                field = this.$v.reservation.passenger[fieldName]
            } else if(fieldName == 'dialog.email' || fieldName == 'dialog.firstName' || fieldName == 'dialog.lastName' || fieldName == 'dialog.phone') {
                let fieldSplit = fieldName.split('dialog.')[1];
                field = this.$v.newClientPassengerDialog[fieldSplit];

            } else if(fieldName == 'firstName' 
                || fieldName == 'lastName' 
                || fieldName == 'email' 
                || fieldName == 'phone') {

                field = this.$v.passenger[fieldName]
            } else if(
                fieldName == 'passengers'
            ) {
                field = this.$v.reservation.passengers
            } else if(fieldName == 'newClient.type' || fieldName == 'newClient.willBePassenger' || fieldName == 'newClient.name' || fieldName == 'newClient.email' || fieldName == 'newClient.firstName' || fieldName == 'newClient.lastName' || fieldName == 'newClient.phone') {
                //let fieldSplit = fieldName.split('newClient.')[1];
                //field = this.$v.newClient[fieldSplit];


                let fieldSplit = fieldName.split('newClient.')[1];

                let clientExsits = {
                    $invalid: false,
                    $dirty: false
                }

                if(fieldName == 'newClient.email') {
                    clientExsits.$invalid = this.clientExsits
                    clientExsits.$dirty = true
                }

                let specialValidation = false
                if(fieldName == 'newClient.email' && this.userCheck) {

                    if(this.newClient.type === 'individual' && !this.isPassenger){
                        clientExsits.$invalid = true
                        clientExsits.$dirty = true
                        specialValidation = true
    
                    } else if(this.newClient.type === 'corporate' && !this.isClient) {
                        clientExsits.$invalid = true
                        clientExsits.$dirty = true
                        specialValidation = true
                    }
                }

                field = this.clientExsits || specialValidation ? clientExsits : this.$v.newClient[fieldSplit];


            } else if(fieldName == 'newPassengerAddition.email' || fieldName == 'newPassengerAddition.firstName' || fieldName == 'newPassengerAddition.lastName' || fieldName == 'newPassengerAddition.phone') {
                let fieldSplit = fieldName.split('newPassengerAddition.')[1];
                field = this.$v.newPassengerAddition[fieldSplit];

            } else if(fieldName == 'driverAmount' ) {
                field = this.$v.reservation.driverAmount
            } else if (
                 fieldName == 'cardNumber' 
                || fieldName == 'cardName' 
                || fieldName == 'cardCvv' 
                || fieldName == 'cardMonth' 
                || fieldName == 'cardYear' 
                || fieldName == 'billZip' 
                || fieldName == 'billingAddress' 
            ) {
                field = this.$v.payment.cardFields[fieldName]
            } else if(
                fieldName == 'check.companyName' 
                || fieldName == 'check.address' 
                || fieldName == 'check.routing' 
                || fieldName == 'check.account' 
            ){
                let fieldSplit = fieldName.split('check.')[1];
                
                field = this.$v.payment.check[fieldSplit]
            } else if(
                fieldName == 'invoice.companyName' 
                || fieldName == 'invoice.address' 
                || fieldName == 'invoice.ein' 
                || fieldName == 'invoice.email' 
            ){
                let fieldSplit = fieldName.split('invoice.')[1];
                field = this.$v.payment.invoice[fieldSplit]
            } else if(fieldName == 'paidAmount' ){
                field = this.$v.payment.cash[fieldName]
            } else if(fieldName == 'cardToPayAmount' ){
                field = this.$v[fieldName]
            } else if (fieldName == 'refund.amount' || fieldName == 'refund.partial') {
                let fieldSplit = fieldName.split('refund.')[1];
                field = this.$v.refund[fieldSplit]
            } else if(fieldName == 'driverAssigment.requestedVehicle') {
                //console.log('index',index)
                //console.log('this.driverAssignment.filteredLinkedDrivers[index].selected',this.driverAssignment.filteredLinkedDrivers[index].selected)
                field = this.$v.driverAssignment.filteredLinkedDrivers.$each[index].requestedVehicle 
            } else {
                field = this.$v.reservation[fieldName]
            }
            

            if (field) {
                return {
                    'md-invalid': field.$invalid && field.$dirty
                }
            }
        },
        clearForm () {
            this.$v.$reset()
            // this.email = null
            // this.password = null
            // this.displayName = null
            // this.role = null
            
        },
        validateReservation () {
            this.$v.$touch()

            if (!this.$v.$invalid) {
               // Save reservation
               console.log('Valid');
            }
        },
        inputWithValue(index,value){
            console.log('Input with value');
            console.log(value);
            let incomingIndex = this.labelWrittenItems.indexOf(index);
            if(value.length > 0) {
                if (incomingIndex > -1) {

                } else {
                    this.labelWrittenItems.push(index) ;
                }
                
                //this.focusedItem = index ;
            } else {
                
                if (incomingIndex > -1) {
                    this.labelWrittenItems.splice(incomingIndex, 1);
                }
            }
        },
        unfocusLocation(index) {
            //this.labelActiveItems[index].focused = null ;
            let incomingIndex = this.labelFocusedItems.indexOf(index);
            if (incomingIndex > -1) {
                this.labelFocusedItems.splice(incomingIndex, 1);
            }
        },
        focusedLocation(index){
            console.log(index);
            console.log('focusedLocation')
            this.labelFocusedItems.push(index) ;
        },
        autocompleteUser(index,address) {
            console.log(index)
            this.activeStopIndex = index;
            let th = this;
            setTimeout(function(){
                console.log(address.value);
                th.actualStopAddress = address.value ;
                //th.reservation.waypoints[index].formatted_address = address.value
            },0)
        },
        showStatusModal(){
            this.showDialog = true;
        },
        setDescription(description) {
            this.description = description;
        },
        saveWaypoint(index) {
            //this.reservation.waypoints[this.activeStopIndex] = this.waypointsPack[this.activeStopIndex] ;
        },
        removeWaypoint(key){
           console.log(key);
            this.reservation.waypoints.splice(key, 1);
        },
        addStop(){ 
            // let waypoint = {
            //     stopover: true,
            //     location: null
            // }
            // this.reservation.waypoints.push({
            //     stopover: true,
            //     location: null
            // })
            //this.waypointsPack.push({});
            this.reservation.waypoints.push({});
            
        },
        setWP(place) {
            console.log('Place changed');
            
            let postal = null;
            for (let index = 0; index < place.address_components.length; index++) {
                if( place.address_components[index].types[0] == "postal_code") {
                    postal = place.address_components[index].long_name ;        
                };
                
            }
            let wp = {
                    //formatted_address: place.formatted_address,
                    postalCode: postal,
                    coordinates: {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    },
                    stop: {
                        location: place.geometry.location.lat() + ',' + place.geometry.location.lng(),
                        stopover: true
                    },
                    formatted_address : this.actualStopAddress
                    
            }

            //this.reservation.waypoints[this.activeStopIndex] = wp;
            this.reservation.waypoints.splice(this.activeStopIndex, 1, wp);
           

            
        },
        getSelectedAddress(type,address) {
           let th = this;
           setTimeout(function(){
               console.log(address.value);
                th.reservation[type].formatted_address = address.value
           },0)
            
        },
        setPU(place) {

            //this.reservation.pu = place; 
            let postal = null;
            for (let index = 0; index < place.address_components.length; index++) {
                if( place.address_components[index].types[0] == "postal_code") {
                    postal = place.address_components[index].long_name ;        
                };
                
            }
            this.reservation.pu.postalCode = postal;
            this.reservation.pu.coordinates = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    };
        },
        setDO(place) {

            //this.reservation.do = place; 
            let postal = null;
            for (let index = 0; index < place.address_components.length; index++) {
                if( place.address_components[index].types[0] == "postal_code") {
                    postal = place.address_components[index].long_name ;        
                };
                
            }
            this.reservation.do.postalCode = postal;
            this.reservation.do.coordinates = {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng()
                    };
        },
        updatedMiles(miles) {
            this.reservation.miles = miles;
        },
        overviewPath(path) {
            this.feeCalculatorData.overviewPath = path;
            
        },
        add(){
            console.log('Add method')
        },
        async getVehicleTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('vehicle-types').orderBy("order").onSnapshot((querySnapshot) => {
                    th.vehicleTypes = [];
                    querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.vehicleTypes.push(dataToSave);
                    

                    });    
                    
                    resolve()
                    
                });
            })  
        },
        async getServiceTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('service-types').orderBy("order").onSnapshot((querySnapshot) => {
                    th.serviceTypes = [];
                    querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    th.serviceTypes.push(dataToSave);
                    

                    });    
                    
                    resolve()
                    
                });
            })  
        },
        async getAddons() {
            let th = this;
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('add-ons').onSnapshot((querySnapshot) => {
                    th.addOns = [];
                    querySnapshot.forEach((doc) => {
                        
                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        th.addOns.push(dataToSave);
                        
                    });
                    resolve()
                });
            })  
        },
        async getCompanyProfiles() {
            let th = this;
            return new Promise((resolve, reject) => {
                if(th.user.data.activeRole == 'company-admin') {

                    firebase.firestore().collection('users').doc(th.user.data.uid).collection('company-profiles').onSnapshot((querySnapshot) => {
                        th.companyProfiles = [];
                        querySnapshot.forEach((doc) => {
                            
                            let dataToSave = doc.data();
                            dataToSave.id = doc.id ;
                            
                            th.companyProfiles.push(dataToSave);
                            
                        });
                        resolve()
                    });
                } else {
                    th.companyProfiles.push(th.reservation.companyProfile)
                    resolve()
                }
            })  
        },
        async getJobTypes() {
            let th = this;
            return new Promise((resolve, reject) => {
                th.jobTypesRef.orderBy('order').onSnapshot((querySnapshot) => {
                    th.jobTypes = [];
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        th.jobTypes.push(dataToSave);
                        
                    });
                    resolve()
                });
            })  
        },
        async getStatuses() {
            ///////
            let th = this;
            return new Promise((resolve, reject) => {
                th.statusesRef.orderBy('name').onSnapshot((querySnapshot) => {
                    th.reservationStatuses =  []
                    th.driverStatuses =  []
                    th.paymentStatuses =  []
                    th.tripStatuses =  []
                    querySnapshot.forEach((doc) => {

                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        switch(dataToSave.type) {
                            case 'reservation':
                                th.reservationStatuses.push(dataToSave);
                                break;
                            case 'driver':
                                th.driverStatuses.push(dataToSave);
                                break;
                            case 'payment':
                                th.paymentStatuses.push(dataToSave);
                                break;
                            case 'trip':
                                th.tripStatuses.push(dataToSave) ;
                                break; 
                            
                        }
                        
                        
                    });
                    resolve()
                })
                
            }) 

            
        },
        async getDispatchers(){
            let th = this ;

            let data = {  
                    ownerEmail: this.$store.getters.user.data.email,
                    roleToGet: 'dispatcher'
            }

            
            return new Promise((resolve, reject) => {
                th.$firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
                    //Send token to your backend via HTTPS
                    //...
                    axiosApi.get('dispatchers', { params: data }).then(response =>{
                        th.dispatchers = response.data.users ;
                        resolve()
                    }).catch(e => {
                        console.log(e);
                        reject()
                    })
                }).catch(function(error) {
                    //Handle error
                });
            }) 

        
        },
        async initPU() {
            
            let data = this.reservation.pu.coordinates ;
            this.reservation.pu.coordinates = null;
            this.reservation.pu.coordinates = data;

            
            // const geocoder =  new window.google.maps.Geocoder();
            
            // return new Promise((resolve, reject) => {
            //     console.log(geocoder);

            //     geocoder
            //     .geocode({ location: th.reservation.pu.coordinates })
            //     .then((response) => {
            //         th.setPU(response.results[0]);
            //         resolve();
            //     })
            //     .catch((e) => window.alert("Geocoder failed due to: " + e));
                                
            // }) 
            
            
        },

        async initDO() {

            let data = this.reservation.do.coordinates ;
            this.reservation.do.coordinates = null;
            this.reservation.do.coordinates = data;



            // let th = this;
            // const geocoder =  new window.google.maps.Geocoder();
           
            // return new Promise((resolve, reject) => {
                
            //     geocoder
            //     .geocode({ location: th.reservation.do.coordinates })
            //     .then((response) => {
            //         th.setDO(response.results[0]);
            //         resolve();
            //     })
            //     .catch((e) => window.alert("Geocoder failed due to: " + e));
            // }) 
           
        },
        reorderTransactions(transactions) {
            transactions.sort(function (a, b) {
                if (a.datetime > b.datetime) {
                    return 1;
                }
                if (a.datetime < b.datetime) {
                    return -1;
                }
                // a must be equal to b
                return 0;
            });
        },
        updateTotalPaidAmount(transactions){
            this.reorderTransactions(transactions)
            let totalPaid = 0;

            for (let j = 0; j < transactions.length; j++) {
                if(transactions[j].status === 'SUCCESS') {
                    totalPaid += parseFloat(transactions[j].amount)
                }
                
            }
            this.totalTransactionsPaidAmount = totalPaid

            return totalPaid


        },
        onOfferSelected(items) {
            this.driverAssignment.selectedOffers = items
        },
        onSelectCommunity(selected) {
            this.driverAssignment.selectedCommunities = selected ;
        },
        onSelectedVehicle(value) {
            
            console.log(value)
            let vehicleIndex = value.split('-')[0]
            let linkedUserDocId = value.split('-')[1]
            let userIndex = value.split('-')[2]

            this.$v.$touch()
            // for (let i = 0; i < selected.length; i++) {
            //     if(selected[i].requestedVehicle) {

            //         selected[i].requestedVehicle = selected[i].fleet[selected[i].requestedVehicle]
            //     }
                
            // }
        },
        onSelectAllDriverList(selected) {
            
            
            this.driverAssignment.selectedDrivers = selected ;
            let activeIds = []

            for (let j = 0; j < selected.length; j++) {
                activeIds.push(selected[j].id) 
            }
            console.log(activeIds)

            for (let i = 0; i < this.driverAssignment.filteredLinkedDrivers.length; i++) {
                if(activeIds.includes(this.driverAssignment.filteredLinkedDrivers[i].id) ) {
                    this.driverAssignment.filteredLinkedDrivers[i].selected = true
                    if(this.driverAssignment.filteredLinkedDrivers[i].fleet.length == 1) {
                        this.driverAssignment.filteredLinkedDrivers[i].requestedVehicle = 0 + '-' + this.driverAssignment.filteredLinkedDrivers[i].id + '-' + this.driverAssignment.filteredLinkedDrivers[i].index
                    }
                } else {
                    this.driverAssignment.filteredLinkedDrivers[i].selected = false 
                }
                activeIds.includes(this.driverAssignment.filteredLinkedDrivers[i].id) ? 
                                    this.driverAssignment.filteredLinkedDrivers[i].selected = true : 
                                    this.driverAssignment.filteredLinkedDrivers[i].selected = false 
            }

        },
        onSelectDirectAssignationList(selected) {
            console.log(selected)
            this.directAssignment.selectedDriver = selected[0] ;

            let activeIds = []

            
            for (let j = 0; j < selected.length; j++) {
                activeIds.push(selected[j].id) 
            }
            
            console.log(activeIds)

            for (let i = 0; i < this.directAssignment.filteredLinkedDrivers.length; i++) {
                if(activeIds.includes(this.directAssignment.filteredLinkedDrivers[i].id) ) {
                    this.directAssignment.filteredLinkedDrivers[i].selected = true
                    this.directAssignment.filteredLinkedDrivers[i].disabled = false
                    if(this.directAssignment.filteredLinkedDrivers[i].fleet.length == 1) {
                        this.directAssignment.filteredLinkedDrivers[i].requestedVehicle = 0 + '-' + this.directAssignment.filteredLinkedDrivers[i].id + '-' + this.directAssignment.filteredLinkedDrivers[i].index
                    }
                } else {
                    if(activeIds.length > 0) {
                        this.directAssignment.filteredLinkedDrivers[i].disabled = true 
                    } else {
                        this.directAssignment.filteredLinkedDrivers[i].disabled = false 
                    }
                    this.directAssignment.filteredLinkedDrivers[i].selected = false 
                }
                // activeIds.includes(this.directAssignment.filteredLinkedDrivers[i].id) ? 
                //                     this.directAssignment.filteredLinkedDrivers[i].selected = true : 
                //                     this.directAssignment.filteredLinkedDrivers[i].selected = false 
            }

        },
        checkVehicleRequired(item) {
            let val
            //this.$nextTick(function() {

                console.log(item)
                console.log(item.index + ': '+ item.selected &&
                item.role == 'driver' &&
                item.fleet.length > 0 && 
                !item.requestedVehicle)
    
    
                return item.selected &&
                item.role == 'driver' &&
                item.fleet.length > 0 && 
                !item.requestedVehicle ? true : false
            //})
        },
        validateOfferSend() {
            
            let driverAmount;
            let invalidVehicles = []
            driverAmount = this.$v.reservation.driverAmount.$invalid;
            console.log('driverAmount', driverAmount)
            //this.$nextTick(function(){

                for (let j = 0; j < this.driverAssignment.filteredLinkedDrivers.length; j++) {
                    if(
                        this.driverAssignment.filteredLinkedDrivers[j].selected &&
                        this.driverAssignment.filteredLinkedDrivers[j].role == 'driver' &&
                        this.driverAssignment.filteredLinkedDrivers[j].fleet.length > 0 && 
                        !this.driverAssignment.filteredLinkedDrivers[j].requestedVehicle

                    ) {
                        invalidVehicles.push(true)
                        //requestedVehicle ? vehicles.push(true) : vehicles.push(false)
                    }
                }
                //return !driverAmount && vehicles.includes(true) ? true : false ;
                return !driverAmount && invalidVehicles.length == 0  ? true : false ;
            //})

        },
        validateDirectOfferSend() {
            let driverAmount;
            let invalidVehicles = []
            driverAmount = this.$v.reservation.driverAmount.$invalid;
            console.log('driverAmount', driverAmount)
            //this.$nextTick(function(){

                for (let j = 0; j < this.directAssignment.filteredLinkedDrivers.length; j++) {
                    if(
                        this.directAssignment.filteredLinkedDrivers[j].selected &&
                        this.directAssignment.filteredLinkedDrivers[j].role == 'driver' &&
                        this.directAssignment.filteredLinkedDrivers[j].fleet.length > 0 && 
                        !this.directAssignment.filteredLinkedDrivers[j].requestedVehicle

                    ) {
                        invalidVehicles.push(true)
                        //requestedVehicle ? vehicles.push(true) : vehicles.push(false)
                    }
                }
                //return !driverAmount && vehicles.includes(true) ? true : false ;
                return !driverAmount && invalidVehicles.length == 0  ? true : false ;
            //})

        },
        sendOffer() {
            if(this.validateOfferSend()) {
                console.log('send offer')
                let data = { 
                    usersToOffer: this.driverAssignment.selectedDrivers ,
                    userReservationTenantUid: this.user.data.uid,
                    reservationId:  this.$route.params.id,
                    driverAmount: this.reservation.driverAmount,
                    offerComment: this.reservation.offerComment
        
                }
        
                this.driverAssignment.busy = true
                let th = this;
                axiosApi.patch('reservations/offers/send', data).then(response =>{

                    console.log(response)
                    this.reservation = response.data.reservation
                    
                    this.driverAssignment.busy = false
                    th.snackMessage = 'Offer sent successfully';
                    th.showSnackbar = true;
                    th.resetDriverAssignment()
                }).catch(e => {
                    console.log(e);
                    this.driverAssignment.busy = false
                    th.resetDriverAssignment()
                })
            } else {
                console.log('$v.$touch() send offer')
                this.$v.reservation.driverAmount.$touch()
                this.$v.driverAssignment.filteredLinkedDrivers.$touch()
            }

        },
        sendCommunitiesOffers() {

            if(this.validateOfferSend()) {
                let data = {
                    communitiesToOffer: this.driverAssignment.selectedCommunities ,
                    userReservationTenantUid: this.user.data.uid,
                    reservationId:  this.$route.params.id,
                    driverAmount: this.reservation.driverAmount,
                    offerComment: this.reservation.offerComment
                }
        
                this.driverAssignment.busy = true
                let th = this;
                axiosApi.patch('reservations/offers/communities/send', data).then(response =>{
                    // for (let i = 0; i < th.reservations.mdData.length; i++) {
                    // if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
                    //     th.reservations.mdData[i] = response.data.reservation
                    // }
                    
                    // }
                    this.reservation = response.data.reservation
                    this.driverAssignment.busy = false
                    th.snackbar.message = 'Offers sent successfully';
                    th.snackbar.show = true;
                    th.resetDriverAssignment()
                }).catch(e => {
                    console.log(e);
                    this.driverAssignment.busy = false
                    th.resetDriverAssignment()
                })
            } else {
                this.$v.$touch()
            }
        },
        resetDriverAssignment() {
            this.driverAssignment.busy =  false
            this.driverAssignment.active =  false
            this.driverAssignment.direct =  false
            this.driverAssignment.newOffer =  true
            this.driverAssignment.selectedDriver =  null
            this.driverAssignment.selectedDriverText =  null
            this.driverAssignment.selectedCommunity =  null
            this.driverAssignment.queryResults =  []
            this.driverAssignment.zoneInputFocused =  false
           
            this.driverAssignment.searchTerm =  null
            this.driverAssignment.searching =  false
            this.driverAssignment.actualReservationId =  null
        },
        resetDirectAssignment() {
            this.directAssignment.busy =  false
            this.directAssignment.active =  false
            this.directAssignment.selectedDriver =  null
            this.directAssignment.selectedDriverText =  null
        
        },
        onDirectOfferSelected(items) {
         this.directAssignment.selectedOffers = items
        },
        sendDirectAssigment() {
            // BUG: Send direct assignation dupli
            if(this.validateDirectOfferSend()) {
                console.log('send direct assigment')
                
                
                let data = { 
                    usersToOffer: [this.directAssignment.selectedDriver] ,
                    userReservationTenantUid: this.user.data.uid,
                    reservationId:  this.$route.params.id,
                    driverAmount: this.reservation.driverAmount,
                    offerComment: this.reservation.offerComment
    
                }
    
                this.directAssignment.busy = true
                let th = this;
                axiosApi.patch('reservations/direct-assignation/send', data).then(response =>{
                    
                    this.directAssignment.busy = false
                    th.reservation = response.data.reservation
                    th.snackbar.message = 'Direct assignation sent successfully';
                    th.snackbar.show = true;
                    th.resetDirectAssignment()
                }).catch(e => {
                    console.log(e);
                    this.driverAssignment.busy = false
                    th.resetDirectAssignment()
                })

                
            } else {
                this.$v.$touch()
            }


        },
        assignmentModeChanged(tabId) {
            this.assignmentMode = tabId
        },
        deleteOffers() {
            let data = { 
                offers: this.driverAssignment.selectedOffers ,
                userReservationTenantUid: this.user.data.uid,
                reservationId:  this.$route.params.id

            }

            this.driverAssignment.busy = true
            let th = this;
            axiosApi.patch('reservations/offers/delete', data).then(response =>{
                // for (let i = 0; i < th.reservations.mdData.length; i++) {
                //     if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
                //     th.reservations.mdData[i] = response.data.reservation
                //     }
                    
                // }
                console.log(response)
                this.reservation = response.data.reservation
                
                this.driverAssignment.busy = false
                th.snackbar.message = 'Direct assignment successfully deleted';
                th.snackbar.show = true;
                th.resetDriverAssignment()
            }).catch(e => {
                console.log(e);
                this.driverAssignment.busy = false
                th.resetDriverAssignment()
            })

        },
        assignDriver() {
     
            let data = { 
            offerToAssign: this.driverAssignment.selectedOffers[0],
            userReservationTenantUid: this.user.data.uid,
            reservationId:  this.$route.params.id

            }

            this.driverAssignment.busy = true
            let th = this;

            axiosApi.patch('reservations/offers/assign-driver', data).then(response =>{
                // for (let i = 0; i < th.reservations.mdData.length; i++) {
                // if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
                //     th.reservations.mdData[i] = response.data.reservation
                // }
                
                // }
                this.reservation = response.data.reservation
                this.driverAssignment.busy = false
                th.snackbar.message = 'Driver successfully pre assigned';
                th.snackbar.show = true;
                th.resetDriverAssignment()
            }).catch(e => {
                console.log(e);
                this.driverAssignment.busy = false
                th.resetDriverAssignment()
            })

            


            
        },
        deleteAssignation() {
            // BUG: delete direct assignation
            let data = { 
                offers: this.directAssignment.selectedOffers ,
                userReservationTenantUid: this.user.data.uid,
                reservationId:  this.$route.params.id,
                directAssignmentOffer: true

            }

            this.directAssignment.busy = true
            let th = this;
            axiosApi.patch('reservations/offers/delete', data).then(response =>{
                // for (let i = 0; i < th.reservations.mdData.length; i++) {
                //     if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
                //     th.reservations.mdData[i] = response.data.reservation
                //     }
                    
                // }
                this.reservation = response.data.reservation
                this.directAssignment.busy = false
                th.snackbar.message = 'Offer successfully deleted';
                th.snackbar.show = true;
                th.resetDirectAssignment()
            }).catch(e => {
                console.log(e);
                this.driverAssignment.busy = false
                th.resetDirectAssignment()
            })
        },
        revokeAssignation() {
            let data = { 
                driverToRevoke: this.driverAssignment.selectedOffers[0] ,
                userReservationTenantUid: this.user.data.uid,
                reservationId:  this.$route.params.id

            }

            this.driverAssignment.busy = true
            let th = this;
            axiosApi.patch('reservations/assignation/revoke', data).then(response =>{
                // for (let i = 0; i < th.reservations.mdData.length; i++) {
                //     if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
                //     th.reservations.mdData[i] = response.data.reservation
                //     }
                    
                // }
                this.reservation = response.data.reservation
                this.driverAssignment.busy = false
                th.snackbar.message = 'Assigment revoked successfully';
                th.snackbar.show = true;
                th.resetDriverAssignment()
            }).catch(e => {
                console.log(e);
                this.driverAssignment.busy = false
                th.resetDriverAssignment()
            })

        },
        revokeDirectAssignation() {

            // BUG: Revoke direct assignation
            let data = { 
                driverToRevoke: this.directAssignment.selectedOffers[0] ,
                userReservationTenantUid: this.user.data.uid,
                reservationId:  this.$route.params.id,
                directAssignment: true
            }

            console.log('revokeDirectAssignation: ', data)

            this.directAssignment.busy = true
            let th = this;
            axiosApi.patch('reservations/assignation/revoke', data).then(response =>{
                // for (let i = 0; i < th.reservations.mdData.length; i++) {
                //     if(th.reservations.mdData[i].readableId === response.data.reservation.readableId) {
                //     th.reservations.mdData[i] = response.data.reservation
                //     }
                    
                // }
                this.reservation = response.data.reservation
                this.directAssignment.busy = false
                th.snackbar.message = 'Assigment revoked successfully';
                th.snackbar.show = true;
                th.resetDirectAssignment()
            }).catch(e => {
                console.log(e);
                this.driverAssignment.busy = false
                th.resetDirectAssignment()
            })
        },
        goToTabInner(tabId) {

            console.log(this.$refs.modeTab)
            this.$refs.modeTab.value = 1
            //this.$refs.dispatchmentInnerTab.activeTab = tabId
        },
        async listenLiveChanges() {
            let th = this
            let ref 
            ref = firebase
                    .firestore()
                    .collection('users')
                    .doc(this.user.data.uid)
                    .collection('live-api-changes')
            return new Promise( (resolve, reject) => {
                th.unsubscribeLiveChanges = ref.onSnapshot(function(snapShot){
                    snapShot.forEach(async (doc) => {
                    let dataToSave = doc.data()
                    dataToSave.id = doc.id
                    
                    let exists = th.reservation.readableId == dataToSave.readableId ? true : false
                    if(exists) {
                        console.log('Entering listener')
                        console.log(dataToSave)
                        th.getReservation()
                        //th.reservation = dataToSave
                    }
                    });
                    resolve()
                
                })
                
                    
            });
            
        },
        async getOffers() {
            let th = this
            return new Promise((resolve, reject) => {
                firebase.firestore().collection('users').doc(th.user.data.uid).collection('reservations').doc(th.$route.params.id).collection('offers')
                .onSnapshot((querySnapshot) => {
                    th.offers = [];
                    querySnapshot.forEach((doc) => {
                        let dataToSave = doc.data();
                        dataToSave.id = doc.id ;
                        th.offers.push(dataToSave);
                    })
                    let acceptedOffers = 0
                    let preAssignedOffers = 0
                    for (let k = 0; k < th.offers.length; k++) {
                        if(this.statuses[th.offers[k].status] == 'Accepted') {
                            acceptedOffers++
                        }

                        if(this.statuses[th.offers[k].status] == 'Pre assigned') {
                            preAssignedOffers++
                        }

                        if(this.statuses[th.offers[k].status] == 'Assigned') {
                        
                            if(th.offers[k].timeline) {

                                for (let n = 0; n < th.offers[k].timeline.length; n++) {
                                    if(this.statuses[th.offers[k].timeline[n].status] == 'Assigned' && n === th.offers[k].timeline.length - 1 ) {
                                        th.reservation.assignedDriver = th.offers[k].timeline[n].userInCharge
                                    }
                                }
                            }
                            
                        }
                    }

                    if(acceptedOffers > 0) {
                        th.offerFilters.push('Accepted')
                    }

                    if(preAssignedOffers > 0) {
                        th.offerFilters.push('Pre assigned')
                    }

                    if(acceptedOffers == 0 && th.offers.length > 0) {
                        th.offerFilters.push('Offered')
                    }

                    th.reservation.acceptedOffers = acceptedOffers
                    th.updateAvailableUsers()
                    resolve()
                })
            })
        },
        async getReservation() {
            let th = this;
            return new Promise((resolve, reject) => {
                th.unsub = firebase.firestore().collection('users').doc(th.user.data.uid).collection('reservations').doc(th.$route.params.id)
                .onSnapshot(doc => {
                        
                        if (doc.exists && !th.resReadedFirstSnap) {
                            th.reservation = doc.data();
                            
                            if(th.reservation.paymentStatus != 'dF6isActI3wRNckjAXiw') {
                                // Not approved payment
                                th.payment.cardFields =  {
                                    cardName: '',
                                    cardNumber: '',
                                    cardMonth: '',
                                    cardYear: '',
                                    cardCvv: '',
                                    billZip: '',
                                    billingAddress: '',
                                }
                            }
                             this.$store.commit('set', ['timezone',th.reservation.companyProfile.timeZone ])
                            this.$store.commit('set', ['utc',moment.tz(th.reservation.companyProfile.timeZone).utcOffset() ])
                            //this.$store.commit('set', ['time',moment.tz(th.reservation.companyProfile.timeZone).format('hh:mm A (HH:mm [HS])') ])
                            //this.$store.commit('set', ['date',moment.tz(th.reservation.companyProfile.timeZone).format('dddd DD-MM-YYYY') ])
                            th.selectedData.companyProfile = th.reservation.companyProfile.id
                            th.selectedData.jobType = th.reservation.jobType.id
                            th.selectedData.vehicleType = th.reservation.vehicleType.id
                            //th.selectedData.dispatcher = th.reservation.dispatcher.uid
                            th.selectedData.passengers = th.reservation.vehicleType.luggage
                            th.selectedData.luggage = th.reservation.vehicleType.luggage
                            
                            th.feeCalculatorData.vehicleType = th.reservation.vehicleType


                            let counter = 0;
                            if(th.reservation.vehicleType.rates.addons){
                                for (let i = 0; i < th.reservation.vehicleType.rates.addons.length; i++) {
                                    if(th.reservation.vehicleType.rates.addons[i].enabled && th.reservation.vehicleType.rates.addons[i].price) {
                                        counter++;
                                    }
                                    
                                }
                            }
                           
                            counter > 0 ? th.reservation.vehicleType.rates.addonsAvailable = true : th.reservation.vehicleType.rates.addonsAvailable = false

                            // feeCalculatorData.jobType
                            // feeCalculatorData.vehicleType
                            if(th.user.data.activeRole != 'company-admin') {
                                th.serviceTypes = [th.reservation.jobType]
                                th.vehicleTypes = [th.reservation.vehicleType]
                            }
                            for (let i = 0; i < th.serviceTypes.length; i++) {
                                if(th.serviceTypes[i].id === th.selectedData.jobType) {
                                        th.feeCalculatorData.jobType = th.serviceTypes[i]
                                        th.reservation.jobType = th.serviceTypes[i]
                                        //th.serviceTypes[i].vehicleType
                                        for (let j = 0; j < th.serviceTypes[i].vehicleType.length; j++) {
                                            for (let k = 0; k < th.vehicleTypes.length; k++) {
                                                if(th.serviceTypes[i].vehicleType[j] == th.vehicleTypes[k].id ) {
                                                    th.filteredVehicleTypes.push(th.vehicleTypes[k]);
                                                }
                                                
                                            }
                                            
                                        }
                                }
                            }

                            th.updateTime()
                            th.flowTimeZone();

                            let timeDiff = th.timezoneOffsetDif
                            th.reservation.day = parseInt(th.reservation.day)
                            // Get UTC time to add or substract timezone difference
                            let utcRealDateTime = moment.unix(th.reservation.day)

                            if(th.viewerTimezonePosition == 'right') {
                                timeDiff = timeDiff * -1
                            }

                            let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
                            th.selectedData.datetime = timeToShow
                           
                            th.rateData = th.reservation.rateData;
                            

                            
                            th.updateTotalPaidAmount(th.reservation.transactions)

                            th.$nextTick(() => {
                                console.log('this.$refs.pu');
                                console.log(th.$refs.pu);
                                if(!th.resReadedFirstSnap) {
                                    th.$refs.pu.$el.value = th.reservation.pu.formatted_address
                                    th.$refs.do.$el.value = th.reservation.do.formatted_address

                                    if(th.reservation.waypoints.length > 0) {
                                        for (let m = 0; m < th.reservation.waypoints.length; m++) {
                                            th.$refs.stopwaypoint[m].$el.value = th.reservation.waypoints[m].formatted_address
                                        }
                                    }
                                    th.resReadedFirstSnap = true;
                                }
                                
                                resolve()

                            });
                          
                            

                            //th.$refs.pu.$refs.input.value= 'Hola mundo'
                            
                            
                        } else if(doc.exists && th.resReadedFirstSnap) {
                           console.log('Mails, SMS, or transactions changed --------')
                           th.reservation.mails = doc.data().mails ; 
                           th.reservation.transactions = doc.data().transactions ; 
                           th.reservation.driverStatus = doc.data().driverStatus ; 
                           th.updateTotalPaidAmount(th.reservation.transactions)
                           th.updatePaymentStatusLocally()
                        } else {
                            // doc.data() will be undefined in this case
                            resolve()
                            console.log("No such document!");
                        }
                        // ...
                    }, err => {
                         console.log(`Encountered error: ${err}`);
                         reject()
                    });

                    // .get()
                    // .then(function(doc) {
                    //     if (doc.exists) {
                    //         console.log("Document data:", doc.data());
                    //         th.reservation = doc.data();
                            
                           
                    //         th.selectedData.companyProfile = th.reservation.companyProfile.id
                    //         th.selectedData.jobType = th.reservation.jobType.id
                    //         th.selectedData.vehicleType = th.reservation.vehicleType.id
                    //         //th.selectedData.dispatcher = th.reservation.dispatcher.uid
                    //         th.selectedData.passengers = th.reservation.vehicleType.luggage
                    //         th.selectedData.luggage = th.reservation.vehicleType.luggage
                            
                    //         th.feeCalculatorData.vehicleType = th.reservation.vehicleType


                    //         let counter = 0;
                    //         if(th.reservation.vehicleType.rates.addons){
                    //             for (let i = 0; i < th.reservation.vehicleType.rates.addons.length; i++) {
                    //                 if(th.reservation.vehicleType.rates.addons[i].enabled && th.reservation.vehicleType.rates.addons[i].price) {
                    //                     counter++;
                    //                 }
                                    
                    //             }
                    //         }
                    //         console.log('Counter',counter);
                           
                    //         counter > 0 ? th.reservation.vehicleType.rates.addonsAvailable = true : th.reservation.vehicleType.rates.addonsAvailable = false

                    //         // feeCalculatorData.jobType
                    //         // feeCalculatorData.vehicleType

                    //         for (let i = 0; i < th.serviceTypes.length; i++) {
                    //             if(th.serviceTypes[i].id === th.selectedData.jobType) {
                    //                     th.feeCalculatorData.jobType = th.serviceTypes[i]
                    //                     th.reservation.jobType = th.serviceTypes[i]
                    //                     //th.serviceTypes[i].vehicleType
                    //                     for (let j = 0; j < th.serviceTypes[i].vehicleType.length; j++) {
                    //                         for (let k = 0; k < th.vehicleTypes.length; k++) {
                    //                             if(th.serviceTypes[i].vehicleType[j] == th.vehicleTypes[k].id ) {
                    //                                 th.filteredVehicleTypes.push(th.vehicleTypes[k]);
                    //                             }
                                                
                    //                         }
                                            
                    //                     }
                    //             }
                    //         }

                    //         th.updateTime()
                    //         th.flowTimeZone();

                    //         let timeDiff = th.timezoneOffsetDif
                    //         // Get UTC time to add or substract timezone difference
                    //         let utcRealDateTime = moment.unix(th.reservation.day)

                    //         if(th.viewerTimezonePosition == 'right') {
                    //             timeDiff = timeDiff * -1
                    //         }

                    //         let timeToShow = utcRealDateTime.utc().add(timeDiff, 'minutes').format('X') ;
                    //         th.selectedData.datetime = timeToShow
                           
                    //         th.rateData = th.reservation.rateData;
                    //         th.$nextTick(() => {
                    //             console.log('this.$refs.pu');
                    //             console.log(th.$refs.pu);
                    //             th.$refs.pu.$el.value = th.reservation.pu.formatted_address
                    //             th.$refs.do.$el.value = th.reservation.do.formatted_address

                    //             if(th.reservation.waypoints.length > 0) {
                    //                 for (let m = 0; m < th.reservation.waypoints.length; m++) {
                    //                     th.$refs.stopwaypoint[m].$el.value = th.reservation.waypoints[m].formatted_address
                    //                 }
                    //             }

                    //             resolve()

                    //         });
                          
                            

                    //         //th.$refs.pu.$refs.input.value= 'Hola mundo'
                            
                            
                    //     } else {
                    //         // doc.data() will be undefined in this case
                    //         resolve()
                    //         console.log("No such document!");
                    //     }
                        
                    // }).catch(function(error) {
                    //     console.log("Error getting document:", error);
                    //     reject()
                    // });
            })  
        },
        async getLinkedUsers(requestedRole, requestedSubrole) {
            let th = this
            let ref 
            

            ref = firebase
                    .firestore()
                    .collection('users')
                    .doc(this.user.data.uid).
                    collection('linked-users')


            return new Promise((resolve, reject) => {
                let result = []
                //th.driverAssignment.linkedDrivers = []
                ref
                .where('role','==',requestedRole)
                .where('subrole','==',requestedSubrole)
                .get()
                .then(function(snapShot){
                    snapShot.forEach((doc) => {
                    let dataToSave = doc.data()
                    dataToSave.id = doc.id
                    result.push(dataToSave)
                    });

                
                    resolve(result)
                
                }).catch(function(error) {
                    
                    reject()
                });
                
                    
            });
        },
        async getCommunities(requestedRole) {
            let th = this
            let ref 
            

            ref = firebase
                    .firestore()
                    .collection('users')
                    .doc(this.user.data.uid).
                    collection('communities')


            return new Promise((resolve, reject) => {
                th.driverAssignment.communities = []
                ref.where('role','==',requestedRole).get()
                .then(function(snapShot){
                    snapShot.forEach((doc) => {
                    let dataToSave = doc.data()
                    dataToSave.id = doc.id
                    th.driverAssignment.communities.push(dataToSave)
                    });

                
                    resolve()
                
                }).catch(function(error) {
                    
                    reject()
                });
                
                    
            });
        },
        async getAvailableUsers() {
            let th = this

            return new Promise(async (resolve, reject) => {

                if(this.user.data.activeRole === 'company-admin') {
                   
                    let ownerOperatorsToAdd = await this.getLinkedUsers('driver','owner-operator')
                    this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, ownerOperatorsToAdd);
                    this.directAssignment.linkedAvailableUsersToOffer = [].concat(this.directAssignment.linkedAvailableUsersToOffer, ownerOperatorsToAdd);
                    this.directAssignment.companyDrivers = await this.getLinkedUsers('driver','company');
    
                    let companyAdminsToAdd = await this.getLinkedUsers('company-admin',null)
                    this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, companyAdminsToAdd);
    
                    let providersToAdd = await this.getLinkedUsers('provider',null)
                    this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, providersToAdd);
                    await this.getCommunities('driver')
                    //await this.getCompanyDrivers()
                } else if(this.user.data.activeRole === 'provider') {
                    let ownerOperatorsToAdd = await this.getLinkedUsers('driver','owner-operator')
                    this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, ownerOperatorsToAdd);
                    this.directAssignment.linkedAvailableUsersToOffer = [].concat(this.directAssignment.linkedAvailableUsersToOffer, ownerOperatorsToAdd);
                    this.directAssignment.companyDrivers = []
                    let companyAdminsToAdd = await this.getLinkedUsers('company-admin',null)
                    this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, companyAdminsToAdd);
    
                    let providersToAdd = await this.getLinkedUsers('provider',null)
                    this.driverAssignment.linkedAvailableUsersToOffer = [].concat(this.driverAssignment.linkedAvailableUsersToOffer, providersToAdd);
                } else {
                    this.directAssignment.companyDrivers = []
                }
    
                let linkedAvailableUsersToOffer = []
                linkedAvailableUsersToOffer = this.driverAssignment.linkedAvailableUsersToOffer.filter((driver, index) => {
                    let matches = [];
                    for (let i = 0; i < th.offers.length; i++) {
                        
                        th.offers[i].code != driver.code ? matches.push(false) : matches.push(true)
                    }
                    //if(matches.includes(true)) {
                    if(driver.code == 'OPD-1017') {
                        //console.log('matches includes true')
                        //console.log(driver.code)
                        //return false
                    } else {
                        // if(driver.role == 'driver' && driver.tlc) {
                        //         if(driver.tlc.number && driver.tlc.number != "") {
                        //             let response = await this.getTLCStatus(driver.tlc.number)
                        //             if(response.data.length > 0) {
                        //                 this.$nextTick(()=> {

                        //                     driver.tlc.check = response.data[0]
                        //                 })
                        //             }
                        //         }
                        // }
                        // if(driver.role == 'driver' ) {

                        //     for (let n = 0; n < driver.fleet.length; n++) {
                        //         console.log(driver.fleet[n])
                        //         if(driver.fleet[n].mainInfo.plate && driver.fleet[n].mainInfo.plate != '') {
                                    
                        //             let response = await this.getDMVStatus(driver.fleet[n].mainInfo.plate)
                        //             if(response.data.length > 0) {
                        //                 this.$nextTick(()=> {
                
                        //                     driver.fleet[n].dmvCheck = response.data[0]
                        //                 })
                        //             }
                        //         }
                                
                                
                        //     }
                        // } 
                            
                        driver.requestedVehicle = null
                        driver.index = index
                        //return driver
                        return true
                    }
                })
                let filteredInfoCompanyDrivers = []
                this.driverAssignment.filteredLinkedDrivers = [].concat(linkedAvailableUsersToOffer, filteredInfoCompanyDrivers);
    
                let linkedAvailableUsersToOfferDirect = []
                let companyDrivers = []
            
                linkedAvailableUsersToOfferDirect = this.directAssignment.linkedAvailableUsersToOffer.filter((driver, index) => {
                    driver.requestedVehicle = null
                    driver.index = index
                    return driver
                })

    
                companyDrivers = this.directAssignment.companyDrivers.filter((driver) => {  
                    
                    return driver
                })
          
                this.directAssignment.filteredLinkedDrivers = [].concat(linkedAvailableUsersToOfferDirect, companyDrivers);

                resolve()
            })




        },
        updateAvailableUsers() {
            let th = this
            if(!this.isBusy) {

                let linkedAvailableUsersToOffer = []
                linkedAvailableUsersToOffer = this.driverAssignment.linkedAvailableUsersToOffer.filter((driver) => {
                    let matches = [];
                    for (let i = 0; i < this.offers.length; i++) {
                        
                        th.offers[i].code != driver.code ? matches.push(false) : matches.push(true)
                    }
                    
                    if(matches.includes(true)) {
                    
                    } else {
                        return driver
                    }
                })
                let filteredInfoCompanyDrivers = []
                this.driverAssignment.filteredLinkedDrivers = [].concat(linkedAvailableUsersToOffer, filteredInfoCompanyDrivers);
    
    
    
                let linkedAvailableUsersToOfferDirect = []
                let companyDrivers = []
                
    
                linkedAvailableUsersToOfferDirect = this.directAssignment.linkedAvailableUsersToOffer.filter((driver) => {
                    return driver
                })
    
    
                companyDrivers = this.directAssignment.companyDrivers.filter((driver) => {  
                    
                    return driver
                })
    
                
                this.directAssignment.filteredLinkedDrivers = [].concat(linkedAvailableUsersToOfferDirect, companyDrivers);
            }



        },
        async getClients() {
            let th = this
            return new Promise((resolve, reject) => {
                this.$firebase.firestore().collection('users').doc(this.user.data.uid).collection('clients').onSnapshot((querySnapshot) => {
                th.clients = [];
                querySnapshot.forEach((doc) => {

                    let dataToSave = doc.data();
                    dataToSave.id = doc.id ;
                    
                    th.clients.push(dataToSave);
                });

                console.log(th.clients);
            
                th.total = th.clients.length;
                resolve()
                })
            })
        },
        async getPassengers() {
            let th = this ;
            return new Promise(async (resolve, reject) => {
                
                for (let i = 0; i < th.clients.length; i++) {
                    th.clients[i].passengers = []
                    let passengers = await this.$firebase.firestore().collection('users').doc(th.user.data.uid).collection('clients').doc(th.clients[i].id).collection('passengers').get()
                    passengers.forEach(doc => {
                        let dataToSave = doc.data()
                        dataToSave.id = doc.id
                        th.clients[i].passengers.push(dataToSave)
                    })
                }
                resolve()
            })
        },
        async obtainViewResources() {
            this.isBusy = true;
            //await this.getClients()
            //await this.getPassengers()
            //await this.getVehicleTypes()
            //await this.getServiceTypes()
            await this.getAddons()
            //await this.getJobTypes()
            //await this.getStatuses()
            //await this.getDispatchers()
            
            await this.getReservation()
            //await this.getCompanyProfiles()
            await this.getOffers()
            await this.getAvailableUsers() // TODO: Possibly to store.js 
            if(this.user.data.activeRole === 'company-admin') {
                //await this.getMerchantAccounts()
            }
            
            await this.initPU()
            await this.initDO()
            this.isEditing = false
            this.isBusy = false;
            this.$nextTick(()=>{

               this.fixInitialTabHeight()
               this.listenLiveChanges()
            })
            // this.mainActiveTab = 'general'
            // this.mainActiveTab = 'date-time-location'
            console.log('obtainViewResources end');
            console.log(this.$refs)
            //console.log(this.$refs.dispatchmentInnerTab.callResizeFunctions())
            //dispatchmentInnerTab
            //innerSecondTabs
        },
        fixInitialTabHeight() {
            console.log(this.$refs.mainTabs)

            let finalHeight = this.$refs.mainTabs.$el.querySelectorAll('#date-time-location')[0].clientHeight
            let mdTabsContent = this.$refs.mainTabs.$el.querySelectorAll('.md-tabs-content')[0]
            mdTabsContent.style.height = finalHeight + 'px'
            
            
        }
       
    },
    computed: {
        getTabLabel() {
            let label = null

            if(this.$refs.mainTabs) {

                switch(this.$refs.mainTabs.activeTab){ 
                    case 'date-time-location':
                        label = 'Datetime & location'
                        break;
                    case 'general':
                        label = 'General'
                        break;
                    case 'dispatchment-info':
                        label = 'Dispatchment info'
                        break;
                    case 'addons':
                        label = 'Addons'
                        break;
                    case 'rates-and-taxes':
                         label = 'Rates and taxes'
                        break;
                    case 'passenger':
                        label = 'Passenger'
                        break;
                    case 'payment-method':
                        label = 'Payment method'
                        break;
                    case 'emails':
                        label = 'Emails'
                        break;
                    case 'sms':
                        label = 'SMS'
                        break;
                    
                }
            } else {
                label = 'Date, time & location'
            }

            console.log(label)


            return label
        },
        clientText() {
            let text = ''
            if(this.preselected.client){
                text = `${this.preselected.client.name} ${this.preselected.client.phone}`
            }
            return text
        },
        availableClientPassengers() {
            let availablePassengers = null

            if(this.reservation.client && this.reservation.client.passengers) {
                let alreadySelectedContacts = 0
                for (let j = 0; j < this.reservation.passengers.length; j++) {
                    if(!this.reservation.passengers[j].new) {
                        alreadySelectedContacts++
                    }
                    
                }

                availablePassengers = this.reservation.client.passengers.length - alreadySelectedContacts
            }
            
            return availablePassengers
        },
        passengers(){
            let passengers = []
            for (let i = 0; i < this.clients.length; i++) {
                for (let j = 0; j < this.clients[i].passengers.length; j++) {
                    let alreadySelected = false
                    for (let k = 0; k < this.reservation.passengers.length; k++) {
                        if(this.reservation.passengers[k].id === this.clients[i].passengers[j].id) {
                            alreadySelected = true
                        }
                        
                    }

                    if(!alreadySelected ) {

                        passengers.push(this.clients[i].passengers[j])
                    }
                    
                }
                
            }

            return passengers
        },
        filteredLinkedDrivers() {
            return this.driverAssignment.filteredLinkedDrivers
        },
        filteredDirectOffers() {
            let offers = []
            let th = this

            if(this.reservation 
                && this.statuses[this.reservation.driverStatus] == 'Assigned' || this.statuses[this.reservation.driverStatus] == 'Pre assigned')
            {
                offers = this.offers.filter(function(offer){
                if(th.statuses[offer.status] == 'Assigned' || th.statuses[offer.status] == 'Pre assigned' ) {
                    return true
                }
                })
            }
            return offers

        },
        filteredPopOffers() {
            let offers = []
            let th = this
            
            if(this.reservation 
                && this.statuses[this.reservation.driverStatus] == 'Assigned' || this.statuses[this.reservation.driverStatus] == 'On hold')
            {
                offers = this.offers.filter(function(offer){
                    if(th.statuses[offer.status] == 'Assigned' || th.statuses[offer.status] == 'On hold' ) {
                        return true
                    }
                })
            } else if(this.reservation){
                offers = this.offers.filter(function(offer){
                    if(th.offerFilters.includes(th.statuses[offer.status])) {
                        return true
                    }
                })
            }
            // if(this.driverAssignment.actualRes.offers) {
            //   for (let j = 0; j < array.length; j++) {
            //     const element = array[j];
                
            //   }
            // }
            return offers
        },
        statuses() {
            return this.$store.state.statuses;
        },
        maxPossibleRefundVal() {
            return this.refund.maxPossibleValue
        },
        minCardMonth () {
            if (this.payment.cardFields.cardYear === this.minCardYear) return new Date().getMonth() + 1
            return 1
        },
        
        origin() {
            if (!this.reservation.pu) return null;
            return { query: this.reservation.pu.coordinates.lat + ',' + this.reservation.pu.coordinates.lng };
        },
        destination() {
            if (!this.reservation.do) return null;
            return { query: this.reservation.do.coordinates.lat + ',' + this.reservation.do.coordinates.lng };
        },
        computedWaypoints() {
            if (this.reservation.waypoints.length == 0 ) return null;
            const total = [];
            this.reservation.waypoints.forEach((waypoint,index) => {
                if(!waypoint.stop){
                    //total.push(null);
                } else {
                    total.push({
                        stopover:true,
                        location: !waypoint.stop ? null : waypoint.stop.location
                    })

                   
                }
                
            });
            return total;
            
        }, 
        dialogDimensions () {
            console.log('this.$store.state.dialogLeft',this.$store.state.dialogLeft)
            return {
                //left: this.$parent.$parent.offsetLeft + 'px'
                left: this.$store.state.dialogLeft + 'px' ,
                width: this.$store.state.dialogWidth + 'px'
                //top: (this.$store.state.sidebarNavHeight / 2) + 'px'
            }
        },
        dialogWidth() {
            return this.$store.state.dialogWidth
        },
        user() {
            return this.$store.state.user;
        },
        clients() {
            return this.$store.state.clients;
        },
        // drivers() {
        //     return this.$store.state.drivers;
        // },
        dispatchers() {
            return this.$store.state.dispatchers;
        },
        serviceTypes() {
            return this.$store.state.serviceTypes;
        },
        vehicleTypes() {
            return this.$store.state.vehicleTypes;
        },
        companyProfiles() {
            return this.$store.state.companyProfiles;
        },
        jobTypes() {
            return this.$store.state.jobTypes;
        },
        merchantAccounts() {
            return this.$store.state.merchantAccounts;
        },
        reservationStatuses() {
            return this.$store.state.reservationStatuses;
        },
        driverStatuses() {
            return this.$store.state.driverStatuses;
        },
        paymentStatuses() {
            return this.$store.state.paymentStatuses;
        }
        
       
    },
    beforeCreate() {
        
    },
    created () {
        window.addEventListener("resize", this.myEventHandler);
        window.addEventListener('scroll', this.positionMenu)
        let th = this ;
       
        this.obtainViewResources();
        

    },
    
    //validations: {
    validations() {    
        return {
            newPassengerAddition: {
                email: {
                    email
                },
                firstName: {
                    required
                },
                lastName: {
                    required
                },
                phone: {
                    required
                }
            },
            newClient: {
                type: {
                    required
                },
                name: {
                    required: requiredIf(function(){
                        return this.newClient.type === 'corporate' ? true : false
                    })
                },
                email: {
                    email,
                    required
                },
                firstName: {
                    required: requiredIf(function(){
                        return this.newClient.type === 'individual' ? true : false
                    })
                },
                lastName: {
                    required: requiredIf(function(){
                        return this.newClient.type === 'individual' ? true : false
                    })
                },
                phone: {
                    required
                },
                willBePassenger: {
                    required: requiredIf(function(){
                        return this.newClient.type === 'individual' ? true : false
                    })
                }
                
            },
            newClientPassengerDialog: {
                email: {
                    email,
                    required
                },
                firstName: {
                    required
                },
                lastName: {
                    required
                },
                phone: {
                    required
                }
                
            },
            passenger: {
                firstName: {
                    required
                },
                lastName:{
                    //required
                },
                email:{
                    //required,
                    email
                },
                phone:{
                    required
                }
            },
            duplication: {
                newPuDatetime: {
                    required: requiredIf(function(){
                        return this.duplication.active ? true : false
                    })
                }
            },
            driverAssignment: {
                filteredLinkedDrivers: {
                    $each: {
                        requestedVehicle: {
                            
                            required: requiredIf(function(value) {
                                //return false
                                return value.role == 'driver' && value.fleet.length > 0 && value.selected ? true : false
                               
                            })
                        }
                    }
                },
                selectedDrivers: {
                    $each: {
                        requestedVehicle: {
                            required: requiredIf(function(value) {
                                console.log(value);
                                return value.role == 'driver' && value.fleet.length > 0 ? true : false
                            })
                        }
                    }
                }
            },
            refund: {
                amount: {
                    minValue: minValue(1),
                    required,
                    maxValue: maxValue(this.refund.maxPossibleValue)
                    // maxValue: maxValue(function(val){
                    //     console.log(val)
                    //     return false
                    //     //return this.refund.maxPossibleValue
                    // })
                
                },
                partial: {
                    required
                }
            },
            //paymentMethod: null,
            cardToPayAmount: {
                required: requiredIf(function(payment){
                    return payment.paymentMethod == 'credit-card' ? false : true ;
                }),
                minValue: minValue(1),
            },
            payment: {
                cardFields: {
                    cardName: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    cardNumber: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    cardMonth: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    cardYear: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    cardCvv: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    billZip: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"))  ? false : true ;

                        })
                    },
                    billingAddress: {
                        required: requiredIf(function(payment){
                            return this.reservation && ( this.payment.paymentMethod == 'credit-card' && (this.reservation.paymentStatus != "dF6isActI3wRNckjAXiw"  && this.reservation.paymentStatus != null))  ? false : true ;

                        })
                    }
                },
                cash: {
                    paidAmount: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'cash' ? false : true ;
                        }),
                        minValue: minValue(1),
                    }
                },
                invoice: {
                    companyName: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'invoice' ? false : true ;

                        })
                    },
                    address: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'invoice' ? false : true ;

                        })
                    },
                    ein: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'invoice' ? false : true ;

                        })
                    },
                    email: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'invoice' ? false : true ;

                        })
                    }
                },
                check: {
                    companyName: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'check' ? false : true ;

                        })
                    },
                    address: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'check' ? false : true ;

                        })
                    },
                    routing: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'check' ? false : true ;

                        })
                    },
                    account: {
                        required: requiredIf(function(payment){
                            return payment.paymentMethod == 'check' ? false : true ;

                        })
                    }
                },
            },
            reservation: {
                passengers: {
                    required
                },
                driverAmount: {
                    required: requiredIf(function(assignmentMode){
                        console.log(this)
                        console.log(assignmentMode)
                        return this.assignmentMode == 'offering-mode' ? true : false ;
                    })
                },
                passenger: {
                    qty: {
                        required,
                        between (value) {
                            return between(1, this.selectedData.passengers)(value)
                        }
                    },
                    luggage: {
                        required,
                        between (value) {
                            return between(0, this.selectedData.luggage)(value)
                        }
                    },
                    firstName: {
                        required
                    },
                    lastName:{
                        //required
                    },
                    email:{
                        //required,
                        email
                    },
                    phone:{
                        required
                    }
                },
                jobType: {
                    required
                },
                companyProfile: {
                    required
                },
                vehicleType: {
                    required
                },
                addOns: {
                    //required
                },
                dispatcher: {
                    //required
                },
                reservationStatus: {
                    required
                },
                day: {
                    required
                },
                pu: {
                    formatted_address: {
                        required
                    }
                },
                do: {
                    formatted_address: {
                        required
                    }
                },
                flightNumber: {
                    required: requiredIf(function(reservation){
                        
                        if(reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2' || reservation.jobType == 'WKvPZgD64U1JISyKjTi6') {
                            return true
                        } else {
                            return false
                        }
                        
                    }),
                    serverError () { return this.serverError }
                },
                flightAirline: {
                    required: requiredIf(function(reservation){
                        
                        if(reservation.jobType == 'kdX0cDgqGrijDqZvHXQ2' || reservation.jobType == 'WKvPZgD64U1JISyKjTi6') {
                            return true
                        } else {
                            return false
                        }
                        
                    })
                },
                
            },
           

            
        }
        
    } , 
        
    //},
    
    destroyed() {
        
        this.unsub()
        if(this.unsubscribeLiveChanges) {
          this.unsubscribeLiveChanges()
        }
        window.removeEventListener("resize", this.myEventHandler);
        window.removeEventListener('scroll', this.positionMenu)
        this.$store.commit('restoreBrowserTime')
    },
}
</script>  

<style lang="scss" >
 @import "./../../assets/scss/variables";
  .badge {
    width: 19px;
    height: 19px;
    line-height:19px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    background: red;
    border-radius: 100%;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: -.05em;
    font-family: 'Roboto Mono', monospace;
  }

  .badge.md-primary {
      background-color: rgb(119, 207, 119);
  }

  .badge.md-primary .md-icon {
    width: 12px;
    min-width: 12px;
    height: 12px;
    font-size: 12px!important;
    color:white!important;

  }

  .text-decoration-line-through .md-table-head-label {
      text-decoration: line-through;
  }

  .flat {
    background-color: #a2a2a2!important;
  }

  .assigned, .accepted {
      background-color: $assigned-to!important;
    }

    .pre-assigned, .on-hold {
      background-color: $primary-color!important;
    }

    .unassigned {
      background-color: $unassigned!important;
    }
    .quoted {
        background-color: $quoted!important;
    }

    .offered {
      background-color: $offered!important;
    }

    .taken {
      background-color: $taken!important;
    }

    
    .confirmed {
        background-color: $confirmed!important;
    } 

    .unconfirmed {
        background-color: $unconfirmed!important;
    } 

    .late-concellation{
        background-color: $late-cancellation!important;
    } 

   .cancelled {
        background-color: $cancelled!important;
    }

  

  
</style>